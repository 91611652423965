import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class BottomLeftChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updated: false
    };
  }

  dataObj = {
    data: [
      {
        labels: [
          "Agricultural land",
          "Crop residues",
          "Dust bins",
          "Forest",
          "Grassland",
          "Grove",
          "Reeds–Swamps",
          "Woodland"
        ],
        datasets: [
          {
            label: "Areas Destroyed in m^2",
            backgroundColor: "rgba(255, 163, 82, 0.5)",
            borderColor: "rgba(0, 0, 0, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255, 130, 20, 0.8)",
            hoverBorderColor: "rgba(0, 0, 0, 1)",
            data: [
              this.props.data["Agricultural land"],
              this.props.data["Crop residues"],
              this.props.data["Dust bins"],
              this.props.data["Forest"],
              this.props.data["Grassland"],
              this.props.data["Grove"],
              this.props.data["Reeds–Swamps"],
              this.props.data["Woodland"]
            ]
          }
        ]
      }
    ]
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.dataObj = {
        data: [
          {
            labels: [
              "Agricultural land",
              "Crop residues",
              "Dust bins",
              "Forest",
              "Grassland",
              "Grove",
              "Reeds–Swamps",
              "Woodland"
            ],
            datasets: [
              {
                label: "Areas Destroyed in m^2",
                backgroundColor: "rgba(255, 163, 82, 0.5)",
                borderColor: "rgba(0, 0, 0, 1)",
                borderWidth: 1,
                hoverBackgroundColor: "rgba(255, 130, 20, 0.8)",
                hoverBorderColor: "rgba(0, 0, 0, 1)",
                data: [
                  this.props.data["Agricultural land"],
                  this.props.data["Crop residues"],
                  this.props.data["Dust bins"],
                  this.props.data["Forest"],
                  this.props.data["Grassland"],
                  this.props.data["Grove"],
                  this.props.data["Reeds–Swamps"],
                  this.props.data["Woodland"]
                ]
              }
            ]
          }
        ]
      };
      this.setState({ updated: !this.state.updated });
    }
  }
  render() {
    return (
      <div>
        <Bar
          data={this.dataObj.data[0]}
          width={100}
          height={50}
          options={{
            maintainAspectRatio: true,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ],
              xAxes: [
                {
                  barThickness: 25
                }
              ]
            }
          }}
        />
      </div>
    );
  }
}

export default BottomLeftChart;
