import React, { Component } from "react";
import { Pie } from "react-chartjs-2";

const options = {
  layout: {
    padding: {
      left: 20,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  legend: {
    display: true,
    labels: {
      fontColor: "#333",
      padding: 50,
      boxWidth: 10,
    },
    position: "right",
  },
};

class TopLeftChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updated: false,
    };
  }

  dataObj = {
    data: [
      {
        labels: ["Small Fire", "Medium Fire", "Large Fire"],
        datasets: [
          {
            data: [this.props.data.Small, this.props.data.Medium, this.props.data.Large],
            backgroundColor: ["#1c5a93", "#fec14b", "#f33c5d"],
            hoverBackgroundColor: ["#1c5a93", "#fec14b", "#f33c5d"],
          },
        ],
      },
    ],
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.dataObj = {
        data: [
          {
            labels: ["Small Fire", "Medium Fire", "Large Fire"],
            datasets: [
              {
                data: [this.props.data.Small, this.props.data.Medium, this.props.data.Large],
                backgroundColor: ["#1c5a93", "#fec14b", "#f33c5d"],
                hoverBackgroundColor: ["#1c5a93", "#fec14b", "#f33c5d"],
              },
            ],
          },
        ],
      };
      this.setState({ updated: !this.state.updated });
    }
  }

  render() {
    return (
      <div className="pie-wrapper">
        <Pie data={this.dataObj.data[0]} width={this.props.width} options={options} />
      </div>
    );
  }
}
export default TopLeftChart;
