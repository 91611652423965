import {
  SET_DSS_TOKEN,
  SET_ALLOCATION_EMS,
  SET_EXPECTED_CASUALTIES,
  SET_HOSPITAL_PATIENTS,
  SET_ACTORS_FIELDS,
  SET_SERVICE2_ACTORS_FIELDS,
  SET_SERVICE3_ACTORS_FIELDS,
} from "../../actions/types";

const initialState = {
  token: null,
  refreshToken: null,
  tokenExp: null,
  userId: null,
  jti: null,
  allocationEMSArray: [],
  expectedCasualties: [],
  hospitalPatients: [],
  actorsFields: [],
  service2ActorsFields: [],
  service3ActorsFields: [],
  isAllocationEMSArrayLoading: true,
  isExpectedCasualtiesLoading: true,
  isHospitalPatientsLoading: true,
  isActorsFieldsLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DSS_TOKEN:
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        tokenExp: action.tokenExp,
        userId: action.userId,
        jti: action.jti,
        allocationEMSArray: [],
        expectedCasualties: [],
        hospitalPatients: [],
        actorsFields: [],
        isAllocationEMSArrayLoading: true,
        isExpectedCasualtiesLoading: true,
        isHospitalPatientsLoading: true,
        isActorsFieldsLoading: true,
      };
    case SET_ALLOCATION_EMS:
      return {
        ...state,
        allocationEMSArray: action.payload,
        isAllocationEMSArrayLoading: false,
      };
    case SET_EXPECTED_CASUALTIES:
      return {
        ...state,
        expectedCasualties: action.payload,
        isExpectedCasualtiesLoading: false,
      };
    case SET_HOSPITAL_PATIENTS:
      return {
        ...state,
        hospitalPatients: action.payload,
        isHospitalPatientsLoading: false,
      };
    case SET_ACTORS_FIELDS:
      return {
        ...state,
        actorsFields: action.payload,
        isActorsFieldsLoading: false,
      };
    case SET_SERVICE2_ACTORS_FIELDS:
      return {
        ...state,
        service2ActorsFields: action.payload,
      };
    case SET_SERVICE3_ACTORS_FIELDS:
      return {
        ...state,
        service3ActorsFields: action.payload,
      };
    default:
      return state;
  }
}
