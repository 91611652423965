import classnames from "classnames";
import React, { Component } from "react";
import { InciInstance, GoogleAPIKey } from "../../../../config";
import Select from "react-select";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import ErrorModal from "../../../../components/UI/Modal/ErrorModal";

import Geocode from "react-geocode";

Geocode.setApiKey(GoogleAPIKey);
Geocode.enableDebug();

class SitrepForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Basic Incident details
      name: "",
      date: "",
      time: "",
      incidentStatusCode: "",
      description: "",

      //Emergency Type
      typeOfEmergency: "",
      additionalInformation: "",

      //Number of Patients
      numberOfAdultPatients: "",
      numberOfChildren: "",
      numberOfFatalities: "",
      patientsAdditionalInformation: "",

      //Location

      location: "",
      lat: "",
      lng: "",
      areaType: "",

      //Hazard Type
      hazards: [],
      hazardBackground: "",

      //Activity

      isError: true,

      updatedSitrep: null,

      valName: "",
      valincidentStatusCode: "",
      valTypeOfEmergency: "",
      valHazards: "",
      valLocation: "",
      valAreaType: "",
      valNumberOfAdultPatients: "",
      valNumberofChildren: "",
      valNumberOfFatalities: "",
      prePopulate: false,
      geocode: true,
      newSitrep: null,
      loading: false,
      show: false,
      error: {},
    };

    this.onChange = this.onChange.bind(this);
    this.handleChangeHazards = this.handleChangeHazards.bind(this);
  }

  componentDidUpdate() {
    if (this.state.geocode) {
      Geocode.fromAddress(this.state.location).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          this.setState({
            lat: lat,
            lng: lng,
            geocode: false,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }


  handleClose = () => {
    this.setState({show: false});
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeHazards = async (value) => {
    await this.setState({ hazards: value });
  };

  // Check input fields for errors
  validate = () => {
    let isError = false;

    if (this.state.name.length < 5 || this.state.name.length > 100) {
      isError = true;
      this.setState({ valName: "Name must be between 5 and 100 characters" });
    }
    if (this.state.incidentStatusCode === "") {
      isError = true;
      this.setState({
        valincidentStatusCode: "Incident Status Code must be chosen",
      });
    }
    if (this.state.typeOfEmergency === "") {
      isError = true;
      this.setState({
        valTypeOfEmergency: "Type of Emergency must be chosen",
      });
    }
    if (this.state.hazards === "") {
      isError = true;
      this.setState({
        valHazards: "Hazard Type must be chosen",
      });
    }
    if (this.state.location === "") {
      isError = true;
      this.setState({
        valLocation: "A Location must be chosen",
      });
    }
    if (this.state.areaType === "") {
      isError = true;
      this.setState({
        valAreaType: "An Area Type must be chosen",
      });
    }
    if (isNaN(this.state.numberOfAdultPatients) || this.state.numberOfAdultPatients.length > 1000) {
      isError = true;
      this.setState({
        valNumberOfAdultPatients: "A valid NUMBER up to 100000 is needed",
      });
    }
    if (isNaN(this.state.numberOfChildren) || this.state.numberOfChildren.length > 1000) {
      isError = true;
      this.setState({
        valNumberofChildren: "A valid NUMBER up to 100000 is needed",
      });
    }
    if (isNaN(this.state.numberOfFatalities) || this.state.numberOfFatalities.length > 1000) {
      isError = true;
      this.setState({
        valNumberOfFatalities: "A valid NUMBER up to 100000 is needed",
      });
    }

    return isError;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      valName: "",
      valincidentStatusCode: "",
      valTypeOfEmergency: "",
      valHazards: "",
      valLocation: "",
      valAreaType: "",
      valNumberOfAdultPatients: "",
      valNumberofChildren: "",
      valNumberOfFatalities: "",
    });
    try {
      const valid = this.validate();
      if (!valid) {
        const newSitrep = {
          name: this.state.name,
          incidentId: this.props.security.user.incidentId,
          incidentTimeOfAnnouncement: this.state.date + " " + this.state.time,
          incidentStatusCode: this.state.incidentStatusCode,
          description: this.state.description,

          //Emergency Type
          typeOfEmergency: this.state.typeOfEmergency,
          additionalInformation: this.state.additionalInformation,

          //Number of Patients
          numberOfAdultPatients: this.state.numberOfAdultPatients,
          numberOfChildren: this.state.numberOfChildren,
          numberOfFatalities: this.state.numberOfFatalities,

          //Location
          location: this.state.location,
          lat: this.state.lat,
          lng: this.state.lng,
          areaType: this.state.areaType,

          //Hazard Type
          hazardtype_set: this.state.hazards.map((value) => value.value),
          hazardType: ["ACCESS"],
          hazardBackground: this.state.hazardBackground,
          //nullables
          priorityDispatchCard: "ABDOMINAL_PAIN_PROBLEMS",
          priorityDispatchCode: "IMMEDIATE",
          eventLogs: true,
        };
        const authentication = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        };

        await InciInstance.post(`sitrep/`, newSitrep, authentication);
        // const updatedSitrep = await InciInstance.get(
        //   `/incident/${this.props.location.prePopulate.id}`
        // );
        // this.setState({ updatedSitrep: updatedSitrep.data.data, loading: false });
      }

      this.props.history.push({
        pathname: "/",
        // prePopulate: this.state.updatedSitrep,
        hasArrived: this.props.location.hasArrived,
      });
    } catch (error) {
      console.log("Register Sitrep API error");
      this.setState({ loading: false, error: error, show: true})
    }
  };

  // Convert value list to label value list
  converthazardList = (hazardValuesList) => {
    const hazardsLabelsList = [
      { label: "Access", value: "ACCESS" },
      { label: "Animal Hazard to Traffic", value: "ANIMAL_HAZARD_TO_TRAFFIC" },
      {
        label: "Building Under Construction/Demolition",
        value: "BUILDING_UNDER_CONSTRUCTION_DEMOLITION",
      },
      { label: "Chemical Exposure", value: "CHEMICAL_EXPOSURE" },
      { label: "Confined Space", value: "CONFINED_SPACE" },
      { label: "Contamination", value: "CONTAMINATION" },
      { label: "Corrosives", value: "CORROSIVES" },
      { label: "Crush Injuries", value: "CRUSH_INJURIES" },
      { label: "Dangerous Animal", value: "DANGEROUS_ANIMAL" },
      { label: "Dangerous Crowd Density", value: "DANGEROUS_CROWD_DENSITY" },
      { label: "Deep Water", value: "DEEP_WATER" },
      { label: "Disorder", value: "DISORDER" },
      { label: "Disorder with Firearms", value: "DISORDER_WITH_FIREARMS" },
      { label: "Edged Weapons Known/Suspected", value: "EDGED_WEAPONS_KNOWN_SUSPECTED" },
      { label: "Explosion", value: "EXPLOSION" },
      { label: "Fallen and Loose Debris", value: "FALLEN_AND_LOOSE_DEBRIS" },
      { label: "Fallen Trees", value: "FALLEN_TREES" },
      { label: "Fast Flowing Water", value: "FAST_FLOWING_WATER" },
      { label: "Fire", value: "FIRE" },
      { label: "Firearms Involved", value: "FIREARMS_INVOLVED" },
      { label: "Firearms Known", value: "FIREAMRS_KNOWN" },
      { label: "Flammable Gas Exposure", value: "FLAMMABLE_GAS_EXPOSURE" },
      { label: "Flammable liquids", value: "FLAMMABLE_LIQUIDS" },
      { label: "Flammable Solids", value: "FLAMMABLE_SOLIDS" },
      { label: "Fuel Spill or Leak", value: "FUEL_SPILL_OR_LEAK" },
      { label: "Gas Leak", value: "GAS_LEAK" },
      { label: "Gases", value: "GASES" },
      { label: "Hazardous Cargo", value: "HAZARDOUS_CARGO" },
      { label: "High Winds", value: "HIGH_WINDS" },
      { label: "Ice", value: "ICE" },
      { label: "I.E.D", value: "IED" },
      { label: "Live Rail or Overhead Lines", value: "LIVE_RAIL_OR_OVERHEAD_LINES" },
      { label: "Loose or Unstable Structure", value: "LOOSE_OR_UNSTABLE_STRUCTURE" },
      { label: "Mud", value: "MUD" },
      { label: "Multiple Heat/Stress Casualties", value: "MULTIPLE_HEAT_STRESS_CASUALTIES" },
      { label: "Multiple Hypothermia", value: "MULTIPLE_HYPOTHERMIA" },
      { label: "Multiple Seats of Fire", value: "MULTIPLE_SEATS_OF_FIRE" },
      { label: "Multiple Vehicles", value: "MULTIPLE_VEHICLES" },
      { label: "Oxidising Substances", value: "OXIDISING_SUBSTANCES" },
      { label: "Persons Trapped", value: "PERSONS_TRAPPED" },
      { label: "Petrochemicals", value: "PETROCHEMICALS" },
      { label: "Power Lines Down", value: "POWER_LINES_DOWN" },
      { label: "Quicksand", value: "QUICKSAND" },
      { label: "Radioactive Material", value: "RADIOACTIVE_MATERIAL" },
      { label: "Rising Water Tide", value: "RISING_WATER_TIDE" },
      { label: "Secondary Devices", value: "SECONDARY_DEVICES" },
      { label: "Serious Disorder", value: "SERIOUS_DISORDER" },
      { label: "Sinking", value: "SINKING" },
      { label: "Smoke", value: "SMOKE" },
      { label: "Smoke Fumes", value: "SMOKE_FUMES" },
      { label: "Standing Water", value: "STANDING_WATER" },
      { label: "Still Water", value: "STILL_WATER" },
      { label: "Sub Surface Hazard", value: "SUB_SURFACE_HAZARD" },
      { label: "Swift Water", value: "SWIFT_WATER" },
      { label: "Toxic and Infectious Substances", value: "TOXIC_AND_INFECTIOUS_SUBSTANCES" },
      {
        label: "Trespassers on Electrical Installations",
        value: "TRESPASSERS_ON_ELECTRICAL_INSTALLATIONS",
      },
      { label: "Unstable Surface", value: "UNSTABLE_SURFACE" },
      { label: "Unstable Vehicle", value: "UNSTABLE_VEHICLE" },
      { label: "Unstable Vessel", value: "UNSTABLE_VESSEL" },
      { label: "Unstable Wreckage", value: "UNSTABLE_WRECKAGE" },
      { label: "Violence Towards Public", value: "VIOLENCE_TOWARD_PUBLIC" },
      { label: "Working at Height", value: "WORKING_AT_HEIGHT" },
      { label: "Working Sub-Surface", value: "WORKING_SUB_SURFACE" },
    ];
    let prepopulateHazardList = [];
    for (let i = 0; i < hazardValuesList.length; i++) {
      for (let k = 0; k < hazardsLabelsList.length; k++) {
        if (hazardValuesList[i] === hazardsLabelsList[k].value) {
          prepopulateHazardList.push(hazardsLabelsList[k]);
        }
      }
    }
    return prepopulateHazardList;
  };
  // Set the prepopulate values to the state
  componentDidMount = async () => {
    this.setState({ loading: true });
    try {
      let sitrep = await InciInstance.get(`/incident/${this.props.security.user.incidentId}/`);
      console.log("sitrep", sitrep);
      if (sitrep.data.data.sitrep_set.length > 0) {
        let index = sitrep.data.data.sitrep_set[0].incidentTimeOfAnnouncement.split("T");
        await this.setState({
          name: sitrep.data.data.sitrep_set[0].name,
          incidentStatusCode: sitrep.data.data.sitrep_set[0].incidentStatusCode,
          date: index[0],
          time: new Date(index[0] + "T" + index[1]).toLocaleTimeString(
            {},
            { timeZone: "UTC", hour12: false, hour: "numeric", minute: "numeric" }
          ),
          description: sitrep.data.data.sitrep_set[0].description,

          typeOfEmergency: sitrep.data.data.sitrep_set[0].typeOfEmergency,
          additionalInformation: sitrep.data.data.sitrep_set[0].additionalInformation,

          numberOfAdultPatients: sitrep.data.data.sitrep_set[0].numberOfAdultPatients,
          numberOfChildren: sitrep.data.data.sitrep_set[0].numberOfChildren,
          numberOfFatalities: sitrep.data.data.sitrep_set[0].numberOfFatalities,

          location: sitrep.data.data.sitrep_set[0].location,
          areaType: sitrep.data.data.sitrep_set[0].areaType,

          hazards: this.converthazardList(sitrep.data.data.sitrep_set[0].hazardtype_set),
          hazardBackground: sitrep.data.data.sitrep_set[0].hazardBackground,
        });

        this.setState({ loading: false });
      } else {
        let index = sitrep.data.data.incidentTimeOfAnnouncement.split("T");
        await this.setState({
          name: sitrep.data.data.name,
          incidentStatusCode: sitrep.data.data.incidentStatusCode,
          date: index[0],
          time: new Date(index[0] + "T" + index[1]).toLocaleTimeString(
            {},
            { timeZone: "UTC", hour12: false, hour: "numeric", minute: "numeric" }
          ),
          description: sitrep.data.data.description,

          typeOfEmergency: sitrep.data.data.typeOfEmergency,
          additionalInformation: sitrep.data.data.additionalInformation,

          numberOfAdultPatients: sitrep.data.data.numberOfAdultPatients,
          numberOfChildren: sitrep.data.data.numberOfChildren,
          numberOfFatalities: sitrep.data.data.numberOfFatalities,

          location: sitrep.data.data.location,
          areaType: sitrep.data.data.areaType,

          hazards: this.converthazardList(sitrep.data.data.hazardtype_set),
          hazardBackground: sitrep.data.data.hazardBackground,
        });
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({error: error, show: true})
    }
  };

  render() {
    const hazards = [
      { label: "Access", value: "ACCESS" },
      { label: "Animal Hazard to Traffic", value: "ANIMAL_HAZARD_TO_TRAFFIC" },
      {
        label: "Building Under Construction/Demolition",
        value: "BUILDING_UNDER_CONSTRUCTION_DEMOLITION",
      },
      { label: "Chemical Exposure", value: "CHEMICAL_EXPOSURE" },
      { label: "Confined Space", value: "CONFINED_SPACE" },
      { label: "Contamination", value: "CONTAMINATION" },
      { label: "Corrosives", value: "CORROSIVES" },
      { label: "Crush Injuries", value: "CRUSH_INJURIES" },
      { label: "Dangerous Animal", value: "DANGEROUS_ANIMAL" },
      { label: "Dangerous Crowd Density", value: "DANGEROUS_CROWD_DENSITY" },
      { label: "Deep Water", value: "DEEP_WATER" },
      { label: "Disorder", value: "DISORDER" },
      { label: "Disorder with Firearms", value: "DISORDER_WITH_FIREARMS" },
      { label: "Edged Weapons Known/Suspected", value: "EDGED_WEAPONS_KNOWN_SUSPECTED" },
      { label: "Explosion", value: "EXPLOSION" },
      { label: "Fallen and Loose Debris", value: "FALLEN_AND_LOOSE_DEBRIS" },
      { label: "Fallen Trees", value: "FALLEN_TREES" },
      { label: "Fast Flowing Water", value: "FAST_FLOWING_WATER" },
      { label: "Fire", value: "FIRE" },
      { label: "Firearms Involved", value: "FIREARMS_INVOLVED" },
      { label: "Firearms Known", value: "FIREAMRS_KNOWN" },
      { label: "Flammable Gas Exposure", value: "FLAMMABLE_GAS_EXPOSURE" },
      { label: "Flammable liquids", value: "FLAMMABLE_LIQUIDS" },
      { label: "Flammable Solids", value: "FLAMMABLE_SOLIDS" },
      { label: "Fuel Spill or Leak", value: "FUEL_SPILL_OR_LEAK" },
      { label: "Gas Leak", value: "GAS_LEAK" },
      { label: "Gases", value: "GASES" },
      { label: "Hazardous Cargo", value: "HAZARDOUS_CARGO" },
      { label: "High Winds", value: "HIGH_WINDS" },
      { label: "Ice", value: "ICE" },
      { label: "I.E.D", value: "IED" },
      { label: "Live Rail or Overhead Lines", value: "LIVE_RAIL_OR_OVERHEAD_LINES" },
      { label: "Loose or Unstable Structure", value: "LOOSE_OR_UNSTABLE_STRUCTURE" },
      { label: "Mud", value: "MUD" },
      { label: "Multiple Heat/Stress Casualties", value: "MULTIPLE_HEAT_STRESS_CASUALTIES" },
      { label: "Multiple Hypothermia", value: "MULTIPLE_HYPOTHERMIA" },
      { label: "Multiple Seats of Fire", value: "MULTIPLE_SEATS_OF_FIRE" },
      { label: "Multiple Vehicles", value: "MULTIPLE_VEHICLES" },
      { label: "Oxidising Substances", value: "OXIDISING_SUBSTANCES" },
      { label: "Persons Trapped", value: "PERSONS_TRAPPED" },
      { label: "Petrochemicals", value: "PETROCHEMICALS" },
      { label: "Power Lines Down", value: "POWER_LINES_DOWN" },
      { label: "Quicksand", value: "QUICKSAND" },
      { label: "Radioactive Material", value: "RADIOACTIVE_MATERIAL" },
      { label: "Rising Water Tide", value: "RISING_WATER_TIDE" },
      { label: "Secondary Devices", value: "SECONDARY_DEVICES" },
      { label: "Serious Disorder", value: "SERIOUS_DISORDER" },
      { label: "Sinking", value: "SINKING" },
      { label: "Smoke", value: "SMOKE" },
      { label: "Smoke Fumes", value: "SMOKE_FUMES" },
      { label: "Standing Water", value: "STANDING_WATER" },
      { label: "Still Water", value: "STILL_WATER" },
      { label: "Sub Surface Hazard", value: "SUB_SURFACE_HAZARD" },
      { label: "Swift Water", value: "SWIFT_WATER" },
      { label: "Toxic and Infectious Substances", value: "TOXIC_AND_INFECTIOUS_SUBSTANCES" },
      {
        label: "Trespassers on Electrical Installations",
        value: "TRESPASSERS_ON_ELECTRICAL_INSTALLATIONS",
      },
      { label: "Unstable Surface", value: "UNSTABLE_SURFACE" },
      { label: "Unstable Vehicle", value: "UNSTABLE_VEHICLE" },
      { label: "Unstable Vessel", value: "UNSTABLE_VESSEL" },
      { label: "Unstable Wreckage", value: "UNSTABLE_WRECKAGE" },
      { label: "Violence Towards Public", value: "VIOLENCE_TOWARD_PUBLIC" },
      { label: "Working at Height", value: "WORKING_AT_HEIGHT" },
      { label: "Working Sub-Surface", value: "WORKING_SUB_SURFACE" },
    ];
    let form = (
      <form onSubmit={this.onSubmit}>
        <h6>
          <b>Incident Details</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valName,
              })}
              placeholder="Name"
              name="name"
              value={this.state.name}
              onChange={this.onChange}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
            {this.state.valName && <div className="invalid-feedback">{this.state.valName}</div>}
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              className="form-control form-control-sm"
              name="date"
              value={this.state.date}
              onChange={this.onChange}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="time">Time</label>
            <input
              type="time"
              className="form-control form-control-sm"
              name="time"
              value={this.state.time}
              onChange={this.onChange}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="incidentStatusCode">Incident Status Code</label>
            <select
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valincidentStatusCode,
              })}
              name="incidentStatusCode"
              onChange={this.onChange}
              value={this.state.incidentStatusCode}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            >
              <option value="">Choose an Incident Status Code</option>
              <option value="MAJOR_INCIDENT_ALERT_STANDBY">MAJOR INCIDENT ALERT / STANDBY</option>
              <option value="MAJOR_INCIDENT_CONFIRMED_DECLARED">
                MAJOR INCIDENT CONFIRMED / DECLARED
              </option>
              <option value="MAJOR_INCIDENT_CANCEL">MAJOR INCIDENT CANCEL</option>
              <option value="AMBULANCE_MAJOR_INCIDENT_STOP">AMBULANCE MAJOR INCIDENT STOP</option>
              <option value="AMBULANCE_MAJOR_INCIDENT_SCENE_EVACUATION_COMPLETE">
                AMBULANCE MAJOR INCIDENT SCENE EVACUATION COMPLETE
              </option>
              <option value="AMBULANCE_MAJOR_INCIDENT_STAND_DOWN">
                AMBULANCE MAJOR INCIDENT STAND DOWN
              </option>
            </select>
            {this.state.valincidentStatusCode && (
              <div className="invalid-feedback">{this.state.valincidentStatusCode}</div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="description">Incident Description</label>
            <textarea
              type="text"
              className="form-control form-control-sm"
              placeholder="Insert An Incident Description Here"
              name="description"
              value={this.state.description}
              onChange={this.onChange}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
          </div>
        </div>
        <hr color="black" />
        <h6 htmlFor="typeOfEmergency">
          <b>Type of Emergency</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="typeOfEmergency">Emergency Type</label>
            <select
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valTypeOfEmergency,
              })}
              name="typeOfEmergency"
              onChange={this.onChange}
              value={this.state.typeOfEmergency}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            >
              <option value="">Choose a Type of Emergency</option>
              <option value="LANDSLIDE">Landslide</option>
              <option value="EARTHQUAKE">Earthquake</option>
              <option value="TSUNAMI">Tsuname</option>
              <option value="FLOOD">Flood</option>
              <option value="STORM_HURRICANE_TORNADO">Storm/Hurricane/Tornado</option>
              <option value="WINTER_STORM">Winter Storm</option>
              <option value="DISORDER">Disorder</option>
              <option value="UNCONTROLLED_CROWD">Uncontrolled Crowd</option>
              <option value="FIREARMS_INCIDENT">Firearms Incident</option>
              <option value="VIOLENCE">Violence</option>
              <option value="SIEGE">Siege</option>
              <option value="ANIMAL">Animal</option>
              <option value="IN_OR_NEAR_WATER">In or Near Water</option>
              <option value="BUILDING_FIRE">Building Fire</option>
              <option value="VEHICLE_FIRE">Vehicle Fire</option>
              <option value="WILD_FIRE">Wild Fire</option>
              <option value="FIRE_AT_WASTE_SITES">Fire at Waste Sites</option>
              <option value="POLLUTION">Pollution</option>
              <option value="AIR_AIRCRAFT_ACCIDENT">Air/Aircraft Accident</option>
              <option value="MARITIME_SHIP_FERRY_ACCIDENT">Maritime/Ship/Ferry accident</option>
              <option value="RAIL_TRAIN_ACCIDENT">Rail/Trail Accident</option>
              <option value="ROAD_ACCIDENT">Road Accident</option>
              <option value="ELECTRICITY_DAMAGE">Electricity Damage</option>
              <option value="GAS_DAMAGE">Gas Damage</option>
              <option value="WATER_DAMAGE">Water Damage</option>
              <option value="CHEMICAL_ACCIDENT">Chemical Accident</option>
              <option value="BIOLOGICAL_ACCIDENT">Biological Accident</option>
              <option value="RADIOLOGICAL_ACCIDENT">Radiological Accident</option>
              <option value="NUCLEAR_ACCIDENT">Nuclear Accident</option>
              <option value="EXPLOSION">Explosion</option>
            </select>
            {this.state.valTypeOfEmergency && (
              <div className="invalid-feedback">{this.state.valTypeOfEmergency}</div>
            )}
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="additionalInformation">Emergency Type Details</label>
            <textarea
              type="text"
              className="form-control form-control-sm"
              placeholder="Additional Emergency Information Details Here"
              name="additionalInformation"
              value={this.state.additionalInformation}
              onChange={this.onChange}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
          </div>
        </div>
        <hr color="black" />
        <h6>
          <b>Estimation of Human Casualties</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="numberOfAdultPatients">Number of Adults</label>
            <input
              type="text"
              placeholder="Number of Adults"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valNumberOfAdultPatients,
              })}
              name="numberOfAdultPatients"
              value={this.state.numberOfAdultPatients}
              onChange={this.onChange}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
            {this.state.valNumberOfAdultPatients && (
              <div className="invalid-feedback">{this.state.valNumberOfAdultPatients}</div>
            )}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="numberOfChildren">Number of Children</label>
            <input
              type="text"
              placeholder="Number of Children"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valNumberofChildren,
              })}
              name="numberOfChildren"
              value={this.state.numberOfChildren}
              onChange={this.onChange}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
            {this.state.valNumberofChildren && (
              <div className="invalid-feedback">{this.state.valNumberofChildren}</div>
            )}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="numberOfFatalities">Number of Fatalities</label>
            <input
              type="text"
              placeholder="Number of Fatalities"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valNumberOfFatalities,
              })}
              name="numberOfFatalities"
              value={this.state.numberOfFatalities}
              onChange={this.onChange}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
            {this.state.valNumberOfFatalities && (
              <div className="invalid-feedback">{this.state.valNumberOfFatalities}</div>
            )}
          </div>
        </div>
        <hr color="black" />
        <h6>
          <b>Hazard Types</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="hazards">Hazard Type</label>
            <Select
              options={hazards}
              isMulti
              onChange={this.handleChangeHazards}
              value={this.state.hazards}
              isDisabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="hazardBackground">Additional Information</label>
            <textarea
              type="text"
              className="form-control form-control-sm"
              placeholder="Insert Additional Hazard Description Here"
              name="hazardBackground"
              value={this.state.hazardBackground}
              onChange={this.onChange}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
          </div>
        </div>
        <hr color="black" />
        <h6>
          <b>Location</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="location">Location</label>
            <input
              type="text"
              placeholder="Location"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valLocation,
              })}
              name="location"
              value={this.state.location}
              onChange={this.onChange}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            />
            {this.state.valLocation && (
              <div className="invalid-feedback">{this.state.valLocation}</div>
            )}
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="areaType">Area Types</label>
            <select
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valAreaType,
              })}
              name="areaType"
              onChange={this.onChange}
              value={this.state.areaType}
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}
            >
              <option value="">Choose an Area Type</option>
              <option value="URBAN">Urban</option>
              <option value="RURAL">Rural</option>
              <option value="WOODS">Woods</option>
              <option value="FIELDS">Fields</option>
            </select>
            {this.state.valAreaType && (
              <div className="invalid-feedback">{this.state.valAreaType}</div>
            )}
          </div>
        </div>
        <br />

        <div className="text-center">
          <input type="submit" className="btn btn-danger btn-sm" 
              disabled={this.props.security.user.incidentRole !== "FIELD_COMMANDER"}/>
          <Link
            to={{
              pathname: "/",
              prePopulate: this.props.location.prePopulate,
              hasArrived: this.props.location.hasArrived,
            }}
          >
            <button type="button" className="btn btn-danger btn-sm">
              Cancel
            </button>
          </Link>
          <ErrorModal
            onHide={this.handleClose}
            handleClose={this.handleClose}
            error = {this.state.error}
            show  ={this.state.show}
        />
        </div>
      </form>
    );

    if (this.state.loading === true) {
      form = <Spinner />;
    }

    return (
      <div>
        <div className="RegisterPsapCenter">
          <div className="container">
            <div className="row">
              <div className="col-md-12 m-auto">
                <h6 className="display-4 text-center">Sitrep</h6>
                <hr />
                {form}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.security.user.sub,
  security: state.security,
});

export default connect(mapStateToProps, null)(SitrepForm);
