import React, { Component } from "react";
import { connect } from "react-redux";
import { UserInstance } from "../../../config";
import {
  CustomForm,
  FormRow,
  FormInput,
  FormWrapper,
} from "../../../components/UI/Form/Form";
import * as validation from "../../../components/UI/Form/formValidation";
import Dropzone from "react-dropzone";
import Avatar from "react-avatar";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css"; // Necessary for Modal component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faUserCog } from "@fortawesome/free-solid-svg-icons";


const config = {
  headers: {
    "Content-Type": "multipart/form-data;"
  }
}

class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: "",
      username: "",
      details: { role: "" },
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      
      open: false,
      loading: false,
      prePopulate: false,
    };
  }

  getUser = async () => {
    try {
      this.setState({ ...await (await UserInstance.get(`user/${this.props.security.user.user_id}`)).data.data });
    }
    catch (error) {
      console.log("Get User API error");
    }
  }

  componentDidMount = async () => {
    this.getUser();
  }

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onDrop = async (files) => {
    try {
      var formData = new FormData();
      formData.append("avatar", files[0]);
      await UserInstance.post("user/avatar/", formData, config);
      this.onCloseModal()
      this.getUser()
    }
    catch (error) {
      this.setState({ loading: false });
      console.log("Post User Avatar API error", error);
    }
  }

  onSubmit = async (formValues) => {
    this.state = formValues
    const user = {
      username: this.state.username,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      details: { role: this.state.role }
    }
    try {
      this.setState({ loading: true });
      await UserInstance.patch(`user/${this.state.id}/`, user);
      this.setState({ loading: false });
      await this.props.history.push('/Dashboard');
    }
    catch (error) {
      this.setState({ loading: false });
      console.log('Pacth User API error');
    }
  }

  userProfile = () => {
    return (
      <div>
        <div>
          <Avatar
            onClick={this.onOpenModal}
            className="react-avatar"
            src={this.state.avatar}
            color="#c7c7c7"
            fgColor="transparent"
            id="change-profile-picture-image"
            size="100"
            round={true}
          />
          <button onClick={this.onOpenModal} id="change-profile-picture-text">Change Profile Picture</button>
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal}>
          <Dropzone
            onDrop={file => this.onDrop(file)}
            multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <h3>Your User Avatar</h3>
                  <p>Drag and drop the image here, or click to select the file.</p>
                </div>
              </section>
            )}
          </Dropzone>
        </Modal>
      </div>
    );
  }

  render() {
    return (
      <div className="container">
        <div className="row ">
          <FontAwesomeIcon icon={faUserCog} size="lg" className="fontawesome-dash title-svg" />
          <h1 className="main-heading">Profile Settings</h1>
        </div>
        <div className="row profile-settings-row">
          <div className="col-md-12 mt-4">
            <h5 className="text-left table-number">User Info</h5>
          </div>
          <div className="col-2 mt-4">
            {this.userProfile()}
          </div>
          <div className="col-10">
            <FormWrapper
              parentClass="ProfileSettingsForm">
              <CustomForm
                initialValues={{ ...this.state, ...this.state.details }}
                onSubmit={(formValues) => this.onSubmit(formValues)}
                loading={this.state.loading}
              >
                <FormRow>
                  <FormInput
                    name="username"
                    noLabel
                    size="col-md-5"
                    validate={value => validation.username(value, 6, 100, this.state.username)}
                  />
                  <FontAwesomeIcon icon={faPen} size="sm" className="input-pen-svg" />
                </FormRow>
                <FormRow>
                  <FormInput
                    name="role"
                    noLabel
                    size="col-md-5"
                    validate={(value) => validation.inputIsRequired("User Role", value)}
                  />
                  <FontAwesomeIcon icon={faPen} size="sm" className="input-pen-svg" />
                </FormRow>
                <FormRow>
                  <FormInput
                    name="firstName"
                    adjacentLabel
                    size="col-md-5"
                    validate={(value) => validation.inputIsRequired("Name", value)}
                  />
                  <FontAwesomeIcon icon={faPen} size="sm" className="input-pen-svg" />
                </FormRow>
                <FormRow>
                  <FormInput
                    name="lastName"
                    adjacentLabel
                    size="col-md-5"
                    validate={(value) => validation.inputIsRequired("Last name", value)}
                  />
                  <FontAwesomeIcon icon={faPen} size="sm" className="input-pen-svg" />
                </FormRow>
                <FormRow>
                  <FormInput
                    name="email"
                    adjacentLabel
                    size="col-md-5"
                    validate={(value) => validation.email(value, this.state.email)}
                  />
                  <FontAwesomeIcon icon={faPen} size="sm" className="input-pen-svg" />
                </FormRow>
                <FormRow>
                  <FormInput
                    name="phoneNumber"
                    adjacentLabel
                    size="col-md-5"
                    validate={(value) => validation.phoneNumber(value)}
                  />
                  <FontAwesomeIcon icon={faPen} size="sm" className="input-pen-svg" />
                </FormRow>
                <FormRow>
                  <div className="text-right form-group col-md-12 form-button-wrapper">
                    <button type="submit" className="btn solid-button">Save</button>
                  </div>
                </FormRow>
              </CustomForm>
            </FormWrapper>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, null)(ProfileSettings);
