import React, { Component } from "react";
import "./checkbox.css";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    const { type, label, checked, name, wrapper } = this.props;
    return (
      <div className={`${wrapper} pb-1`}>
        <input
          type={type}
          placeholder={label}
          name={name}
          checked={checked}
          onChange={this.handleChange}
        />
        <label className="label">{label}</label>
      </div>
    );
  }
}

export default Checkbox;
