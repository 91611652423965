import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { authInstance } from "../../../../../config";
import "./triageDropdown.css";

export const TriageDropdown = (props) => {
  const [patients, setPatients] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  console.log("PATIENTS", patients);
  const getPatients = async () => {
    try {
      const response = await authInstance.get("triage/patient");
      if (response.status === 200) {
        setPatients(response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPatients();
  }, []);

  return (
    <div className="col-sm-6 aligner">
      <DropdownButton
        id="patient-filter-dropdown"
        title={`Edit Patient ${selectedFilter === null ? "" : selectedFilter}`}
        onSelect={(event) => {
          setSelectedFilter(event);
        }}
      >
        <div className="dropdown-scroll">
          {patients &&
            patients.map((patient) => {
              return (
                <Dropdown.Item
                  key={patient.id}
                  onClick={() => props.setPatient(patient)}
                  eventKey={`${patient.triage2.id}`}
                >{`Patient ${patient.triage2.id}`}</Dropdown.Item>
              );
            })}
        </div>
      </DropdownButton>
    </div>
  );
};

export default TriageDropdown;
