import React, { Component } from "react";
import { UserInstance } from "../../../../../../config";
import Spinner from "../../../../../../components/UI/Spinner/Spinner";
import { MDBDataTable } from "mdbreact";

class DispatchTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        {
          columns: [
            {
              label: "Psap",
              field: "psap",
              sort: "asc",
              width: 110,
            },
            {
              label: "Name",
              field: "name",
              sort: "asc",
              width: 110,
            },
            {
              label: "Last Name",
              field: "lastName",
              sort: "asc",
              width: 110,
            },

            {
              label: "Telephone",
              field: "telephone",
              sort: "asc",
              width: 110,
            },
            {
              label: "Special Expertise",
              field: "specialExpertise",
              sort: "asc",
              width: 110,
            },
            {
              label: "Email",
              field: "email",
              sort: "asc",
              width: 110,
            },
            {
              label: "Organization",
              field: "organizationType",
              sort: "asc",
              width: 110,
            },
          ],
          rows: [],
        },
      ],
      resMembers: [],
      pushedButton: false,
      emsStaffDispatch: [],
      userIds: [],
      dispatchedIds: [],
      newIncident: null,
      newSitrep: null,
      loading: false,
      value: null,
      dispatchedPsapIds: [],
      updatePsapAndEms: [],
    };
  }

  onPsapRoleSelectChange = async (choosenId, e) => {
    await this.setState({ value: e.target.value });
    let newPsapJson = {};
    let updatePsapAndEms = [];
    let stopper = false;

    this.state.resMembers.forEach((ems) => {
      if (ems.id.includes(choosenId)) {
        if (this.state.value !== "choose") {
          newPsapJson = { id: choosenId, psap: this.state.value === "true" ? true : false };
          this.setState({ updatePsapAndEms: [...this.state.updatePsapAndEms, newPsapJson] });
        } else if (this.state.value === "choose") {
          let removeActor = this.state.updatePsapAndEms;
          removeActor.forEach((actor) => {
            if (actor.id === choosenId) {
              removeActor.splice(removeActor.indexOf(actor), 1);
            }
          });
        }
      }
    });
    updatePsapAndEms = this.state.updatePsapAndEms;
    let valueArr = updatePsapAndEms.map((item) => {
      return item.id;
    });
    let isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });
    if (isDuplicate) {
      updatePsapAndEms.forEach((item) => {
        if (item.id === updatePsapAndEms[updatePsapAndEms.length - 1].id && stopper === false) {
          updatePsapAndEms.splice(updatePsapAndEms.indexOf(item), 1);
          stopper = true;
        }
      });
    }
    this.setState({ updatePsapAndEms });
  };

  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    if (this.props.location.origin === "RegisterIncident") {
      await this.setState({ newIncident: this.props.location.newIncident });
    } else if (this.props.location.origin === "Sitrep") {
      await this.setState({ newSitrep: this.props.location.newSitrep });
    } else {
      await this.setState({ newIncident: this.props.location.newIncident });
    }
    try {
      const resMembers = await UserInstance.get("ems/not-dispatched/");
      this.setState({ resMembers: resMembers.data.data });
      this.setState((prevState) => (prevState.data[0].rows = []));
      for (let i = 0; i < resMembers.data.data.length; i++) {
        if (
          this.props.history.location.updatePsapAndEms !== null &&
          this.props.history.location.updatePsapAndEms !== undefined &&
          this.props.history.location.updatePsapAndEms.length > 0
        ) {
          this.setState({ updatePsapAndEms: this.props.history.location.updatePsapAndEms });
          this.setState(
            (prevState) =>
              (prevState.data[0].rows = [
                ...prevState.data[0].rows,
                {
                  id: resMembers.data.data[i].id,
                  psap: (
                    <React.Fragment>
                      {this.props.history.location.updatePsapAndEms.some(
                        (element) => element.id === resMembers.data.data[i].id
                      ) ? (
                        this.props.history.location.updatePsapAndEms.map((ems, index) =>
                          ems.id === resMembers.data.data[i].id ? (
                            <select
                              onChange={(e) =>
                                this.onPsapRoleSelectChange(resMembers.data.data[i].id, e)
                              }
                              defaultValue={ems.psap}
                              key={index}
                            >
                              <option value="choose">Choose</option>
                              <option value={true}>Psap</option>
                              <option value={false}>EMS</option>
                            </select>
                          ) : null
                        )
                      ) : (
                        <select
                          onChange={(e) =>
                            this.onPsapRoleSelectChange(resMembers.data.data[i].id, e)
                          }
                        >
                          <option value="choose">Choose</option>
                          <option value={true}>Psap</option>
                          <option value={false}>EMS</option>
                        </select>
                      )}
                    </React.Fragment>
                  ),
                  name: resMembers.data.data[i].firstName,
                  lastName: resMembers.data.data[i].lastName,
                  position: resMembers.data.data[i].details.position,
                  telephone: resMembers.data.data[i].phoneNumber,
                  specialExpertise: resMembers.data.data[i].details.specialExpertise,
                  email: resMembers.data.data[i].email,
                  organizationType: resMembers.data.data[i].details.organizationType,
                },
              ])
          );
        } else {
          this.setState(
            (prevState) =>
              (prevState.data[0].rows = [
                ...prevState.data[0].rows,
                {
                  id: resMembers.data.data[i].id,
                  psap: (
                    <select
                      onChange={(e) => this.onPsapRoleSelectChange(resMembers.data.data[i].id, e)}
                    >
                      <option value="choose">Choose</option>
                      <option value={true}>Psap</option>
                      <option value={false}>EMS</option>
                    </select>
                  ),
                  name: resMembers.data.data[i].firstName,
                  lastName: resMembers.data.data[i].lastName,
                  position: resMembers.data.data[i].details.position,
                  telephone: resMembers.data.data[i].phoneNumber,
                  specialExpertise: resMembers.data.data[i].details.specialExpertise,
                  email: resMembers.data.data[i].email,
                  organizationType: resMembers.data.data[i].details.organizationType,
                },
              ])
          );
        }
      }

      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      console.log("Get EmsMembers API error");
    }
  };

  onClickDispatchSubmition = () => {
    if (this.props.location.origin === "RegisterIncident") {
      this.props.history.push({
        pathname: "/RegisterIncident",
        updatePsapAndEms: this.state.updatePsapAndEms,
        newIncident: this.state.newIncident,
        id: this.props.location.id,
      });
    } else if (this.props.location.origin === "Sitrep") {
      this.props.history.push({
        pathname: "/Sitrep",
        updatePsapAndEms: this.state.updatePsapAndEms,
        newSitrep: this.state.newSitrep,
        prePopulate: this.props.location.prePopulate,
        id: this.props.location.id,
      });
    } else if (this.props.location.origin === "RegisterIncidentEdit") {
      this.props.history.push({
        pathname: "/RegisterIncident",
        updatePsapAndEms: this.state.updatePsapAndEms,
        newIncident: this.state.newIncident,
        id: this.props.location.id,
        populate1: true,
      });
    }
  };
  onCancelClick = () => {
    this.props.history.push({
      pathname: "/RegisterIncident",
      updatePsapAndEms: this.state.updatePsapAndEms,
      newIncident: this.state.newIncident,
      id: this.props.location.id,
    });
  };
  render() {
    const EmsMembersPostSubmit = (
      <div>
        <MDBDataTable
          paging={false}
          maxHeight="400px"
          scrollY
          striped
          bordered
          hover
          small
          fixed
          data={this.state.data[0]}
        />
        <div className="text-center">
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={this.onClickDispatchSubmition}
          >
            Submit
          </button>

          <button type="button" className="btn btn-danger btn-sm" onClick={this.onCancelClick}>
            Cancel
          </button>
        </div>
      </div>
    );

    let ShowEmsMembers;

    if (this.state.loading === true) {
      ShowEmsMembers = <Spinner />;
    } else {
      ShowEmsMembers = EmsMembersPostSubmit;
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm text-center">{ShowEmsMembers}</div>
        </div>
      </div>
    );
  }
}

export default DispatchTable;
