import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faBell,
  faExclamationTriangle,
  faMap,
} from "@fortawesome/free-solid-svg-icons";
import organizations from "../../../assets/organizations.svg";
import users from "../../../assets/users.svg";
import dss from "../../../assets/dss.svg";

import { useDrop } from "react-dnd";
import ItemTypes from "../../../utils/dndItems";
import OrgMGT from "../../../containers/Screens/Admin/ResourceMGT/Organizations/OrgMGT";
import IncidentMGT from "../../../containers/Screens/Admin/ResourceMGT/Incidents/IncidentMGT";
import UsersMGT from "../../../containers/Screens/Admin/ResourceMGT/RegisterUser/UsersMGT";
import DssForDashboard from "../../../containers/Screens/Admin/DssForDashboard";
import NotificationsPage from "../../../containers/Screens/Notifications/NotificationsPage";
import IncidentsMap from "../../../containers/Screens/Admin/ResourceMGT/Incidents/IncidentsMap";
import CisMapOfficial from "../../../containers/Screens/IncidentModule/CisComponents/Map/CisMapOfficial";

const DashboardDropTarget = ({ lastDroppedItem, savedPanels, fetchData, setPanels, panels, isEditing, size, user, setExist, incidentRole, incidentId, actorsFields, service2ActorsFields, service3ActorsFields }) => {
  
  //this is the component id that is dropped from the drag and drop. This determines the component that is rendered.
  // Use it for the backend and pass it to the parent to be sent onSave.
  const [id, setId] = useState(savedPanels.name);
  const targetArr = savedPanels.target.split(".");
  const [idArr, setIdArr] = useState(savedPanels.id);

  //changes the style of each box based on whether an item is dragged over it, on whether it is empty or loaded
  const selectStyle = () => {
    let selectedClass;
    selectedClass = isOver
      ? "bg-box-hover"
      : id === null || id === undefined
        ? "bg-box-fill border-dashed"
        : "bg-box-fill";
    return selectedClass;
  };

  const removePanel = () => {

    var axios = require('axios');

    var config = {
      method: 'delete',
      url: 'https://back.concorde-cognos.com/api/custom-dashboard/dashboard-component/'+ idArr +'/',
      headers: { 
        'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"), 
      }
    };

    axios(config)
    .then(function (response) {
      for (let i = 0; i < lastDroppedItem.length; i++) {
        if(lastDroppedItem[i].target === savedPanels.target){                    
          lastDroppedItem[i].id = null
          lastDroppedItem[i].name = null
        }
      }
      //console.log(lastDroppedItem);      
      setPanels((curState) => curState.map(panel => panel.name === id ? {...panel, isVisible : true } : panel  ))
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const postData = (id) => {

    var axios = require('axios');
    var data
    var config

    if(savedPanels.id === null){ 

      data = JSON.stringify({
        "component_name": id,
        "row": targetArr[0],
        "column": targetArr[1]
      });
      config = {
        method: 'post',
        url: 'https://back.concorde-cognos.com/api/custom-dashboard/dashboard-component/',
        headers: { 
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"), 
          'Content-Type': 'application/json'
        },
        data : data
      };
    } else { 

      data = JSON.stringify({
        "component_name": id,
      });

      config = {
        method: 'put',
        url: 'https://back.concorde-cognos.com/api/custom-dashboard/dashboard-component/'+ savedPanels.id +'/',
        headers: { 
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"), 
          'Content-Type': 'application/json'
        },
        data : data
      };      
    }
      
    axios(config)
    .then(function (response) {      
      if(savedPanels.name === null){
        setIdArr(response.data.data.id);
        for (let i = 0; i < lastDroppedItem.length; i++) {
          if(lastDroppedItem[i].target === savedPanels.target){                    
            if(response.data.data.id){ 
              lastDroppedItem[i].id = response.data.data.id
            } 
            lastDroppedItem[i].name = id
            setPanels((curState) => curState.map(panel => panel.name === lastDroppedItem[i].name ? {...panel, isVisible : false } : panel  ))
          }
        }
        //console.log(lastDroppedItem);      
      } else {        
        setPanels((curState) => curState.map(panel => panel.name === savedPanels.name ? {...panel, isVisible : true } : panel  ))
        setPanels((curState) => curState.map(panel => panel.name === id ? {...panel, isVisible : false } : panel  ))
        savedPanels.id = null
        savedPanels.name = null
      }      
    })
    .catch(function (error) {
      console.log(error);
    });     
  }  

  //displays the selected component icon in the corresponding box
  const chooseComponent = (id) => {
    switch (id) {
      case "Organizations":
        return (
          <div>
            <img src={organizations} alt="organizations" width={30} height={32} className="min-w-min" />
          </div>
        );
      case "Users":       
        return (
          <div>
            <img src={users} alt="users" width={30} height={32} className="min-w-min" />
          </div>
        );
      case "DSS":
        return (
          <div>
            <img src={dss} alt="dss" width={30} height={32} className="min-w-min" />
          </div>
        );
      case "Incidents":
        return (
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-concorde-blue" size="2x" />
        );
      case "Notifications":
        return <FontAwesomeIcon icon={faBell} className="text-concorde-blue" size="2x" />;
      case "Map":
        return <FontAwesomeIcon icon={faMap} size="2x" className="text-concorde-blue self-center" />;
      default:
        return <FontAwesomeIcon icon={faPlusCircle} size="2x" className="text-icon-circle self-center" />;
    }
  };

  //gets the data from the drag and loads it into the state
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.COMPONENT,
    drop: (item, monitor) => {
      setId(item.id);
      postData(item.id);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  //renders the actual component when you press save
  const loadComponent = (id) => {
    switch (id) {
      case "Organizations":
        setExist(true);
        return <div className={`${size === "h-64" ? "h-80" : "h-64" }`}><OrgMGT /></div>;
      case "Users":
        setExist(true);
        return <div className={`${size === "h-64" ? "h-80" : "h-64" }`}><UsersMGT /></div>;
      case "DSS":
        setExist(true);
        return <div className={`${size === "h-64" ? "h-80" : "h-64" }`}><DssForDashboard /></div>;
      case "Incidents":
        setExist(true);
        return <div className={`${size === "h-64" ? "h-80" : "h-64" }`}><IncidentMGT /></div>;
      case "Notifications":
        setExist(true);
        return (
          <div className={`${size === "h-64" ? "h-80" : "h-64" } bg-white rounded-2xl px-4 pt-4 pb-2 overflow-scroll`}>
            <NotificationsPage />
          </div>
        );
      case "Map":
        setExist(true);
        return <div className={`${user === "HIGH_COMMANDER" ? "h-64" : "h-auto" }`}>{user === "HIGH_COMMANDER" ? <IncidentsMap isShownInDashboard={true} /> : <CisMapOfficial emsData={actorsFields}
        service2Data={service2ActorsFields}
        service3Data={service3ActorsFields}
        type={`${
          incidentRole === "EMS" ? null : "fieldCommander"
        }`}
        incidentId={incidentId} /> }</div>;
      default:
        setExist(false);
        return null;
    }
  };

  //handles the close button. SetExist communicates to the parent whether a component has been loaded.
  const handleClose = () => {
    setId(null)
    setExist(true)
    removePanel()
  }
  
  //determines what is rendered
  if (isEditing) {    
    return (
      <div
        ref={drop}
        className={`${selectStyle()} ${size} border-2 border-concorde-light rounded-xl gap-y-1 grid ${!id ? "grid-rows-1" : "grid-rows-3"}`}>
        {id && (
          <button
            className="text-concorde-light text-xs font-bold self-start justify-self-end mr-3 mt-2.5"
            onClick={() => handleClose()}>
            X
          </button>
        )}
        <div className="flex flex-col self-center items-center">
        {chooseComponent(id)}
        {id && <span className="text-concorde-light text-xl justify-center">{id}</span>}
        </div>
      </div>
    );
  } else {
    if (!id) {
        setExist(false)
        return null;
    } else {
       return loadComponent(id);
    }
  }
};

export default DashboardDropTarget;
