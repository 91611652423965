import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, getIn } from "formik";
import Spinner from "../Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as lodash from "lodash";

export const CustomForm = (props) => {
  if (props.loading) {
    return <Spinner />;
  } else {
    return (
      <Formik
        enableReinitialize={true} // This prop is needed so that we can update form values externally.
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
      >
        <Form className="custom-form">{props.children}</Form>
      </Formik>
    );
  }
};

export const FormRow = (props) => {
  return <div className="form-row">{props.children}</div>;
};

export const FormSubHeading = (props) => {
  return <h6 className="mt-4 table-sub-header">{props.children}</h6>;
};

export const FormWrapper = (props) => {
  return (
    <div>
      <div className={props.parentClass}>
        <div className="container">
          {props.mainHeading ? (
            <div className="row ">
              <FontAwesomeIcon icon={props.icon} size="lg" className="fontawesome-dash title-svg" />
              <h1 className="main-heading">{props.mainHeading}</h1>
            </div>
          ) : null}
          <div className="row">
            <div className="col-md-12 mt-4">
              <h5 className="text-left table-number">{props.componentHeading}</h5>
              <div className="mt-3">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButtons = (props) => {
  return (
    <div className="text-right form-group col-md-12 form-button-wrapper">
      <button type="submit" className="btn solid-button">
        Submit
      </button>
      <Link to={{ pathname: "/Dashboard" }}>
        <button type="button" className="btn outline-button">
          Cancel
        </button>
      </Link>
      <div className="required-text mb-4">{props.text}</div>
    </div>
  );
};

export const FormInput = (props) => {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, form: { touched, errors } }) => (
        <React.Fragment>
          {props.adjacentLabel && !props.noLabel ? (
            <label className="custom-form-label col-md-3" htmlFor={`${field.name}`}>
              {props.label ? props.label : lodash.startCase(field.name)}
            </label>
          ) : null}
          <div className={props.size ? `form-group ${props.size}` : "form-group col-md-3"}>
            {props.adjacentLabel || props.noLabel ? null : (
              <label className="custom-form-label" htmlFor={`${field.name}`}>
                {props.label ? props.label : lodash.startCase(field.name)}
              </label>
            )}
            <Field
              name={field.name}
              type={props.type ? props.type : "text"}
              placeholder={props.label ? props.label : lodash.startCase(field.name)}
              className={`form-control ${
                getIn(errors, props.name) && getIn(touched, props.name) ? "is-invalid" : ""
              }`}
            />
            <ErrorMessage component="div" name={field.name} className="invalid-feedback" />
          </div>
        </React.Fragment>
      )}
    </Field>
  );
};

export const FormDropdown = (props) => {
  const createOptionsHTML = () => {
    if (props.options) {
      let optionsHTML = [];
      optionsHTML.push(
        <option value="NONE">
          {props.label ? `Choose ${props.label}` : `Choose ${lodash.startCase(props.name)}`}{" "}
        </option>
      );
      props.options.forEach((i) => {
        optionsHTML.push(<option value={Object.keys(i)}>{Object.values(i)}</option>);
      });
      return optionsHTML;
    }
  };

  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, form: { touched, errors } }) => (
        <div className={props.size ? `form-group ${props.size}` : "form-group col-md-3"}>
          <label htmlFor={`${field.name}`}>
            {props.label ? props.label : lodash.startCase(field.name)}
          </label>
          <Field
            disabled={props.disable}
            name={field.name}
            as="select"
            value={props.value}
            className={`form-control ${
              getIn(errors, props.name) && getIn(touched, props.name) ? "is-invalid" : ""
            }`}
          >
            {createOptionsHTML()}
          </Field>
          <ErrorMessage component="div" name={field.name} className="invalid-feedback" />
        </div>
      )}
    </Field>
  );
};
