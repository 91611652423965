import {
  SHOW_REGISTER,
  SHOW_INCIDENTS,
  SHOW_MEMBERS,
  CLOSE_REGMEMB,
  CLEAR_CSS,
  REMOVE_CLEAR_CSS,
} from "../types";

export const showRegister = () => {
  return {
    type: SHOW_REGISTER,
  };
};

export const showMembers = () => {
  return {
    type: SHOW_MEMBERS,
  };
};

export const showIncidents = () => {
  return {
    type: SHOW_INCIDENTS,
  };
};

export const closeRegisterMember = () => {
  return {
    type: CLOSE_REGMEMB,
  };
};

export const clearCss = () => {
  return {
    type: CLEAR_CSS,
  };
};

export const removeClearCss = () => {
  return {
    type: REMOVE_CLEAR_CSS,
  };
};
