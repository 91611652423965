import React from "react"
import "./Documentation.css"

export const Documentation = (props) => {

    //TODO fix fonts
    return (
        <>  
            <div className="container-fluid">
                <p className="headers mb-2"><i className="fas fa-file-invoice mr-1"></i>Documentation</p>
                <div>
                    <p className="paragraphs mb-2">
                    <b>COncORDE</b> is a crisis management platform, designed in order to orchestrate a plethora of emergencies.&nbsp;
                    <b>COncORDE Cognos</b> is a learning platform, where the end-users can test&nbsp; 
                    <b>COncORDE</b> by creating new testing incidents, users and finally&nbsp; 
                    detect the rest of the EMS services, such as DSS, Event Log and Notifications.  
                    </p>
                    <p className="paragraphs mb-4"> 
                    COncORDE was used in Resistant and Search and Rescue, 2 EU Projects,&nbsp; 
                    where the platform was tested in several use case scenarios. 
                    </p>
                    <p className="paragraphs mb-4">
                        Below you can find more details for both projects.
                    </p>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <p className="headers mb-1">RESISTANT</p>
                        <div className="p-4 boxes">
                            <p className="mb-1">
                            RESISTANT’s aim is to build the first European Crisis Training Platform to train first responders through threefold comprehensive training,&nbsp;
                            including tools for the characterization of hazards and associated risks,&nbsp;
                            operational training on mock-up real-scale transport, and innovative virtual reality training reproducing the entire accident scenarios, intervention strategies, and tactics, including the whole chain of command and communications between different teams of people.
                            </p>
                            <p className="mb-1 sub-headers"> USE CASE Guidelines </p>
                            <div className="mb-4" style={{display: 'grid', gridTemplateColumns: '0.2fr 0.2fr'}}>
                                <a href="https://back.concorde-cognos.com/docs/D3.1 RESISTANT Use case guidelines and User's Handbook_v1_FINAL.pdf">
                                    <span className="file-names"><i className="fas fa-file mr-1"></i>D3.1</span>
                                </a>
                                <a href="https://back.concorde-cognos.com/docs/D3.2-RESISTANT-Use-case-Implementation-and-Evaluation-Report.pdf">
                                <span className="file-names"><i className="fas fa-file mr-1"></i>D3.2</span>
                                </a>
                            </div>
                            <p className="mb-4">More info: <a href="https://www.resistantproject.eu/" style={{color:'#065097'}}>https://www.resistantproject.eu/</a></p>
                            <button className='btn m-0' style={{backgroundColor:'#065097', color: 'white'}}>FORUM</button>
                        </div>
                    </div>
                    <div className="col-md-6 mb-2">
                    <p className="headers mb-1">SEARCH & RESCUE</p>
                        <div className="p-4 boxes">
                            <p className="mb-1">
                                Through a series of large-scale pilot scenarios, 
                                the Search and Rescue project designs, implements, and tests a highly interoperable open architecture platform for first responders, 
                                including advanced frontend equipment systems and backend applications, 
                                improving the decision-making of first responders and providing a dynamic common operational picture of the crisis.
                            </p>
                            <p className="mb-1 sub-headers"> User Manual </p>
                            <a href="https://back.concorde-cognos.com/docs/COncORDE_COGNOS_User_Manual.pdf">
                                <span className="file-names"><i className="fas fa-file mr-1"></i>COncORDE User Manual</span>
                            </a>

                            <p className="mt-2 mb-1 sub-headers"> Use Cases Portfolio </p>
                            <a href="https://search-and-rescue.eu/use-cases/" style={{color:'#065097'}}>https://search-and-rescue.eu/use-cases/</a>

                            <p className="mt-4">More info: <a href="https://search-and-rescue.eu/" style={{color:'#065097'}}>https://search-and-rescue.eu/</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
