import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

import { connect } from 'react-redux'

class ErrorModal extends Component {
  message = ''
  status = ''
  render() {
    let errorStatusSwitcher = () => {
      if (this.props.error.response) {
        switch (this.props.error.response.status) {
          case 400:
            return 'Bad Request'
          case 401:
            this.status = 'Login Failed'
            this.message = 'hahahaha'
            break
          case 403:
            return 'No Access Allowed'
          case 404:
            this.message = 'Try something else . . .'
            return 'The resource does not exist'
          case 408:
            return 'Connection Timeout'
          case 1212:
            return 'asfdf'
          default:
            return 'the default'
        }
      } else {
        this.message = 'Try again . . . '
        return 'Network error'
      }
    }

    let errorsStatus = () => {
      if (this.props.errors.response.status === 401) {
        // this.status = "401";
        this.message = 'Login Failed'
        return 'Please try again . . .'
      } else {
        return 'problem'
      }
    }

    return (
      <div>
        {this.props.show === true ? (
          <Modal id='errorModal' show={this.props.show} onHide={this.props.handleClose}>
            <Modal.Header closeButton id='errorModalHeader'>
              <Modal.Title id='errorModalTitle'>
                {this.props.error ? errorStatusSwitcher() : errorsStatus()}
                {this.status}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body id='errorModalBody'>
              {this.message}
              {this.props.response}
            </Modal.Body>
            <Modal.Footer id='errorModalFooter'>
              <Button className='btn-danger' onClick={this.props.handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <div></div>
        )}
      </div>
    )
  }
}

// export default ErrorModal;

const mapStateToProps = (state) => ({
  errors: state.errors,
})

export default connect(mapStateToProps)(ErrorModal)
