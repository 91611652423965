import React, { useContext, useState, useRef } from "react"
import { setConstantValue } from "typescript"
import { VolunteerContext } from "../../../context/Context"
import VolunteerService from "../../../services/VolunteerService"
import './VolunteerAvailableNotifications.css'
const volunteerService = VolunteerService.getInstance()

const VolunteerNotificationsPage = (props) => {

    // {username, notificationId}
    const [userInfo, setUserInfo] = useState({})
    const [isLoading, setIsLoading] = useState({})
    const notificationElementIdMapper = useRef({})
    const volunteerContext = useContext(VolunteerContext)
    volunteerContext.resetUnreadCounter(0)

    const tableHeaders = [
        'Incident',
        'Username',
        'Organization',
        'Role',
        'Date',
        'Status',
        'Actions'
    ]

    const notificationAction = async (notificationId, status) => {
        try {
            const updatedUserInfo = await volunteerService.updateVolunteerStatus(notificationId, status)
            console.log(updatedUserInfo.data)
        } catch(error) {
            console.error(error)
        }

    }
    const getVolunteerInfo = async (event, username, notificationId) => {
        
        if(notificationElementIdMapper.current[notificationId].style.display === 'table-row') {
            notificationElementIdMapper.current[notificationId].style.display = 'none'
        } else if(!userInfo[notificationId]) {
            const volunteerInfoResponse = await volunteerService.getVolunteerInfo(username)
            setUserInfo(oldUserInfo => ({...oldUserInfo, [notificationId]: volunteerInfoResponse.data }))
            notificationElementIdMapper.current[notificationId].style.display = 'table-row'
        } else {
            notificationElementIdMapper.current[notificationId].style.display = 'table-row'
        }
    }

    const rowStyle = (index) => {
        const rowColor= index % 2 === 0 ? '#fff' : '#eff6ff'
        return {
                displayed: {
                    cursor: 'pointer',
                    backgroundColor: rowColor,
                    textAlign: 'center'
                },
                hidden: {
                    display: 'none',
                    cursor: 'pointer',
                    backgroundColor: rowColor,
                    textAlign: 'left'
                }
              }
    }


    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    {tableHeaders.map(tableHeader => <th className="text-center"><b>{tableHeader}</b></th>)}
                </thead>
                <tbody>
                    {volunteerContext.notifications.map((notification,index) => {
                        return (<>
                                    <tr style={rowStyle(index).displayed}
                                        onClick={(event) => getVolunteerInfo(event, notification.username, notification.id)}
                                        id={notification.id}
                                        key={notification.id}>
                                        <td className="border-0 align-middle">{notification.incident}</td>
                                        <td className="border-0 align-middle">{notification.username}</td>
                                        <td className="border-0 align-middle">{notification.organization}</td>
                                        <td className="border-0 align-middle">{notification.role}</td>
                                        <td className="border-0 align-middle">{notification.timestamp}</td>
                                        <td className="border-0 align-middle">{notification.notif_status}</td>
                                        <td className="border-0 align-middle" onClick={event => event.stopPropagation()}>
                                            <button className="btn" onClick={() => notificationAction(notification.id, 'accepted')}>Accept</button>
                                            <button className="btn" onClick={() => notificationAction(notification.id, 'rejected')}>Reject</button>
                                        </td>
                                    </tr>
                                    <tr id={'id'+notification.id} ref={el => notificationElementIdMapper.current[notification.id] = el} style={rowStyle(index).hidden}>
                                        <td className="border-0" colSpan={tableHeaders.length}>
                                            <h6>Volunteer Information</h6>
                                            <div style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr'}}>
                                                <span><b>age:</b>{userInfo[notification.id]?.age}</span>
                                                <span><b>country:</b>{userInfo[notification.id]?.country}</span>
                                                <span><b>email:</b>{userInfo[notification.id]?.email}</span>
                                                <span><b>skills:</b> {userInfo[notification.id]?.skills}</span>
                                                <span><b>organization:</b> {userInfo[notification.id]?.organization}</span>
                                            </div>
                                            
                                        </td>
                                    </tr>
                                </>
                                )
                    })}
                </tbody>
            </table>
        </div>
            )
}

export default VolunteerNotificationsPage