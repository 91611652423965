import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../Redux/actions/Users/securityActions";
import store from "../store";

const checkExpiration = (expirationTime) => {
  let currentTime = Date.now() / 1000;
  if (expirationTime < currentTime) {
    store.dispatch(logout());
    return false;
  } else {
    return true;
  }
};

const SecuredRoute = ({ component: Component, security, ...otherProps }) => (
  <Route
    {...otherProps}
    render={(props) =>
      security.validToken === true && checkExpiration(security.tokenExp) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/Login" />
      )
    }
  />
);

SecuredRoute.propTypes = {
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, { logout })(SecuredRoute);
