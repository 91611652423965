import React, { Component } from "react";
import axios from "axios";
import Cloud from "../../../../assets/cloud.svg";
import "./Weather.css";

class Weather extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weatherRequest: {},
      degToCompass: "",
      windSpeed: "",
      atmosphericPressure: "",
      humidityLevel: "",
      temperatureLevel: "",
      weatherDiscription: "",
    };
  }

  componentDidMount = async () => {
    let weatherRequest = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${this.props.lat}&lon=${this.props.lng}&appid=a20ba6b1bda4d9df403a665a74ddf166`
    );
    await this.setState({
      weatherRequest: weatherRequest.data,
    });
    this.weatherDiscription(this.state.weatherRequest.weather[0].description);
    this.temperatureLevel(this.state.weatherRequest.main.temp);
    this.degToCompass(this.state.weatherRequest.wind.deg);
    this.windSpeed(this.state.weatherRequest.wind.speed);
    this.atmosphericPressure(this.state.weatherRequest.main.pressure);
    this.humidityLevel(this.state.weatherRequest.main.humidity);
  };
  weatherDiscription = (description) => {
    let weatherDiscription = description;
    this.setState({ weatherDiscription });
  };
  temperatureLevel = (temperature) => {
    let temperatureLevel = Math.round(temperature - 273.15).toString();
    this.setState({ temperatureLevel });
  };
  humidityLevel = (humidity) => {
    let humidityLevel = humidity.toString();
    this.setState({ humidityLevel });
  };
  windSpeed = (speed) => {
    let windSpeed = speed.toString();
    this.setState({ windSpeed });
  };
  atmosphericPressure = (pressure) => {
    let atmosphericPressure = pressure.toString();
    this.setState({ atmosphericPressure });
  };
  degToCompass = (num) => {
    let val = parseInt(num / 22.5 + 0.5);
    let arr = [
      "N",
      "NNE",
      "NE",
      "ENE",
      "E",
      "ESE",
      "SE",
      "SSE",
      "S",
      "SSW",
      "SW",
      "WSW",
      "W",
      "WNW",
      "NW",
      "NNW",
    ];
    let result = arr[val % 16];
    this.setState({ degToCompass: result });
  };
  render() {
    const {
      degToCompass,
      windSpeed,
      atmosphericPressure,
      humidityLevel,
      weatherDiscription,
      temperatureLevel,
    } = this.state;
    return (
      <React.Fragment>
        <div className="d-flex flex-column justify-content-center column-gap">
          <div className="ml-auto">
            <img src={Cloud} alt="" className="cloud-image mx-auto" />
          </div>
          <div className="weather-description">
            <div>{temperatureLevel}°C</div>
            <div>{weatherDiscription}</div>
          </div>
          <div>
            <ul className="weather-list">
              <li>
                Wind: {degToCompass}, speed {windSpeed} m/s
              </li>
              <li>Pressure: {atmosphericPressure} hpa</li>
              <li>Humidity: {humidityLevel} %</li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Weather;
