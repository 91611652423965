import {
  FETCH_ORGS_START,
  FETCH_ORGS_SUCCESS,
  FETCH_ORGS_FAILURE,
  EDIT_ORGS,
  DELETE_ORG,
  ORG_SELECTED,
} from "../types";
import history from "../../../history";
import { OrgInstance } from "../../../config";

const fetchOrgsStart = () => {
  return {
    type: FETCH_ORGS_START,
  };
};

const fetchOrgsSuccess = (orgs) => {
  return {
    type: FETCH_ORGS_SUCCESS,
    orgs,
  };
};

const fetchOrgsFailure = (error) => {
  return {
    type: FETCH_ORGS_FAILURE,
    error,
  };
};

const fetchOrgsDetails = () => {
  const response = OrgInstance.get("all/");
  return response;
};

export const fetchOrgs = () => (dispatch) => {
  dispatch(fetchOrgsStart());
  return fetchOrgsDetails().then(
    (response) => dispatch(fetchOrgsSuccess(response.data.data)),
    (error) => dispatch(fetchOrgsFailure(error))
  );
};

export const selectOrg = (id, org) => {
  return {
    type: ORG_SELECTED,
    id: id,
    org: org,
  };
};

export const deleteOrg = (id, type) => {
  return async (dispatch) => {
    let typeUrl;
    switch (type) {
      case "PSAP_CENTER":
        typeUrl = "psapcenter";
        break;
      case "HOSPITAL":
        typeUrl = "hospital";
        break;
      case "EMS":
        typeUrl = "ems";
        break;
      case "HIGHER_COMMAND":
        typeUrl = "highercommand";
        break;

      case "OTHER":
        typeUrl = "other";
        break;
      default:
        break;
    }

    const response = await OrgInstance.delete(`${typeUrl}/${id}/`);
    dispatch({ type: DELETE_ORG, payload: response.data });
    history.push("/");
  };
};

export const editOrgs = (id, type) => {
  return async (dispatch) => {
    let typeUrl;
    switch (type) {
      case "PSAP_CENTER":
        typeUrl = "psapcenter";
        break;
      case "HOSPITAL":
        typeUrl = "hospital";
        break;
      case "EMS":
        typeUrl = "ems";
        break;
      case "HIGHER_COMMAND":
        typeUrl = "highercommand";
        break;

      case "OTHER":
        typeUrl = "other";
        break;
      default:
        break;
    }

    const response = await OrgInstance.get(`/${typeUrl}/${id}/`);
    dispatch({ type: EDIT_ORGS, payload: response.data });

    switch (response.data.data.organizationType) {
      case "PSAP_CENTER":
        return history.push({
          pathname: "/RegisterPsapCenter",
          prePopulate: response.data.data,
        });
      case "HOSPITAL":
        return history.push({
          pathname: "/RegisterHospital",
          prePopulate: response.data.data,
        });
      case "EMS":
        return history.push({
          pathname: "/RegisterEms",
          prePopulate: response.data.data,
        });
      case "HIGHER_COMMAND":
        return history.push({
          pathname: "/RegisterHigherCommand",
          prePopulate: response.data.data,
        });

      case "OTHER":
        return history.push({
          pathname: "/RegisterOther",
          prePopulate: response.data.data,
        });
      default:
        return null;
    }
  };
};
