import { SHOW_DISPATCH_MODAL, HIDE_DISPATCH_MODAL } from "../../actions/types";

const INITIAL_STATE = {
  showDispatch: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_DISPATCH_MODAL:
      return { ...state, showDispatch: true };
    case HIDE_DISPATCH_MODAL:
      return { ...state, showDispatch: false };
    default:
      return state;
  }
};
