import React from "react";
import PatientModal from "./PatientModal";
import PatientBarChart from "./PatientBarChart";
import { Button } from "react-bootstrap";
import Spinner from "../../../../../components/UI/Spinner/Spinner";

import "./PhysioModal.css";

const PhysioModal = (props) => {
  return (
    <PatientModal closePhysioModal={props.closePhysioModal} graphData={props.graphData}>
      <div className="graph-sorting mt-2">
        {props.graphData === undefined ? (
          <p className="no-p-data text-center">Patient data doesn't exist.</p>
        ) : props.graphData === null || props.chartIsLoading === true ? (
          <Spinner />
        ) : (
          <>
            {props.graphData.physio.pies[0] && (
              <PatientBarChart
                labels={props.graphData.physio.pies[0].midpointsETD.map((point) => {
                  return Math.round((point + Number.EPSILON) * 100) / 100;
                })}
                label={props.graphData.physio.patient}
                data={props.graphData.physio.pies[0].frequenciesETD}
                time={props.graphData.physio.pies[0].timestamp}
              />
            )}
            {props.graphData.physio.pies[1] && (
              <PatientBarChart
                labels={props.graphData.physio.pies[1].midpointsETD.map((point) => {
                  return Math.round((point + Number.EPSILON) * 100) / 100;
                })}
                label={props.graphData.physio.patient}
                data={props.graphData.physio.pies[1].frequenciesETD}
                time={props.graphData.physio.pies[1].timestamp}
              />
            )}
            {props.graphData.physio.pies.length === 0 ? (
              <p className="no-p-data text-center">Patient data doesn't exist.</p>
            ) : null}
          </>
        )}
      </div>
      <Button
        className="modal-btn mt-4"
        onClick={() => {
          props.closePhysioModal();
        }}
      >
        Close
      </Button>
    </PatientModal>
  );
};

export default PhysioModal;
