import React, { Component } from "react";

class Result extends Component {
  render() {
    let { result } = this.props;
    return (
      <div className="result">
        <p id="acres">{result} Acres of Land</p>
      </div>
    );
  }
}

export default Result;
