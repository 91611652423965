import React from "react";
import moment from "moment";
import './Events.css';

export const StrainEvent = (props) => {
    return ( 
        <>
        {props.data ? 
        ( <div className="row row-eq-height mb-3 row-borders-events font-events" style={props.rowColor ? {backgroundColor: props.rowColor }: null}>
                <div className="col-3 vertical-centered-cols-events">
                    <div className="row">
                        <div className="col-12">
                            <span style={{fontWeight: 'bold'}}>Local:</span> {moment.unix(props.data.localTimestamp).format("DD/MM/YYYY HH:mm:ss")}
                        </div>
                    </div>
                </div>
                <div className="col-3 vertical-centered-cols-events">
                    <div className="row">
                        <div className="col-12">
                            <span style={{fontWeight: 'bold'}}>Sensor ID:</span> {props.data.id}
                        </div>
                        <div className="col-12">
                            <span style={{fontWeight: 'bold'}}>Sensor Type:</span> {props.data.fieldDataProducer}
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <span style={{fontWeight: 'bold'}}>Values:</span> &ensp;
                            {props.data?.environmentParametersDetection?.strainSensorAngleValue?.[0]}
                            &ensp;
                            {props.data?.environmentParametersDetection?.strainSensorAngleValue?.[1]}
                            &ensp;
                            {props.data?.environmentParametersDetection?.strainSensorAngleValue?.[2]}
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <span style={{fontWeight: 'bold'}}>Strain Sensor Angle Unit:</span>
                            &ensp; {props.data?.environmentParametersDetection?.strainSensorAngleUnitEnum}
                        </div>
                    </div>
                </div>
           </div> 
            ) : null}
        </>)
}