import React from 'react';
import Pdf from "react-to-pdf";

const ref = React.createRef();

const PDF = (props) => {
  return (
    <>
        <div className="col-md-12 align-items-stretch mt-3 mb-3">
            <div className="col-md-12 flex m-0 p-0">
              <h2 className="col-md-10 dash-secondary-heading-refactor m-0 p-0 py-3">Preview</h2>
              <div className="col-md-2 m-0 p-0 text-right">
                <Pdf targetRef={ref} filename="Press Release Information.pdf">
                    {({ toPdf }) => <button className="btn btn-danger btn-md" onClick={toPdf}>Export as PDF</button>}
                </Pdf>
              </div>

            </div>
          <div className="row dashboard-background-container" style={{ marginRight: "0px" }}>
            <div className="col-md-12" ref={ref}>
              <p className="text-dashboard-refactor mt-2">
              <ul>
                <li><b>General reason for the SAR operation: </b><br/> {props.reason} </li><br/>
                <li><b>Type of aircraft or vessel involved:</b><br/>{props.type}</li><br/>
                <li><b>Owner/Operator of the aircraft or vessel:</b><br/>{props.owner}</li><br/>
                <li><b>Name of vessel/flight number:</b><br/>{props.vessel_number}</li><br/>
                <li><b>Number of people on board:</b><br/>{props.people}</li><br/>
                <li><b>General area being searched:</b><br/>{props.area}</li><br/>
                <li><b>Number of hours flown:</b><br/>{props.hours_flown}</li><br/>
                <li><b>Number of first responders engaged in the SAR:</b><br/>{props.responders}</li><br/>
                <li><b>Number and types of aircraft and vessels engaged in the SAR:</b><br/>{props.vessels_engaged}</li><br/>
                <li><b>Arrangements for search at sea or on land (as applicable):</b><br/>{props.arrangements}</li><br/>
                <li><b>Details of other authorities participating in the search:</b><br/>{props.details}</li><br/>
                <li><b>Contact number for further information:</b><br/>{props.contact_number_further}</li><br/>
                <li><b>Contact number for media enquiries:</b><br/>{props.contact_number_media}</li><br/>
              </ul>
              </p>
            </div>
          </div>
        </div>
    </>
  );
}

export default PDF;
