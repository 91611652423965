import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { InciInstance } from "../../../../config";

export default class DispatchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstResponders: 0,
    };
  }

  onAddClick = (event) => {
    event.preventDefault();
    let counter = this.state.firstResponders;
    counter++;
    this.setState({ firstResponders: counter });
  };

  onMinusClick = (event) => {
    event.preventDefault();
    let minusCounter = this.state.firstResponders;
    minusCounter--;
    this.setState({ firstResponders: minusCounter });
  };
  handleSubmit = async () => {
    // event.preventDefault();
    let dispatchedInfo = {

      requestedEMS: this.state.firstResponders,
      incident: this.props.incidentId,
    };
    await InciInstance.post("/notification/", dispatchedInfo);
    console.log(dispatchedInfo);
  };

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  render() {
    return (
      <React.Fragment>
        {this.props.show ? (
          <Modal show={this.props.show} onHide={this.props.isClosed}>
            <Modal.Header closeButton>
              <Modal.Title>FIRST RESPONDERS</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="d-flex flex-row">
                <h6 className="first-responders-modal">First Responders</h6>

                <div className="d-flex flex-row">
                  <button
                    type="button"
                    className="btn btn-light ml-auto"
                    onClick={this.onMinusClick}
                  >
                    -
                  </button>
                  <div className="p-3">{this.state.firstResponders}</div>
                  <button type="button" className="btn btn-light mr-auto" onClick={this.onAddClick}>
                    +
                  </button>
                </div>
              </div>
              {/* <div className="d-flex flex-row">
                                <h6 className="inside-title-swimmers" style={{marginBottom:"-12%"}}> Airplanes</h6>
                            </div>
                                <div className="d-flex flex-row">
                                    <button
                                    type="button"
                                    className="btn btn-light ml-auto"
                                    onClick={this.onMinusClick}
                                    >
                                    -
                                    </button>
                                    <div className="p-2">{this.state.airplanes}</div>
                                    <button
                                    type="button"
                                    className="btn btn-primary mr-auto"
                                    onClick={this.onAddClick}
                                    >
                                    +
                                    </button>
                            </div>
                            <div className="d-flex flex-row">
                                <h6 style={{marginBottom:"-12%"}}> Volunteers</h6>
                            </div>
                                <div className="d-flex flex-row">
                                    <button
                                    type="button"
                                    className="btn btn-light ml-auto"
                                    onClick={this.onMinusClick}
                                    >
                                    -
                                    </button>
                                    <div className="p-2">{this.state.volunteers}</div>
                                    <button
                                    type="button"
                                    className="btn btn-primary mr-auto"
                                    onClick={this.onAddClick}
                                    >
                                    +
                                    </button>
                            </div>
                            <div className="d-flex flex-row">
                                <h6 style={{marginBottom:"-12%"}}> Fire Trucks</h6>
                            </div>
                                <div className="d-flex flex-row">
                                    <button
                                    type="button"
                                    className="btn btn-light ml-auto"
                                    onClick={this.onMinusClick}
                                    >
                                    -
                                    </button>
                                    <div className="p-2">{this.state.fireTrucks}</div>
                                    <button
                                    type="button"
                                    className="btn btn-primary mr-auto"
                                    onClick={this.onAddClick}
                                    >
                                    +
                                    </button>
                            </div>
                            <div className="d-flex flex-row">
                            <h6  style={{marginBottom:"-12%"}}>Comments </h6>
                                        <div className="form-group">
                            {/* <label htmlFor="observations">Comments </label> */}
              {/* <input
                                type="text"
                                className="form-control"
                                name="observations"
                                onChange={this.handleChange}
                                value={this.state.observations}
                                placeholder="(Comments . . . )"
                            />
                              </div>
                            </div> */}
              <div className="d-flex flex-row">
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="submit-button-first-responders"
                  // onClick={this.handleSubmit}
                  onClick={() => {
                    this.handleSubmit();
                    this.props.isClosed();
                  }}
                >
                  Submit
                </button>
              </div>
            </Modal.Body>
            {/* 
                       <Modal.Footer>
                            <Button variant="secondary" onClick={this.props.onHide}>
                                Close
                            </Button>
                        </Modal.Footer> */}
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}
