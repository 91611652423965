import {
  FETCH_PATIENTS_REQUEST,
  FETCH_PATIENTS_SUCCESS,
  FETCH_PATIENTS_FAILURE,
} from "../../actions/types";

const initialState = {
  isLoading: false,
  patients: [],
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PATIENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PATIENTS_SUCCESS:
      return {
        isLoading: false,
        patients: action.payload,
        error: "",
      };
    case FETCH_PATIENTS_FAILURE:
      return {
        isLoading: false,
        patients: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
