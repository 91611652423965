import React from "react";
import PatientModal from "./PatientModal";
import { authInstance } from "../../../../../config";

import "./DeleteModal.css";

const DeleteModal = (props) => {
  const deletePatient = async (id) => {
    try {
      await authInstance.delete(`triage/patient/${id}/`);
      props.setShowModal({
        show: false,
        patientId: null,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PatientModal setShowModal={props.setShowModal}>
      <div className="mt-2">
        <p className="delete-warning text-center">
          Are you sure you want to delete this patient? This action cannot be undone.
        </p>
      </div>
      <div className="mt-4 btn-box">
        <button
          className="delete-btn d-m-btn btn-lg"
          onClick={() => deletePatient(props.deleteModal.patientId)}
        >
          Delete
        </button>
        <button
          className="cancel-btn d-m-btn btn-lg"
          onClick={() =>
            props.setShowModal({
              show: false,
              patientId: null,
            })
          }
        >
          Cancel
        </button>
      </div>
    </PatientModal>
  );
};

export default DeleteModal;
