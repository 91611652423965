import React, { Component } from "react";

class RoomList extends Component {
  render() {
    const orderedRooms = [...this.props.rooms].sort((a, b) => a.id > b.id);
    return (
      <div className="rooms-list">
        <ul>
          <h3>Your rooms:</h3>
          {orderedRooms.map((room) => {
            const active = room.id === this.props.roomId ? "active" : "";
            return (
              <li key={room.id} className={"room" + active}>
                <a href="#" onClick={() => this.props.subscribeToRoom(room.name, room.id)}>
                  # {room.lastName}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default RoomList;
