import axios from "axios";

class NotificationsService {
  constructor() {}

  static getInstance() {
    return new NotificationsService();
  }

  // returns all types of notifications (alerts included)
  getAllNotifications() {
    return axios.get("https://snr.euprojects.net/v1/notifications/allnotifications");
  }

  getAllAlerts() {
    return axios.get("https://snr.euprojects.net/v1/notifications/allalerts");
  }

  async getLatestNotifications(numberOfEvents) {
    // add an alert here only for demo
    let alertsResponse = await this.getAllAlerts();
    let notificationsResponse = await this.getAllNotifications();

    let notificationsData = notificationsResponse.data;
    notificationsData.sort(function (x, y) {
      return y.timestamp - x.timestamp;
    });
    const slicedNotificationData = notificationsData.slice(0, numberOfEvents);
    slicedNotificationData.push(alertsResponse.data[0]);
    return slicedNotificationData;
  }
}

export default NotificationsService;
