import React, { Component } from "react";
import { InciInstance, UserInstance } from "../../../../config";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ErrorModal from "../../../../components/UI/Modal/ErrorModal";
import Spinner from "../../../../components/UI/Spinner/Spinner";

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBDataTable,
} from "mdbreact";

class IncidentTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data:
        // Actors
        // column types must be ordered the same as the fields of the rows objects
        [
          {
            columns: [
              {
                label: "Origin",
                field: "origin",
                sort: "asc",
                width: 50,
              },
              {
                label: "Lastname",
                field: "lastname",
                sort: "asc",
                width: 150,
              },
              {
                label: "Arrived",
                field: "arrived",
                sort: "asc",
                width: 50,
              },
              {
                label: "Role",
                field: "role",
                sort: "asc",
                width: 130,
              },
              {
                label: "Change Role",
                field: "changeRole",
                sort: "asc",
                width: 120,
              },
              {
                label: "Remove",
                field: "remove",
                sort: "asc",
                width: 120,
              },
            ],
            rows: [],
          },
        ],
      pushedButton: false,
      removedId: null,
      changeRoleId: null,
      modal14: false,
      modal15: false,
      userRole: "",
      show: false,
      loading: false,
      done: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  // Remove team member modal
  toggleModalRemoveActor = (nr, id) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      removedId: id,
    });
  };

  // Remove team member
  removeHandler = async (authentication) => {
    if (this.state.removedId !== null) {
      try {
        authentication = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        };
        await InciInstance.delete(
          `incident/${this.props.security.user.incidentId}/${this.state.removedId}/delete-user/`,
          authentication
        );
        this.tableUserRemovalLiveUpdate(this.state.removedId);

        this.setState({
          modal14: !this.state.modal14,
          pushedButton: false,
          removedId: null,
        });
      } catch (err) {
        console.log("Delete user API error");
      }
    }
  };

  error = "";
  errorRequest = "";
  // Change role modal
  toggleModalChangeRole = (nr, id) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      changeRoleId: id,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // Close/Open incident button
  changeRoleHandler = async (authentication) => {
    try {
      authentication = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      };
      let assignRoleObj = {
        userId: this.state.changeRoleId,
        fieldCommanderId: this.props.userId,
        incidentRole: this.state.userRole,
      };
      await InciInstance.post(
        `incident/${this.props.security.user.incidentId}/assignrole/`,
        assignRoleObj,
        authentication
      );

      this.setState({
        modal15: !this.state.modal15,
        pushedButton: false,
        changeRoleId: null,
      });
      this.tableUserRoleLiveUpdate(assignRoleObj.userId, assignRoleObj.incidentRole);
    } catch (err) {
      // this.error = err.response.data.message;
      // this.errorRequest = err.response.data.status;
      this.setState({ show: true });
      console.log("Change incident status API error");
    }
  };

  // Show team button
  userInfo = null;
  actorIds = [];
  users = [];
  handleClickTeam = async (authentication) => {
    this.setState({ pushedButton: true });
    try {
      authentication = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      };
      // Get incident actors
      const actors = await InciInstance.get(
        `incident/${this.props.security.user.incidentId}`,
        authentication
      );

      for (let actor of actors.data.data.dispatched_users) {
        this.actorIds.push(actor);
      }
      // Get all users to get the org type of each user
      const allUsers = await UserInstance.post("/user/user-ids/", { ids: this.actorIds });
      for (let user of allUsers.data.data.users) {
        this.users.push(user);
      }
      this.setState((prevState) => (prevState.data[0].rows = []));
      for (let i = 0; i < this.actorIds.length; i++) {
        this.userInfo = this.users.filter((element) => element.id === this.actorIds[i]);
        if (
          this.props.security.user.incidentRole === "PSAP" ||
          this.props.security.user.incidentRole === "EMS" ||
          this.props.security.user.incidentRole === "INACTIVE"
        ) {
          this.setState((prevState) => ({
            data: [
              {
                ...prevState.data[0],
                rows: [
                  ...prevState.data[0].rows,

                  {
                    origin: this.userInfo[0].details.organizationType,
                    id: this.userInfo[0].id,
                    lastname: this.userInfo[0].firstName,
                    arrived: JSON.stringify(
                      this.userInfo[0].details.incident_details[0].hasArrived
                    ),
                    role: this.userInfo[0].details.incident_details[0].incidentRole,
                    changeRole: (
                      <MDBBtn
                        disabled
                        color="primary"
                        size="sm"
                        onClick={this.toggleModalChangeRole(15, this.userInfo[0].id)}
                      >
                        Change Role
                      </MDBBtn>
                    ),
                    remove: (
                      <MDBBtn
                        disabled
                        color="danger"
                        onClick={this.toggleModalRemoveActor(14, this.userInfo[0].id)}
                        size="sm"
                      >
                        Remove
                      </MDBBtn>
                    ),
                  },
                ],
              },
            ],
          }));
        } else if (this.props.security.user.incidentRole === "FIELD_COMMANDER") {
          this.setState((prevState) => ({
            data: [
              {
                ...prevState.data[0],
                rows: [
                  ...prevState.data[0].rows,

                  {
                    origin: this.userInfo[0].details.organizationType,
                    id: this.userInfo[0].id,
                    lastname: this.userInfo[0].firstName,
                    arrived: JSON.stringify(
                      this.userInfo[0].details.incident_details[0].hasArrived
                    ),
                    role: this.userInfo[0].details.incident_details[0].incidentRole,
                    changeRole: (
                      <MDBBtn
                        color="primary"
                        size="sm"
                        onClick={this.toggleModalChangeRole(15, this.userInfo[0].id)}
                      >
                        Change Role
                      </MDBBtn>
                    ),
                    remove: (
                      <MDBBtn
                        color="danger"
                        onClick={this.toggleModalRemoveActor(14, this.userInfo[0].id)}
                        size="sm"
                      >
                        Remove
                      </MDBBtn>
                    ),
                  },
                ],
              },
            ],
          }));
        }
      }
      this.setState({ loading: false, done: true });
    } catch (err) {
      // this.error = err.response.data.message;
      // this.errorRequest = err.response.data.status;
      this.setState({ loading: false, show: true });
      console.log("Get users API error");
    }
  };

  tableUserRoleLiveUpdate = (userRoleChangeId, userRoleChangeUpdate) => {
    for (let i = 0; i < this.state.data[0].rows.length; i++) {
      if (this.state.data[0].rows[i].id === userRoleChangeId) {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.data[0].rows[i].role = userRoleChangeUpdate;
        this.setState(stateCopy);
      }
    }
  };
  tableUserRemovalLiveUpdate = (userRoleRemoveId) => {
    for (let i = 0; i < this.state.data[0].rows.length; i++) {
      if (this.state.data[0].rows[i].id === userRoleRemoveId) {
        let stateCopy = Object.assign({}, this.state);
        stateCopy.data[0].rows.splice(i, 1);
        this.setState(stateCopy);
      }
    }
  };
  render() {
    let roleOptions;
    for (let i = 0; i < this.users.length; i++) {
      if (this.state.changeRoleId === this.users[i].id) {
        if (
          this.users[i].details.position === "LOW" &&
          this.users[i].details.specialExpertise === "NO_MEDICAL_SKILLS"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="TRANSPORT_STAFF">Transport Staff (Driver)</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "LOW" &&
          this.users[i].details.specialExpertise === "BASIC_LIFE_SUPPORT"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="TRANSPORT_STAFF">Transport Staff</option>
              <option value="FIELD_COMMANDER">Field Commander</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "LOW" &&
          this.users[i].details.specialExpertise === "ADVANCED_LIFE_SUPPORT"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="FIELD_MEDIC">Field Medic</option>

              <option value="TRANSPORT_STAFF">Transport Staff</option>
              <option value="FIELD_COMMANDER">Field Commander</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "LOW" &&
          this.users[i].details.specialExpertise === "SPECIALIST_MEDICAL"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="FIELD_MEDIC">Field Medic</option>

              <option value="TRANSPORT_STAFF">Transport Staff</option>
              <option value="FIELD_COMMANDER">Field Commander</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "MEDIUM" &&
          this.users[i].details.specialExpertise === "NO_MEDICAL_SKILLS"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="TRANSPORT_STAFF">Transport Staff (Driver)</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "MEDIUM" &&
          this.users[i].details.specialExpertise === "BASIC_LIFE_SUPPORT"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>

              <option value="TRANSPORT_STAFF">Transport Staff</option>
              <option value="FIELD_COMMANDER">Field Commander</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "MEDIUM" &&
          this.users[i].details.specialExpertise === "ADVANCED_LIFE_SUPPORT"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="FIELD_MEDIC">Field Medic</option>
              <option value="TRANSPORT_OFFICER">Transport Officer</option>
              <option value="TRANSPORT_STAFF">Transport Staff</option>
              <option value="FIELD_COMMANDER">Field Commander</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "MEDIUM" &&
          this.users[i].details.specialExpertise === "SPECIALIST_MEDICAL"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="FIELD_MEDIC">Field Medic</option>
              <option value="MEDICAL_OFFICER">Medical Officer</option>
              <option value="TRANSPORT_OFFICER">Transport Officer</option>
              <option value="TRANSPORT_STAFF">Transport Staff</option>
              <option value="FIELD_COMMANDER">Field Commander</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "HIGH" &&
          this.users[i].details.specialExpertise === "NO_MEDICAL_SKILLS"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="TRANSPORT_OFFICER">Transport Officer</option>
              <option value="TRANSPORT_STAFF">Transport Staff</option>
              <option value="FIELD_COMMANDER">Field Commander</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "HIGH" &&
          this.users[i].details.specialExpertise === "BASIC_LIFE_SUPPORT"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="TRANSPORT_OFFICER">Transport Officer</option>
              <option value="TRANSPORT_STAFF">Transport Staff</option>
              <option value="FIELD_COMMANDER">Field Commander</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "HIGH" &&
          this.users[i].details.specialExpertise === "ADVANCED_LIFE_SUPPORT"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="FIELD_MEDIC">Field Medic</option>
              <option value="MEDICAL_OFFICER">Medical Officer</option>
              <option value="TRANSPORT_OFFICER">Transport Officer</option>
              <option value="TRANSPORT_STAFF">Transport Staff</option>
              <option value="FIELD_COMMANDER">Field Commander</option>
            </select>
          );
        }
        if (
          this.users[i].details.position === "HIGH" &&
          this.users[i].details.specialExpertise === "SPECIALIST_MEDICAL"
        ) {
          roleOptions = (
            <select
              className="form-control form-control-sm"
              name="userRole"
              onChange={this.onChange}
              value={this.state.userRole}
            >
              <option value="EN_ROUTE">Choose User Role</option>
              <option value="RUNNER">Runner</option>
              <option value="RETRIEVER">Retriever</option>
              <option value="FIELD_MEDIC">Field Medic</option>
              <option value="MEDICAL_OFFICER">Medical Officer</option>
              <option value="TRANSPORT_OFFICER">Transport Officer</option>
              <option value="TRANSPORT_STAFF">Transport Staff</option>
              <option value="FIELD_COMMANDER">Field Commander</option>
            </select>
          );
        }
      }
    }
    let ShowTeam;
    if (this.state.loading === true) {
      ShowTeam = <Spinner />;
    } else if (this.state.loading === false && this.state.done === true) {
      ShowTeam = (
        <div>
          <MDBDataTable
            maxHeight="500px"
            scrollY
            striped
            bordered
            fixed
            hover
            small
            data={{ columns: this.state.data[0].columns, rows: this.state.data[0].rows }}
          />
        </div>
      );
    }
    return (
      <div className="container">
        <div>
          <ErrorModal
            onHide={this.handleClose}
            handleClose={this.handleClose}
            show={this.state.show}
            status={this.errorRequest}
            message={this.error}
          />
        </div>
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "5px",
          }}
        >
          <MDBModal
            isOpen={this.state.modal14}
            toggle={this.toggleModalRemoveActor(14)}
            centered
            size="sm"
          >
            <MDBModalHeader toggle={this.toggleModalRemoveActor(14)}>
              Remove Team Member
            </MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.toggleModalRemoveActor(14)} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn color="danger" size="sm" onClick={this.removeHandler}>
                Remove
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>

        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "5px",
          }}
        >
          <MDBModal
            isOpen={this.state.modal15}
            toggle={this.toggleModalChangeRole(15)}
            centered
            size="sm"
          >
            <MDBModalHeader toggle={this.toggleModalChangeRole(15)}>Change Role</MDBModalHeader>
            <MDBModalBody>{roleOptions}</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" size="sm" onClick={this.changeRoleHandler}>
                Change Role
              </MDBBtn>
              <MDBBtn color="danger" onClick={this.toggleModalChangeRole(15)} size="sm">
                Cancel
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>

        <div className="row mt-5">
          <div className="col-sm">
            <h4>Incident Description</h4>
            <div className="border-bottom border-primary rounded">
              {/* <p>{this.props.location.prePopulate.description}</p> */}
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm">
            {this.state.pushedButton === false ? (
              <div className="button_container text-center">
                <button type="button" className="btn btn-primary" onClick={this.handleClickTeam}>
                  Show Team Members
                </button>
              </div>
            ) : (
              <div />
            )}
            {ShowTeam}
            <div className="row mt-5">
              <div className="col-sm">
                <div className="text-center">
                  <Link
                    to={{
                      pathname: "/",
                      prePopulate: this.props.location.prePopulate,
                      populate: true,
                      hasArrived: this.props.location.hasArrived,
                    }}
                  >
                    <button type="button" className="btn btn-danger btn-sm">
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  security: state.security,
  userId: state.security.user.user_id,
  selectedIncident: state.selectIncident,
});

export default connect(mapStateToProps, null)(IncidentTeam);
