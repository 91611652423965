import React from "react";
import "./PatientModal.css";

const PatientModal = (props) => {
  return (
    <>
      <div
        className="backdrop"
        onClick={() => {
          if (props.setShowModal) {
            props.setShowModal(false);
          } else {
            props.closePhysioModal();
            console.log("graphData", props.graphData);
          }
        }}
      />
      <div className="patient-modal">{props.children}</div>
    </>
  );
};

export default PatientModal;
