import React from "react";
import moment from "moment";
import "./Events.css";

export const SixGasEvent = (props) => {
  console.log("SixGasHazmat", props.data)
  return (
    <>
      {props.data ? (
        <div
          className="row row-eq-height mb-3 row-borders-events font-events"
          style={props.rowColor ? { backgroundColor: props.rowColor } : null}
        >
          <div className="col-3 vertical-centered-cols-events">
            <div className="row">
              <div className="col-12">
                <span style={{ fontWeight: "bold" }}>Local:</span>{" "}
                {moment.unix(props.data.localTimestamp).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            </div>
          </div>
          <div className="col-3 vertical-centered-cols-events">
            <div className="row">
              <div className="col-12">
                <span style={{ fontWeight: "bold" }}>Sensor ID:</span> {props.data.id}
              </div>
              <div className="col-12">
                <span style={{ fontWeight: "bold" }}>Sensor Type:</span>{" "}
                {props.data.fieldDataProducer}
              </div>
            </div>
          </div>
          <div className="col-6">
            <span style={{ fontWeight: "bold" }}>Gas Detection</span> &ensp;
            <div className="row">
              <div className="col-sm-6">
                <span style={{ fontWeight: "bold" }}>Gas Type: </span> &ensp;
                {props.data.gasDetection.gasType}
                <br />
                <span style={{ fontWeight: "bold" }}>Gas Concentration:</span> &ensp;
                {props.data.gasDetection.gasConcentration}
              </div>
              <div className="col-sm-6">
                <span style={{ fontWeight: "bold" }}>Gas Alarm Status: </span> &ensp;
                {props.data.gasDetection.gasAlarmStatus}
                <br />
                <span style={{ fontWeight: "bold" }}>Gas Range:</span> &ensp;
                {props.data.gasDetection.gasRange}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
