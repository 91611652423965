import React, { useMemo } from "react";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";

import { patientColumns } from "./PatientColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp, faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
// import { GlobalPatientFilter } from "./GlobalPatientFilter";
import { authInstance } from "../../../../../config";
import axios from "axios";
import "./PatientTable.css";

function PatientTableInner(props) {
  const onShowPhysioModal = async (id) => {
    props.setGraphData(null);
    props.setChartIsLoading(true);
    props.setShowPhysioModal(true);
    try {
      props.cancelTokenSource.current = axios.CancelToken.source();
      props.setCurrentCancelTokenSource(props.cancelTokenSource.current);
      const response = await authInstance.get(`triage/physio-dss/patient/${id}/`, {
        cancelToken: props.cancelTokenSource.current.token,
      });
      console.log("PHYSIO DSS", response.data);
      props.setGraphData(response.data);
      props.setChartIsLoading(false);
    } catch (error) {
      console.error(error);
      props.setGraphData(undefined);
    }
  };

  const onShowDeleteModal = (patientId) => {
    props.setDeleteModal({
      show: true,
      patientId: patientId,
    });
  };

  const columns = useMemo(() => patientColumns(onShowPhysioModal, onShowDeleteModal), []);
  const data = useMemo(() => props.patients, [props.patients]);

  console.log("DATA", data);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    prepareRow,
    state,
    // setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    // globalFilter,
    pageIndex,
    pageSize,
  } = state;

  return (
    <div>
      <div className="tableBox tableStyle mt-4">
        {/* <GlobalPatientFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
        <div className="tableContainer">
          <hr className="patientHr" />
          <table id="patientTable" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr className="tableHeadRow" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="tableH"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} size="sm" />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} size="sm" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr className="tableData" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <hr className="patientHr" />
        <div className="flexContainer">
          <div className="paginationBox  showInfo flexChild">
            <span className="patientPagination">
              Showing rows {""} {pageSize * pageIndex + 1} -{" "}
              {pageSize * pageIndex + 1 + page.length - 1} of {rows.length}
            </span>
          </div>
          <div className="paginationBox pageControl flexContainer paginationFlexContainer">
            <label>
              <span className="patientPagination">Rows per page:</span>
              <select
                className="selectNumber"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </label>
            <div className="pageControl float-right">
              <button
                className="pageButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <FontAwesomeIcon icon={faAngleLeft} size="sm" />
              </button>
              <span className="patientPagination">
                Page {""}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <button className="pageButton" onClick={() => nextPage()} disabled={!canNextPage}>
                <FontAwesomeIcon icon={faAngleRight} size="sm" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PatientTable(props) {
  return (
    <div>
      <PatientTableInner
        patients={props.patients}
        setShowPhysioModal={props.setShowPhysioModal}
        setDeleteModal={props.setDeleteModal}
        setGraphData={props.setGraphData}
        graphData={props.graphData}
        setChartIsLoading={props.setChartIsLoading}
        setCurrentCancelTokenSource={props.setCurrentCancelTokenSource}
        cancelTokenSource={props.cancelTokenSource}
      />
    </div>
  );
}

export default PatientTable;
