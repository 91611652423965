import React, { useEffect, useState } from "react";
import videos from './arrayOfVideos'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import axios from "axios";

export const VideosPage = () => {

    // const [selectedVideo, setSelectedVideo] = useState({});
    const [selectedVideo, setSelectedVideo] = useState(null);

    const [videos, setVideos] = useState();

    const getVideos = async () => {
        const response = await axios.get('http://104.248.131.178:5124/hdfs/raw-videos')
        setVideos(response.data)
    }

    useEffect(() => {
        getVideos()
    }, [])

    useEffect(() => {
        if (selectedVideo) {
            const url = `http://104.248.131.178:5124/yolo/${selectedVideo}`
            window.open(url, '_blank')
        }
    },[selectedVideo])

    // const onSelectVideo = (videoId) => {
    //     let video = videos.find(video => video.id.toString() === videoId);
    //     setSelectedVideo({title: video.title, url: video.url})
    // }

    const onSelectVideo = (videoName) => {
        setSelectedVideo(videoName)
    }

    console.log("VIDEOS", videos)
   videos && videos.map((video) => console.log('Title', video))
    return ( 
    <div className="container-fluid">
        <h1 className="dash-heading">
               <FontAwesomeIcon icon={faRobot} size="sm" className="fontawesome-sidemenu" style={{marginRight: '5px'}}/>
               Object Detection
            </h1>
        <div className="row mb-3">
            <div className="col-4">
                {/* <DropdownButton id="dropdown-basic-button" 
                title={selectedVideo.title ? selectedVideo.title : 'Select a video'}
                onSelect={onSelectVideo}>
                    {videos.map((video,index) =><Dropdown.Item key={index} eventKey={video.id.toString()}>{video.title}</Dropdown.Item>)}
                </DropdownButton> */}
                {videos && <DropdownButton id="dropdown-basic-button" title="Select a video" onSelect={onSelectVideo}>
                {videos.map((video) => <Dropdown.Item key={video} eventKey={video}>{video}</Dropdown.Item>)}
                </DropdownButton>}
            </div>
        </div>
        {/* { selectedVideo.url ? 
        ( <div className="row">
            <div className="col-12 embed-responsive embed-responsive-16by9" style={{ paddingRight: '15px', paddingLeft: '15px' }}>
                <video src={selectedVideo.url} title={selectedVideo.title} controls></video>
            </div>
        </div>) : null
        } */}
    </div>) 
} 