import React from "react";
import { Bar } from "react-chartjs-2";
import "./PatientBarChart.css";
import moment from "moment";

const PatientBarChart = (props) => {
  const time = moment(props.time).format("YYYY-MM-DD ddd [GMT] Z HH:mm").toString();
  return (
    <div id="patients-chart">
      <Bar
        data={{
          labels: props.labels,
          datasets: [
            {
              label: `Patient ${props.label} ${time}`,
              data: props.data,
              backgroundColor: "#065097",
              borderColor: "#065097",
              borderWidth: 1,
            },
          ],
        }}
        width={750}
        height={500}
        options={{
          responsive: true,
          title: {
            display: true,
            text: "Expected Time of Death (ETD)",
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  drawOnChartArea: false,
                },
                scaleLabel: {
                  display: true,
                  labelString: "Hours [h]",
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawOnChartArea: false,
                },
                ticks: {
                  beginAtZero: true,
                },
                scaleLabel: {
                  display: true,
                  labelString: "Frequencies",
                },
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default PatientBarChart;
