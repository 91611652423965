import { AnalInstance, AnalAPIKey } from "../../../config";
import {
  FETCH_ANALYTICS,
  YEAR_SELECTED,
  REGION_SELECTED,
  FETCH_REGIONS,
  FETCH_YEARS,
} from "../types";

export const fetchAnalytics = () => {
  return async (dispatch) => {
    const authentication = { headers: { "Api-Key": AnalAPIKey } };
    const response = await AnalInstance.get("", authentication);

    dispatch({ type: FETCH_ANALYTICS, payload: response.data });
  };
};

export const fetchYears = () => {
  return async (dispatch) => {
    const authentication = { headers: { "Api-Key": AnalAPIKey } };
    const response = await AnalInstance.get("", authentication);
    const years = [];
    for (let year in response.data) {
      years.push(year);
    }

    dispatch({ type: FETCH_YEARS, payload: years });
  };
};

export const fetchRegions = (year) => {
  return async (dispatch) => {
    const authentication = { headers: { "Api-Key": AnalAPIKey } };
    const response = await AnalInstance.get("", authentication);
    const regions = [];
    for (let region in response.data[year]) {
      regions.push(region);
    }

    dispatch({ type: FETCH_REGIONS, payload: regions });
  };
};

export const selectYear = (year) => {
  return {
    type: YEAR_SELECTED,
    payload: year,
  };
};

export const selectRegion = (region) => {
  return {
    type: REGION_SELECTED,
    payload: region,
  };
};
