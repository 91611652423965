import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faHome, faPen, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import DashboardDragItem from "../../../components/UI/Admin/DashboardDragItem";
import DashboardDropTarget from "../../../components/UI/Admin/DashboardDropTarget";
import ChatApp from "../../../components/Chat/ChatApp";
import { connect } from "react-redux";
import { UserInstance, CustomDashboardInstance } from "../../../config";
// import Cloud from "./Cloud";
import {
  getActorsFields,
  getService2ActorsFields,
  getService3ActorsFields,
} from "../../../Redux/actions/dss/dssActions";

const CustomDashboard = ({security, actorsFields, getActorsFields, service2ActorsFields, getService2ActorsFields, service3ActorsFields, getService3ActorsFields}) => {
  const filledButton =
    "text-white bg-concorde-light border-2 border-concorde-light rounded-2xl mb-0.5";    


    const [showChat, setShowChat] = useState(false);
    //isEditing must be set to false in the final app
    const [isEditing, setIsEditing] = useState(false);
  
    const [hasTop, setHasTop] = useState(true);
    const [hasTopLeft, setHasTopLeft] = useState(true);
    const [hasTopRight, setHasTopRight] = useState(true);
    const [hasBottomLeft, setHasBottomLeft] = useState(true);
    const [hasBottomRight, setHasBottomRight] = useState(true);
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

  //const panels = ["Organizations", "Incidents", "Users", "DSS", "Notifications", "Map"];
  const [savedPanels, setSavedPanels] = useState([ 
    {target: '1.1', name: null, id: null},
    {target: '2.1', name: null, id: null},
    {target: '2.2', name: null, id: null},
    {target: '3.1', name: null, id: null},
    {target: '3.2', name: null, id: null},
  ]);

  const [panels, setPanels] = useState([
    {name: 'Organizations', isVisible: true},
    {name: 'Incidents', isVisible: true},
    {name: 'Users', isVisible: true},
    {name: 'DSS', isVisible: true},
    {name: 'Notifications', isVisible: true},
    {name: 'Map', isVisible: true},
  ]);

  const fetchData = async () => {
    try {
      let dataFromApi = await CustomDashboardInstance.get();
      const transformedPanels = [...savedPanels]
      dataFromApi = dataFromApi.data.data;
        for (let i = 0; i < dataFromApi.length; i++) {
      
            for (let j = 0; j < panels.length; j++) {
              if(panels[j].name === dataFromApi[i].component_name.component_name){
                panels[j].isVisible = false;
              }
            }          
            for (let y = 0; y < transformedPanels.length; y++) {
              const position = dataFromApi[i].component_position.row + '.' + dataFromApi[i].component_position.column
              if(transformedPanels[y].target === position){
                transformedPanels[y].name = dataFromApi[i].component_name.component_name;
                transformedPanels[y].id = dataFromApi[i].id;                
              }               
            }              
        } 
        setSavedPanels(transformedPanels);  
        console.log('YOOOOOOOOOOOOO', transformedPanels);          
      } catch (error) {
      console.log("Error while getting alerts");
    }
  }   

  //counts the number of screens for the purpose of responsiveness
  // const handleBottomScreens = () => {
  //   const left = hasBottomLeft ? 1 : 0;
  //   const middle = hasBottomMiddle ? 1 : 0;
  //   const right = hasBottomRight ? 1 : 0;

  //   const sum = left + middle + right
  //   //console.log("SUM", sum)
  //   if (sum === 3) {
  //     return "grid-cols-3"
  //   } else if (sum === 2) {
  //     return "grid-cols-2"
  //   } else {
  //     return "grid-cols-1"
  //   }
  // }

  //used to open the edit menu
  const switchToEdit = () => {
    setIsEditing(true);
    setHasTop(true);
    setHasTopLeft(true);
    setHasTopRight(true);
    setHasBottomLeft(true);
    // setHasBottomMiddle(true);
    setHasBottomRight(true)
  }

  //gets user data
  const getUser = async () => {
    try {
      const userRes = await UserInstance.get(`user/${security.user.user_id}`)
      setUser(userRes.data.data.details.role)           
      //console.log("USER", userRes.data.data)
    } catch (error) {
      console.log("Get User API error");
    }
  };

  //onSave changes should send data to the backend and 
  const onSave = () => {
    setIsEditing(false)
    window.location.reload(false);
  }

  useEffect(() => {   
    //security && 
    getUser()
    fetchData()               
    // getActorsFields();
    // getService2ActorsFields();
    // getService3ActorsFields();    
  }, [])

  useEffect(() => {
    if (user) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }    
  }, [user])

  // useEffect(() => {
  //   handleBottomScreens()
  // }, [hasBottomLeft, hasBottomMiddle, hasBottomRight])
  
  return (
    <div className="mr-10 mt-5">
      <ChatApp show={showChat} isClosed={() => setShowChat(false)} />
      <div className={`flex justify-between ${!isEditing ? "pb-5" : ""}`}>
        <div className="flex">
          <FontAwesomeIcon icon={faHome} size="3x" className="fontawesome-dash" />
          <h1 className="text-concorde-blue text-4xl font-semibold mt-2 mr-4">Dashboard</h1>
          {!isEditing && (
            <button
              className="text-concorde-light text-lg px-4 py-0.5 shadow-5px bg-btn-fill border-concorde-light border-2 rounded-2xl"
              onClick={() => switchToEdit()}>
              <FontAwesomeIcon icon={faPen} className="mr-2" />
              Customize
            </button>
          )}
        </div>
        <button className={`${filledButton} px-4 py-1`} onClick={() => setShowChat(true)}>
          <FontAwesomeIcon icon={faComment} className="mr-2" />
          Chat
        </button>
      </div>
      {/* renders the draggable panels */}
      {isEditing && (
        <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 my-4 gap-14">
          {panels.map((panel, index) => (
            <DashboardDragItem panel={panel} key={index} />
          ))}
        </div>
      )}
      {/* renders the cloud, which is broken */}
      {/* {!isEditing && user === "EMS" && <Cloud />} */}
      {/* Dashboard screens and drop targets rendered conditionally based on number of screens and whether user is in edit mode.
       The exist prop passes info on whether the component has been filled
       && !savedPanels.every(panel => panel.name === null)
       */}
     {isLoggedIn && <div className={`grid grid-rows-1 gap-y-10`}>

        {(hasTop) ? <div className={`grid grid-cols-1 gap-x-20`}>
          <DashboardDropTarget lastDroppedItem={savedPanels} savedPanels={savedPanels[0]} panels={panels} fetchData={fetchData} setPanels={setPanels} isEditing={isEditing} size={"h-64"} user={user} incidentRole={security.user.incidentRole} incidentId={security.user.incidentId} setExist={setHasTop} actorsFields={actorsFields} service2ActorsFields={service2ActorsFields} service3ActorsFields={service3ActorsFields} />
        </div> : null}
       {(hasTopLeft || hasTopRight) ? <div className={`grid ${(hasTopLeft && hasTopRight) ? "grid-cols-2" : "grid-cols-1"} gap-x-20`}>
          <DashboardDropTarget lastDroppedItem={savedPanels} savedPanels={savedPanels[1]} panels={panels} fetchData={fetchData} setPanels={setPanels} isEditing={isEditing} size={"h-64"} user={user} incidentRole={security.user.incidentRole} incidentId={security.user.incidentId} setExist={setHasTopLeft} actorsFields={actorsFields} service2ActorsFields={service2ActorsFields} service3ActorsFields={service3ActorsFields} />
          <DashboardDropTarget lastDroppedItem={savedPanels} savedPanels={savedPanels[2]} panels={panels} fetchData={fetchData} setPanels={setPanels} isEditing={isEditing} size={"h-64"} user={user} incidentRole={security.user.incidentRole} incidentId={security.user.incidentId} setExist={setHasTopRight} actorsFields={actorsFields} service2ActorsFields={service2ActorsFields} service3ActorsFields={service3ActorsFields}/>
        </div> : null}
        {(hasBottomLeft || hasBottomRight) ? <div className={`grid mb-10 ${(hasBottomLeft && hasBottomRight) ? "grid-cols-2" : "grid-cols-1"} gap-x-20`}>
          <DashboardDropTarget lastDroppedItem={savedPanels} savedPanels={savedPanels[3]} panels={panels} fetchData={fetchData} setPanels={setPanels} isEditing={isEditing} size={"h-64"} user={user} incidentRole={security.user.incidentRole} incidentId={security.user.incidentId} setExist={setHasBottomLeft} actorsFields={actorsFields} service2ActorsFields={service2ActorsFields} service3ActorsFields={service3ActorsFields}/>
          <DashboardDropTarget lastDroppedItem={savedPanels} savedPanels={savedPanels[4]} panels={panels} fetchData={fetchData} setPanels={setPanels} isEditing={isEditing} size={"h-64"} user={user} incidentRole={security.user.incidentRole} incidentId={security.user.incidentId} setExist={setHasBottomRight} actorsFields={actorsFields} service2ActorsFields={service2ActorsFields} service3ActorsFields={service3ActorsFields}/>
        </div> : null}
      </div>}
      {/* {${!isEditing ? handleBottomScreens() : "grid-cols-2"}} */}
      {isEditing && (
        <div className="flex gap-x-8 justify-end mb-10">
          <button className="text-concorde-light" onClick={() => setIsEditing(false)}>
            <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
            Back
          </button>
          <button className={`${filledButton} px-10 py-2.5`} onClick={onSave}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

//From redux, used to call the user through the api call
const mapStateToProps = (state) => ({
  security: state.security,
  incidents: state.incidents,
  actorsFields: state.dss.actorsFields,
  service2ActorsFields: state.dss.service2ActorsFields,
  service3ActorsFields: state.dss.service3ActorsFields,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActorsFields,
      getService2ActorsFields,
      getService3ActorsFields,
    },
    dispatch
  );
};

export default connect(mapStateToProps)(CustomDashboard);
