import axios from "axios";

class VolunteerService {
  constructor() {}

  static getInstance() {
    return new VolunteerService();
  }

  authHeaders = { headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}`} }

  getAvailableVolunteer() {
    return axios.get("https://platform-back.search-and-rescue.eu/api/incidents/volunteer-available/");
  }

  getInDangerVolunteer() {
    return axios.get("https://platform-back.search-and-rescue.eu/api/incidents/volunteer-in-danger/");
  }

  getVolunteerInfo(username) {
    return axios.post("https://platform-back.search-and-rescue.eu/api/incidents/volunteer-info/", {username}, this.authHeaders)
  }

  updateVolunteerStatus(notificationId, status) {
    console.log(status)
    return axios.put(`https://platform-back.search-and-rescue.eu/api/incidents/volunteer-notification/${notificationId}/`, {status: status}, this.authHeaders)
  }

}

export default VolunteerService;