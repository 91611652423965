import React from "react";
import moment from "moment";
import "./Events.css";

export const EmgEvent = (props) => {
  return (
    <>
      {props.data ? (
        <div
          className="row row-eq-height mb-3 row-borders-events font-events"
          style={props.rowColor ? { backgroundColor: props.rowColor } : null}
        >
          <div className="col-3 vertical-centered-cols-events">
            <div className="row">
              <div className="col-12">
                <span style={{ fontWeight: "bold" }}>Local:</span>{" "}
                {moment.unix(props.data.localTimestamp).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            </div>
          </div>
          <div className="col-3 vertical-centered-cols-events">
            <div className="row">
              <div className="col-12">
                <span style={{ fontWeight: "bold" }}>Sensor ID:</span> {props.data.id}
              </div>
              <div className="col-12">
                <span style={{ fontWeight: "bold" }}>Sensor Type:</span>{" "}
                {props.data.fieldDataProducer}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <span style={{ fontWeight: "bold" }}>Values: - </span> &ensp;
                {/* {props.data.vitalParametersDetection?.otherParameters?.emgValue[0]}
                            &ensp;
                            {props.data.vitalParametersDetection?.otherParameters?.emgValue[1]}
                            &ensp;
                            {props.data.vitalParametersDetection?.otherParameters?.emgValue[2]} */}
              </div>
              <div className="col-md-6 col-sm-12">
                <span style={{ fontWeight: "bold" }}>Fatigue:</span>&ensp;{" "}
                {props.data.vitalParametersDetection?.otherParameters?.fatigue}
              </div>
              <div className="col-md-6 col-sm-12">
                <span style={{ display: "block" }}>
                  <span style={{ fontWeight: "bold" }}>mvc 1:</span>&ensp;
                  {props.data.vitalParametersDetection?.otherParameters?.mvc_1}
                </span>
                <span style={{ display: "block" }}>
                  <span style={{ fontWeight: "bold" }}>mvc 2:</span>&ensp;
                  {props.data.vitalParametersDetection?.otherParameters?.mvc_2}
                </span>
              </div>
              <div className="col-md-6 col-sm-12">
                <span style={{ display: "block" }}>
                  <span style={{ fontWeight: "bold" }}>Muscle 1:</span>&ensp;
                  {props.data.vitalParametersDetection?.otherParameters?.muscle_1}
                </span>
                <span style={{ display: "block" }}>
                  <span style={{ fontWeight: "bold" }}>Muscle 2:</span>&ensp;
                  {props.data.vitalParametersDetection?.otherParameters?.muscle_2}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
