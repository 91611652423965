import React, { Component } from "react";
import { UserInstance, InciInstance } from "../../../../../../config";
import Spinner from "../../../../../../components/UI/Spinner/Spinner";

import { MDBDataTable } from "mdbreact";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from "mdbreact";

class DispatchTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        {
          columns: [
            {
              label: "Psap",
              field: "psap",
              sort: "asc",
              width: 110,
            },

            {
              label: "Name",
              field: "name",
              sort: "asc",
              width: 110,
            },
            {
              label: "Last Name",
              field: "lastName",
              sort: "asc",
              width: 110,
            },
            {
              label: "Position",
              field: "position",
              sort: "asc",
              width: 110,
            },
            {
              label: "Telephone",
              field: "telephone",
              sort: "asc",
              width: 110,
            },
            {
              label: "Special Expertise",
              field: "specialExpertise",
              sort: "asc",
              width: 110,
            },
            {
              label: "Email",
              field: "email",
              sort: "asc",
              width: 110,
            },
          ],
          rows: [],
        },
      ],
      pushedButton: false,
      emsStaffDispatch: [],
      userIds: [],
      dispatchedIds: [],
      newIncident: null,
      newSitrep: null,
      loading: false,
      updatePsapAndEms: [],
      resMembers: [],
      value: null,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    try {
      const resMembers = await UserInstance.get("ems/not-dispatched/");
      this.setState({ resMembers: resMembers.data.data });
      this.setState((prevState) => (prevState.data[0].rows = []));
      for (let i = 0; i < resMembers.data.data.length; i++) {
        this.setState((prevState) => ({
          data: [
            {
              ...prevState.data[0],
              rows: [
                ...prevState.data[0].rows,

                {
                  psap: (
                    <React.Fragment>
                      {resMembers.data.data.map((ems, index) =>
                        ems.id === resMembers.data.data[i].id ? (
                          <select
                            onChange={(e) =>
                              this.onPsapRoleSelectChange(resMembers.data.data[i].id, e)
                            }
                            defaultValue={ems.psap}
                            key={index}
                          >
                            <option value="choose">Choose</option>
                            <option value={true}>Psap</option>
                            <option value={false}>EMS</option>
                          </select>
                        ) : null
                      )}
                    </React.Fragment>
                  ),
                  id: resMembers.data.data[i].id,
                  name: resMembers.data.data[i].firstName,
                  lastName: resMembers.data.data[i].lastName,
                  position: resMembers.data.data[i].details.position,
                  telephone: resMembers.data.data[i].phoneNumber,
                  specialExpertise: resMembers.data.data[i].details.specialExpertise,
                  email: resMembers.data.data[i].email,
                },
              ],
            },
          ],
        }));
      }
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      console.log("Get EmsMembers API error");
    }
  };
  onPsapRoleSelectChange = async (choosenId, e) => {
    await this.setState({ value: e.target.value });
    let newPsapJson = {};
    let updatePsapAndEms = [];
    let stopper = false;

    this.state.resMembers.forEach((ems) => {
      if (ems.id.includes(choosenId)) {
        if (this.state.value !== "choose") {
          newPsapJson = { id: choosenId, psap: this.state.value === "true" ? true : false };
          this.setState({ updatePsapAndEms: [...this.state.updatePsapAndEms, newPsapJson] });
        } else if (this.state.value === "choose") {
          let removeActor = this.state.updatePsapAndEms;
          removeActor.forEach((actor) => {
            if (actor.id === choosenId) {
              removeActor.splice(removeActor.indexOf(actor), 1);
            }
          });
        }
      }
    });
    updatePsapAndEms = this.state.updatePsapAndEms;
    let valueArr = updatePsapAndEms.map((item) => {
      return item.id;
    });
    let isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });
    if (isDuplicate) {
      updatePsapAndEms.forEach((item) => {
        if (item.id === updatePsapAndEms[updatePsapAndEms.length - 1].id && stopper === false) {
          updatePsapAndEms.splice(updatePsapAndEms.indexOf(item), 1);
          stopper = true;
        }
      });
    }
    this.setState({ updatePsapAndEms });
  };

  onClickDispatchSubmition = async () => {
    try {
      await InciInstance.put(`incident/${this.props.incident.id}/update-dispatched/`, {
        users: this.state.updatePsapAndEms,
      });
    } catch (error) {
      console.log("Error during Additional Dispatch");
    }
  };

  isHandled = async () => {
    let notifications = await InciInstance.get(`/notification/`);
    if (this.state.dispatchedIds !== []) {
      try {
        let notificationId = notifications.data.data.filter(
          (notification) => notification.incident === this.props.incident.id
        )[0].id;

        await InciInstance.patch(`/notification/${notificationId}/`, {
          isHandled: true,
        });
      } catch (error) {
        console.log("Error during is handled");
      }
    }
  };

  render() {
    const EmsMembersPostSubmit = (
      <MDBContainer
        style={{
          backgroundColor: "transparent",
          borderRadius: "0px",
          boxShadow: "0px 0px 0px #888888",
          padding: "2%",
        }}
      >
        <MDBModal isOpen={this.props.isOpen} toggle={this.props.toggle} centered size="lg">
          <MDBModalHeader>Dispatch First Responders</MDBModalHeader>
          <MDBModalBody>
            <MDBDataTable
              maxHeight="400px"
              scrollY
              striped
              bordered
              hover
              small
              fixed
              data={this.state.data[0]}
            />
            <MDBModalFooter>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    this.onClickDispatchSubmition();
                    this.isHandled();
                    this.props.isClosed();
                  }}
                >
                  Submit
                </button>

                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={this.props.isClosed}
                >
                  Cancel
                </button>
              </div>
            </MDBModalFooter>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );

    let ShowEmsMembers;

    if (this.state.loading === true) {
      ShowEmsMembers = <Spinner />;
    } else {
      ShowEmsMembers = EmsMembersPostSubmit;
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm text-center">{ShowEmsMembers}</div>
        </div>
      </div>
    );
  }
}

export default DispatchTable;
