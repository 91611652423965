import React from "react";
import { connect } from "react-redux";
import "./DSSPageCardActorsFieldsCis.css";

const getArrayJSX = (array) => {
  return array.map((item, index) => {
    return (
      <span key={index} style={{ display: "flex" }}>
        <span>{`Incident ${item.incident_id} `}</span>
        <p className="dss-page-card-value-text">{`${item.alloc}`}</p>
      </span>
    );
  });
};
export const DssPageCardActorFields = (props) => {
  console.log("PROPS.DATA", props.data);
  return (
    <div className={`${props.type === "cis" ? "cis-actors-fields flex-kid" : "dss-page-card"}`}>
      {props.isActorsFieldsLoading ? (
        <p className="loading">Waiting for data</p>
      ) : props.data ? (
        getArrayJSX(props.data)
      ) : (
        <p>There is no data</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isActorsFieldsLoading: state.dss.isActorsFieldsLoading,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DssPageCardActorFields);
