import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchOrgs,
  editOrgs,
  deleteOrg,
  selectOrg,
} from "../../../../../Redux/actions/Organizations/fetchOrgsAction";
import { showModal, hideModal } from "../../../../../Redux/actions/UI/toggleModalAction";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import More from "../../../../../assets/more.svg";
import Edit from "../../../../../assets/edit.svg";
import Delete from "../../../../../assets/delete.svg";
import orderBy from "lodash/orderBy";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

class OrgsTable extends Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
    };
  }

  componentDidMount() {
    console.log("EDIT", this.props);
    const { fetchOrgs } = this.props;
    fetchOrgs();
  }

  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  modalHandler() {
    if (this.props.modal) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal isOpen={this.props.modal} toggle={this.props.modal} centered size="sm">
            <MDBModalHeader>Delete</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.props.hideModal} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  if (this.selectedOrg !== null) {
                    this.props.deleteOrg(this.props.selectedOrg.id, this.props.selectedOrg.org);
                    this.props.hideModal();
                  }
                }}
                size="sm"
              >
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  actionOptions = (row) => {
    return (
      <>
        <Dropdown.Item onClick={() => this.props.editOrgs(row.id, row.type)}>
          <div className="table-edit">
            <img src={Edit} alt="" /> Edit
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.props.selectOrg(row.id, row.type);
            this.props.showModal();
          }}
        >
          <div className="table-delete">
            <img src={Delete} alt="" /> Delete
          </div>
        </Dropdown.Item>
      </>
    );
  };

  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field]) {
        return row[field].toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  renderOrgs() {
    if (this.props.orgs !== null && this.props.orgs.isLoading === false) {
      return this.props.orgs.orgs.orgs.map((org) => {
        return {
          id: org.organizationId,
          type: org.organizationType,
          name: org.name,
          address: org.location,
          telephoneNumber: org.telephoneNumber,
          edit: (
            <MDBBtn
              color="primary"
              size="sm"
              onClick={() => this.props.editOrgs(org.organizationId, org.organizationType)}
            >
              Edit
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              color="danger"
              size="sm"
              onClick={() => {
                // this.props.selectOrg(org.organizationId, org.organizationType);
                this.props.showModal();
              }}
            >
              Delete
            </MDBBtn>
          ),
        };
      });
    }
  }

  render() {
    let filteredData;
    const { filterText } = this.state;
    if (this.props.orgs !== null && this.props.orgs.isLoading === false) {
      filteredData = this.renderOrgs().filter(
        (item) =>
          item.name.toLowerCase().includes(filterText.toLowerCase()) ||
          item.type.toLowerCase().includes(filterText.toLowerCase()) ||
          item.address.toLowerCase().includes(filterText.toLowerCase()) ||
          item.telephoneNumber.includes(filterText)
      );
    }

    const columns = [
      {
        name: "Type",
        selector: "type",
        grow: 1,
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        grow: 2,
        sortable: true,
      },

      {
        name: "Address",
        selector: "address",
        grow: 2,
        sortable: true,
      },
      {
        name: "Telephone Number",
        selector: "telephoneNumber",
        grow: 2,
        sortable: true,
      },
      {
        cell: (row) => (
          <Dropdown className="">
            <Dropdown.Toggle id="dropdown-action-user-org">
              <img src={More} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="edit-table-wrapper">{this.actionOptions(row)}</Dropdown.Menu>
          </Dropdown>
        ),
        selector: "action",
        allowOverflow: true,
        button: true,
        right: true,
      },
    ];

    if (this.props.orgs !== null && this.props.orgs.isLoading === true) {
      return <Spinner />;
    } else {
      return (
        <>
          <div className="table-search-wrapper">
            <input
              className="table-search"
              placeholder="Search Organisations"
              value={this.filter}
              onChange={this.handleChange}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination={true}
            paginationPerPage={4}
            paginationRowsPerPageOptions={[4, 10, 15]}
            sortFunction={this.customSort}
          />
          <div>{this.modalHandler()}</div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    orgs: state.orgs,
    modal: state.toggleModal.show,
    selectedOrg: state.selectedOrg,
    deletedOrg: state.deleteOrg,
    editOrg: state.editOrg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchOrgs, editOrgs, selectOrg, deleteOrg, showModal, hideModal },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgsTable);
