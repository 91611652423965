import React, { Component } from "react";
import { OrgInstance, UserInstance, GoogleAPIKey } from "../../../../../../config";
import Geocode from "react-geocode";
import {
  CustomForm,
  FormRow,
  FormSubHeading,
  FormInput,
  FormButtons,
  FormDropdown,
  FormWrapper,
} from "../../../../../../components/UI/Form/Form";
import { connect } from "react-redux";

import * as validation from "../../../../../../components/UI/Form/formValidation";
import ErrorModal from "../../../../../../components/UI/Modal/ErrorModal";

class Hospital extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      prePopulate: false,
      show: false,
      error: {},
      org: {
        // Basic Registration details
        name: "",
        location: "",
        lat: "",
        lng: "",
        telephoneNumber: "",
        totalNumberOfBeds: "",

        //  Beds by specialities
        emergencyMedicineBeds: "",
        criticalCareBeds: "",
        neurosurgeryBeds: "",
        burnsOrPlasticSurgeryBeds: "",
        pediatricsOrPediatricSurgeryBeds: "",
        vascularBeds: "",
        resuscitationBeds: "",
        traumaBeds: "",
        opthalmologyBeds: "",

        //  Level of care per speciality
        emergencyMedicineCare: "",
        criticalCare: "",
        neurosurgeryCare: "",
        burnsOrPlasticSurgeryCare: "",
        vascularCare: "",
        pediatricCare: "",

        //  Trauma Operating Theatres
        generalTheaters: "",
        orthopaedicsTheaters: "",
        neurosurgeryTheaters: "",
        cardiothoracicTheaters: "",
        numberOfVentilators: "",
        numberOfCTorMRIScanners: "",
        decontaminationUnit: "",
        helicopterLandingStation: "",
      },
    };
  }

  async getLocationCoordinates(location) {
    Geocode.setApiKey(GoogleAPIKey);
    Geocode.enableDebug();
    return await Geocode.fromAddress(location).then(
      (response) => [
        response.results[0].geometry.location.lat,
        response.results[0].geometry.location.lng,
      ],
      (error) => console.error(error)
    );
  }

  onSubmit = async (formValues) => {
    try {
      [formValues.lat, formValues.lng] = await this.getLocationCoordinates(formValues.location);
    } catch (error) {
      console.log("Could not get coordinates from location.");
      this.setState({ error: error, show: true})
    }

    this.setState({ loading: true });
    try {
      if (this.props.security.user.user_roles[0] === "HOSPITAL_COMMANDER") {
        let currentUser = await UserInstance.get(`user/${this.props.security.user.user_id}`);
        let assingedHospital = await OrgInstance.get(
          `/hospital/${currentUser.data.data.details.organizationId}/`
        );
        await OrgInstance.patch(
          `hospital/${assingedHospital.data.data.organizationId}/`,
          formValues
        );
      } else if (
        this.props.security.user.user_roles[0] === "HIGH_COMMANDER" &&
        this.props.history.location.pathname === "/RegisterOrganization"
      ) {
        console.log("postHighCommander");
        await OrgInstance.post("hospital/", formValues);
      } else {
        console.log("patchHighCommander");
        await OrgInstance.patch(
          `hospital/${this.props.editOrg.org.payload.data.organizationId}/`,
          formValues
        );
      }
      this.setState({ loading: false });
      window.location.reload(true);
    } catch (error) {
      this.setState({ loading: false, error: error, show: true });
      console.log("Register Hospital Organization API error", error);
    }
  };

  componentDidMount = async () => {
    if (this.props.security.user.user_roles[0] === "HOSPITAL_COMMANDER") {
      try {
        let currentUser = await UserInstance.get(`user/${this.props.security.user.user_id}`);
        let assingedHospital = await OrgInstance.get(
          `/hospital/${currentUser.data.data.details.organizationId}`
        );

        if (assingedHospital.data.data) {
          this.setState({
            org: {
              // Basic Registration details
              name: assingedHospital.data.data.name,
              location: assingedHospital.data.data.location,
              lat: assingedHospital.data.data.lat,
              lng: assingedHospital.data.data.lng,
              telephoneNumber: assingedHospital.data.data.telephoneNumber,
              totalNumberOfBeds: assingedHospital.data.data.totalNumberOfBeds,

              //  Beds by specialities
              emergencyMedicineBeds: assingedHospital.data.data.emergencyMedicineBeds,
              criticalCareBeds: assingedHospital.data.data.criticalCareBeds,
              neurosurgeryBeds: assingedHospital.data.data.neurosurgeryBeds,
              burnsOrPlasticSurgeryBeds: assingedHospital.data.data.burnsOrPlasticSurgeryBeds,
              pediatricsOrPediatricSurgeryBeds:
                assingedHospital.data.data.pediatricsOrPediatricSurgeryBeds,
              vascularBeds: assingedHospital.data.data.vascularBeds,
              resuscitationBeds: assingedHospital.data.data.resuscitationBeds,
              traumaBeds: assingedHospital.data.data.traumaBeds,
              opthalmologyBeds: assingedHospital.data.data.opthalmologyBeds,

              //  Level of care per speciality
              emergencyMedicineCare: assingedHospital.data.data.emergencyMedicineCare,
              criticalCare: assingedHospital.data.data.criticalCare,
              neurosurgeryCare: assingedHospital.data.data.neurosurgeryCare,
              burnsOrPlasticSurgeryCare: assingedHospital.data.data.burnsOrPlasticSurgeryCare,
              vascularCare: assingedHospital.data.data.vascularCare,
              pediatricCare: assingedHospital.data.data.pediatricCare,

              //  Trauma Operating Theatres
              generalTheaters: assingedHospital.data.data.generalTheaters,
              orthopaedicsTheaters: assingedHospital.data.data.orthopaedicsTheaters,
              neurosurgeryTheaters: assingedHospital.data.data.neurosurgeryTheaters,
              cardiothoracicTheaters: assingedHospital.data.data.cardiothoracicTheaters,
              numberOfVentilators: assingedHospital.data.data.numberOfVentilators,
              numberOfCTorMRIScanners: assingedHospital.data.data.numberOfCTorMRIScanners,
              decontaminationUnit: assingedHospital.data.data.decontaminationUnit,
              helicopterLandingStation: assingedHospital.data.data.helicopterLandingStation,
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.setState({error: error, show: true})
      }
    } else if (
      this.props.security.user.user_roles[0] === "HIGH_COMMANDER" &&
      this.props.history.location.pathname === "/RegisterOrganization"
    ) {
      this.setState({
        org: {
          // Basic Registration details
          name: "",
          location: "",
          lat: "",
          lng: "",
          telephoneNumber: "",
          totalNumberOfBeds: "",

          //  Beds by specialities
          emergencyMedicineBeds: "",
          criticalCareBeds: "",
          neurosurgeryBeds: "",
          burnsOrPlasticSurgeryBeds: "",
          pediatricsOrPediatricSurgeryBeds: "",
          vascularBeds: "",
          resuscitationBeds: "",
          traumaBeds: "",
          opthalmologyBeds: "",

          //  Level of care per speciality
          emergencyMedicineCare: "",
          criticalCare: "",
          neurosurgeryCare: "",
          burnsOrPlasticSurgeryCare: "",
          vascularCare: "",
          pediatricCare: "",

          //  Trauma Operating Theatres
          generalTheaters: "",
          orthopaedicsTheaters: "",
          neurosurgeryTheaters: "",
          cardiothoracicTheaters: "",
          numberOfVentilators: "",
          numberOfCTorMRIScanners: "",
          decontaminationUnit: "",
          helicopterLandingStation: "",
        },
      });
    } else {
      this.setState({
        org: {
          // Basic Registration details
          name: this.props.editOrg.org.payload.data.name,
          location: this.props.editOrg.org.payload.data.location,
          lat: this.props.editOrg.org.payload.data.lat,
          lng: this.props.editOrg.org.payload.data.lng,
          telephoneNumber: this.props.editOrg.org.payload.data.telephoneNumber,
          totalNumberOfBeds: this.props.editOrg.org.payload.data.totalNumberOfBeds,

          //  Beds by specialities
          emergencyMedicineBeds: this.props.editOrg.org.payload.data.emergencyMedicineBeds,
          criticalCareBeds: this.props.editOrg.org.payload.data.criticalCareBeds,
          neurosurgeryBeds: this.props.editOrg.org.payload.data.neurosurgeryBeds,
          burnsOrPlasticSurgeryBeds: this.props.editOrg.org.payload.data.burnsOrPlasticSurgeryBeds,
          pediatricsOrPediatricSurgeryBeds: this.props.editOrg.org.payload.data
            .pediatricsOrPediatricSurgeryBeds,
          vascularBeds: this.props.editOrg.org.payload.data.vascularBeds,
          resuscitationBeds: this.props.editOrg.org.payload.data.resuscitationBeds,
          traumaBeds: this.props.editOrg.org.payload.data.traumaBeds,
          opthalmologyBeds: this.props.editOrg.org.payload.data.opthalmologyBeds,

          //  Level of care per speciality
          emergencyMedicineCare: this.props.editOrg.org.payload.data.emergencyMedicineCare,
          criticalCare: this.props.editOrg.org.payload.data.criticalCare,
          neurosurgeryCare: this.props.editOrg.org.payload.data.neurosurgeryCare,
          burnsOrPlasticSurgeryCare: this.props.editOrg.org.payload.data.burnsOrPlasticSurgeryCare,
          vascularCare: this.props.editOrg.org.payload.data.vascularCare,
          pediatricCare: this.props.editOrg.org.payload.data.pediatricCare,

          //  Trauma Operating Theatres
          generalTheaters: this.props.editOrg.org.payload.data.generalTheaters,
          orthopaedicsTheaters: this.props.editOrg.org.payload.data.orthopaedicsTheaters,
          neurosurgeryTheaters: this.props.editOrg.org.payload.data.neurosurgeryTheaters,
          cardiothoracicTheaters: this.props.editOrg.org.payload.data.cardiothoracicTheaters,
          numberOfVentilators: this.props.editOrg.org.payload.data.numberOfVentilators,
          numberOfCTorMRIScanners: this.props.editOrg.org.payload.data.numberOfCTorMRIScanners,
          decontaminationUnit: this.props.editOrg.org.payload.data.decontaminationUnit,
          helicopterLandingStation: this.props.editOrg.org.payload.data.helicopterLandingStation,
        },
      });
    }
  };


  handleClose = () => {
    this.setState({show: false});
  };


  render() {
    const levelOfCare = [
      { PRIMARY: "Primary" },
      { SECONDARY: "Secondary" },
      { TERTIARY: "Tertiary" },
    ];

    const oneHundredThousand = 100000;

    return (
      <FormWrapper parentClass="Hospital" componentHeading="Register Hospital">
        <CustomForm
          initialValues={{ ...this.state.org }}
          onSubmit={(formValues) => this.onSubmit(formValues)}
          loading={this.state.loading}
        >
          <FormRow>
            <FormInput
              name="name"
              size="col-md-6"
              validate={(value) => validation.inputIsRequired("Name", value)}
            />
            <FormInput
              name="telephoneNumber"
              size="col-md-6"
              validate={(value) => validation.phoneNumber(value)}
            />
          </FormRow>
          <FormRow>
            <FormInput
              name="location"
              label="Address"
              size="col-md-12"
              validate={(value) => validation.inputIsRequired("Address", value)}
            />
          </FormRow>
          <FormRow>
            <FormInput
              name="totalNumberOfBeds"
              size="col-md-6"
              type="number"
              validate={(value) => validation.maxNum("Number of beds", value, oneHundredThousand)}
            />
          </FormRow>

          <FormSubHeading>Beds By Specialties</FormSubHeading>
          <FormRow>
            <FormInput
              name="emergencyMedicineBeds"
              type="number"
              size="col-md-4"
              validate={(value) => validation.maxNum("Number of beds", value, oneHundredThousand)}
            />
            <FormInput
              name="criticalCareBeds"
              size="col-md-4"
              type="number"
              validate={(value) => validation.maxNum("Number of beds", value, oneHundredThousand)}
            />
            <FormInput
              name="neurosurgeryBeds"
              size="col-md-4"
              type="number"
              validate={(value) => validation.maxNum("Number of beds", value, oneHundredThousand)}
            />
          </FormRow>
          <FormRow>
            <FormInput
              name="burnsOrPlasticSurgeryBeds"
              size="col-md-4"
              type="number"
              validate={(value) => validation.maxNum("Number of beds", value, oneHundredThousand)}
            />
            <FormInput
              name="pediatricsOrPediatricSurgeryBeds"
              size="col-md-4"
              type="number"
              validate={(value) => validation.maxNum("Number of beds", value, oneHundredThousand)}
            />
            <FormInput
              name="vascularBeds"
              size="col-md-4"
              type="number"
              validate={(value) => validation.maxNum("Number of beds", value, oneHundredThousand)}
            />
          </FormRow>
          <FormRow>
            <FormInput
              name="resuscitationBeds"
              size="col-md-4"
              type="number"
              validate={(value) => validation.maxNum("Number of beds", value, oneHundredThousand)}
            />
            <FormInput
              name="traumaBeds"
              size="col-md-4"
              type="number"
              validate={(value) => validation.maxNum("Number of beds", value, oneHundredThousand)}
            />
            <FormInput
              name="opthalmologyBeds"
              size="col-md-4"
              type="number"
              validate={(value) => validation.maxNum("Number of beds", value, oneHundredThousand)}
            />
          </FormRow>

          <FormSubHeading>Level of Care per Speciality</FormSubHeading>
          <FormRow>
            <FormDropdown
              name="emergencyMedicineCare"
              size="col-md-4"
              options={levelOfCare}
              validate={(value) => validation.selectionIsRequired("level of care", value)}
            />
            <FormDropdown
              name="criticalCare"
              size="col-md-4"
              options={levelOfCare}
              validate={(value) => validation.selectionIsRequired("level of care", value)}
            />
            <FormDropdown
              name="neurosurgeryCare"
              size="col-md-4"
              options={levelOfCare}
              validate={(value) => validation.selectionIsRequired("level of care", value)}
            />
          </FormRow>
          <FormRow>
            <FormDropdown
              name="burnsOrPlasticSurgeryCare"
              size="col-md-4"
              options={levelOfCare}
              validate={(value) => validation.selectionIsRequired("level of care", value)}
            />
            <FormDropdown
              name="vascularCare"
              size="col-md-4"
              options={levelOfCare}
              validate={(value) => validation.selectionIsRequired("level of care", value)}
            />
            <FormDropdown
              name="pediatricCare"
              size="col-md-4"
              options={levelOfCare}
              validate={(value) => validation.selectionIsRequired("level of care", value)}
            />
          </FormRow>

          <FormSubHeading>Trauma Operating Theatres</FormSubHeading>
          <FormRow>
            <FormInput
              name="generalTheaters"
              type="number"
              size="col-md-4"
              validate={(value) =>
                validation.maxNum("Number of General Theaters", value, oneHundredThousand)
              }
            />
            <FormInput
              name="orthopaedicsTheaters"
              type="number"
              size="col-md-4"
              validate={(value) =>
                validation.maxNum("Number of Orthopaedics Theaters", value, oneHundredThousand)
              }
            />
            <FormInput
              name="neurosurgeryTheaters"
              type="number"
              size="col-md-4"
              validate={(value) =>
                validation.maxNum("Number of Neurosurgery Theaters", value, oneHundredThousand)
              }
            />
          </FormRow>
          <FormRow>
            <FormInput
              name="cardiothoracicTheaters"
              type="number"
              size="col-md-4"
              validate={(value) =>
                validation.maxNum("Number of Cardiothoracic Theaters", value, oneHundredThousand)
              }
            />
            <FormInput
              name="numberOfVentilators"
              type="number"
              size="col-md-4"
              validate={(value) =>
                validation.maxNum("Number of Ventilator Theaters", value, oneHundredThousand)
              }
            />
            <FormInput
              name="numberOfCTorMRIScanners"
              type="number"
              label="Number of CT or MRI Scanners"
              size="col-md-4"
              validate={(value) =>
                validation.maxNum("Number of CT or MRI Scanners", value, oneHundredThousand)
              }
            />
          </FormRow>
          <FormRow>
            <FormDropdown
              name="decontaminationUnit"
              size="col-md-4"
              validate={(value) =>
                validation.selectionIsRequired("Decontamination Unit availability", value)
              }
              options={[{ true: "Yes" }, { false: "No" }]}
            />
            <FormDropdown
              name="helicopterLandingStation"
              size="col-md-4"
              validate={(value) => validation.selectionIsRequired("Helipad availability", value)}
              options={[{ true: "Yes" }, { false: "No" }]}
            />
          </FormRow>
          <ErrorModal
            onHide={this.handleClose}
            handleClose={this.handleClose}
            show  ={this.state.show}
            error = {this.state.error}
        />
          <FormRow>
            <FormButtons />
          </FormRow>
        </CustomForm>
      </FormWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
  editOrg: state.editOrg,
});

export default connect(mapStateToProps, null)(Hospital);
