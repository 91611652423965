import React, { useEffect } from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBezierCurve } from "@fortawesome/free-solid-svg-icons";

import DssPageCardEMSAlloc from "../DSS/DssPageCardEMSAlloc";
import DssPageCardHospitalPat from "../DSS/DssPageCardHospitalPat";
import DssPageCardActorFields from "../DSS/DssPageCardActorsFields"

import {
  getAllocationEms,
  getActorsFields,
  getHospitalPatients,
} from "../../../Redux/actions/dss/dssActions";

export const DssForDashboard = (props) => {
  useEffect(() => {
    getAllocationEms();
    getActorsFields();
    getHospitalPatients();
  }, []);

  const { getAllocationEms, getExpectedCasualties, getHospitalPatients, getActorsFields } = props;

  return (
    <div className="fluid-container h-full overflow-scroll" id="dss-page-container">
      <div className="row">
        <div className="col">
          <FontAwesomeIcon
            icon={faBezierCurve}
            size="lg"
            className="fontawesome-dash"
            style={{ float: "left" }}
          />
          <h1 className="dash-heading" style={{ float: "left" }}>
            Decision Support System (DSS)
          </h1>
        </div>
      </div>
        <div className="w-full">
          <div className="dss-page-cards-titles">ALLOCATION OF AVAILABLE EMS UNITS TO INCIDENT</div>
          <div>
            <DssPageCardEMSAlloc data={props.allocationEMSArray} />
          </div>
        </div>
        <div className="w-full">
          <div className="dss-page-cards-titles">ALLOCATION OF PATIENTS TO HOSPITALS</div>
          <div>
            <DssPageCardHospitalPat data={props.hospitalPatients} />
          </div>
        </div>
        <div className="w-full">
          <div className="dss-page-cards-titles">
            ALLOCATION OF TASKS TO AVAILABLE ACTORS ON THE FIELD
          </div>
          <div>
            <DssPageCardActorFields data={props.actorsFields} />
          </div>
        </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allocationEMSArray: state.dss.allocationEMSArray,
  hospitalPatients: state.dss.hospitalPatients,
  actorsFields: state.dss.actorsFields,
});

export default connect(mapStateToProps, {
  getAllocationEms,
  getActorsFields,
  getHospitalPatients,
})(DssForDashboard);
