import React from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  showMembers,
  showRegister,
  showIncidents,
  closeRegisterMember,
  clearCss,
  removeClearCss,
} from "../../Redux/actions/UI/sideMenuActions";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faFileInvoice,
  faPencilAlt,
  faLevelUpAlt,
  faUserFriends,
  faChartBar,
  faExclamationTriangle,
  faUsers,
  faHospital,
  faBezierCurve,
  faFileAlt,
  faBell,
  faBed,
  faMeteor,
  faRobot,
  faUserGraduate,
  faHandHoldingHeart,
} from "@fortawesome/free-solid-svg-icons";
import { UserInstance } from "../../config";

class Sidemenu extends React.Component {
  showRegisterMembers = false;
  showMembers = false;
  showIncidents = false;

  constructor(props) {
    super();
    this.state = {
      userData: null,
    };
  }

  fetchUser = async () => {
    try {
      const userCall = await UserInstance.get(`user/${this.props.security.user.user_id}`);
      console.log("USER DATA", userCall);
      this.setState({ userData: userCall.data.data });
    } catch (error) {
      console.log("Get User API error");
    }
  };

  componentDidMount = () => {
    this.fetchUser();
  };

  registerMembers = () => {
    this.showMembers = false;
    if (this.showRegisterMembers) {
      this.showRegisterMembers = false;
    } else {
      this.showRegisterMembers = true;
    }
  };

  adminDashboardClick = () => {
    this.props.closeRegisterMember();
    this.showRegisterMembers = false;
    this.showMembers = false;
  };

  adminAnalyticsClick = () => {
    this.props.closeRegisterMember();
    this.props.clearCss();
    this.showRegisterMembers = false;
    this.showMembers = false;
  };

  // Psap sidemenu options
  incidents = () => {
    this.showIncidents = false;
    if (this.showIncidents) {
      this.showIncidents = false;
    } else {
      this.showIncidents = true;
    }
  };

  psapDashboardClick = () => {
    this.props.clearCss();
    this.props.sideMenu.showIncidents = false;
  };

  psapAnalyticsClick = () => {
    this.props.clearCss();
    this.props.sideMenu.showIncidents = false;
  };

  menuOptions = () => {
    let role;
    if (this.props.security.user.user_roles) {
      if (this.props.security.user.user_roles[0] === "EMS") {
        role = this.props.security.user.incidentRole
          ? this.props.security.user.incidentRole
          : this.props.security.user.user_roles[0];
      } else {
        role = this.props.security.user.user_roles[0];
      }
    }

    let menuLinks = null;
    switch (role) {
      case "HIGH_COMMANDER":
        menuLinks = (
          <React.Fragment>
            <ul className="sidemenu-ul">
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Agora"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-dashboard"
                  onClick={this.adminDashboardClick}
                >
                  <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                  Agora
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Dashboard"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-dashboard"
                  onClick={this.adminDashboardClick}
                >
                  <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                  Dashboard
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/CustomDashboard"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-custom-dashboard"
                  onClick={this.adminDashboardClick}
                >
                  <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                  Custom Dashboard
                </li>
              </NavLink>
              <li
                className="sidemenu-li"
                id="sidemenu-register-members"
                onClick={() => {
                  this.props.showRegister();
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} size="sm" className="fontawesome-sidemenu" />
                Organizations
              </li>
              <ul
                id="sidemenu-ul-register"
                style={{
                  height: this.props.sideMenu.showRegister === true ? "90px" : "0vh",
                }}
              >
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                  }`}
                  to="/RegisterOrganization"
                  activeClassName="activeMI"
                  exact
                >
                  <li
                    className="sidemenu-li-sub"
                    id="sidemenu-regorgclick"
                    onClick={this.regOrgClick}
                  >
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size="xs"
                      className="fontawesome-sidemenu-sub"
                    />
                    Register
                  </li>
                  <div id="borderRight"></div>
                </NavLink>
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                  }`}
                  to="/OrgMGT"
                  activeClassName="activeMI"
                  exact
                >
                  <li
                    className="sidemenu-li-sub"
                    id="sidemenu-showorgclick"
                    onClick={this.showOrgClick}
                  >
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size="xs"
                      className="fontawesome-sidemenu-sub"
                    />
                    View
                  </li>
                  <div id="borderRight"></div>
                </NavLink>
                <div id="borderRight"></div>
              </ul>
              <li
                className="sidemenu-li"
                id="sidemenu-show-members"
                onClick={this.props.showMembers}
              >
                <FontAwesomeIcon icon={faUserFriends} size="sm" className="fontawesome-sidemenu" />
                Users
              </li>
              <ul
                id="sidemenu-ul-show"
                style={{
                  height: this.props.sideMenu.showMembers === true ? "90px" : "0vh",
                }}
              >
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                  }`}
                  to="/RegisterUser"
                  activeClassName="activeMI"
                  exact
                >
                  <li
                    className="sidemenu-li-sub"
                    id="sidemenu-reguserclick"
                    onClick={this.regUserClick}
                  >
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size="xs"
                      className="fontawesome-sidemenu-sub"
                    />
                    Register
                  </li>
                </NavLink>

                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                  }`}
                  to="/UsersMGT"
                  activeClassName="activeMI"
                  exact
                >
                  <li
                    className="sidemenu-li-sub"
                    id="sidemenu-showuserclick"
                    onClick={this.showUserClick}
                  >
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size="xs"
                      className="fontawesome-sidemenu-sub"
                    />
                    View
                  </li>
                  <div id="borderRight"></div>
                </NavLink>
                {/* <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                  }`}
                  to="/VolunteerInDangerNotifications"
                  activeClassName="activeMI"
                  exact>
                  <li
                    className="sidemenu-li-sub"
                    id="sidemenu-showuserclick"
                    onClick={this.showUserClick}>
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size="xs"
                      className="fontawesome-sidemenu-sub"
                    />
                    Volunteer in Danger
                  </li>
                  <div id="borderRight"></div>
                </NavLink> */}
                {/* <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                  }`}
                  to="/VolunteerNotifications"
                  activeClassName="activeMI"
                  exact>
                  <li
                    className="sidemenu-li-sub"
                    id="sidemenu-showuserclick"
                    onClick={this.showUserClick}>
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size="xs"
                      className="fontawesome-sidemenu-sub"
                    />
                    Volunteer Availability
                  </li>
                  <div id="borderRight"></div>
                </NavLink> */}
              </ul>
              <li
                className="sidemenu-li"
                id="sidemenu-incidents"
                onClick={this.props.showIncidents}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="sm"
                  className="fontawesome-sidemenu"
                />
                Incidents
              </li>
              <ul
                id="sidemenu-ul-show"
                style={{
                  height: this.props.sideMenu.showIncidents === true ? "90px" : "0vh",
                }}
              >
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                  }`}
                  to="/RegisterIncident"
                  activeClassName="activeMI"
                  exact
                >
                  <li className="sidemenu-li-sub">
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size="xs"
                      className="fontawesome-sidemenu-sub"
                    />
                    Add New
                  </li>
                </NavLink>
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                  }`}
                  to="/IncidentMGT"
                  activeClassName="activeMI"
                  exact
                >
                  <li className="sidemenu-li-sub">
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size="xs"
                      className="fontawesome-sidemenu-sub"
                    />
                    Show Incidents
                  </li>
                </NavLink>
              </ul>

              {/* <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Analytics"
                activeClassName="activeMI"
                exact>
                <li
                  className="sidemenu-li"
                  id="sidemenu-analytics"
                  onClick={this.adminAnalyticsClick}>
                  <FontAwesomeIcon icon={faChartBar} size="sm" className="fontawesome-sidemenu" />
                  Incident Analytics
                </li>
              </NavLink> */}
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Dss"
                activeClassName="activeMI"
                exact
              >
                <li className="sidemenu-li" id="sidemenu-dss" onClick={this.adminAnalyticsClick}>
                  <FontAwesomeIcon
                    icon={faBezierCurve}
                    size="sm"
                    className="fontawesome-sidemenu"
                  />
                  DSS
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/EventLogs"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-eventlog"
                  onClick={this.adminAnalyticsClick}
                >
                  <FontAwesomeIcon icon={faFileAlt} size="sm" className="fontawesome-sidemenu" />
                  Event Log
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Notifications"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-notifications"
                  onClick={this.adminAnalyticsClick}
                >
                  <FontAwesomeIcon icon={faBell} size="sm" className="fontawesome-sidemenu" />
                  Notifications
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/HospitalCis"
                activeClassName="activeMI"
                exact
              >
                <li className="sidemenu-li-sub">
                  <FontAwesomeIcon icon={faHospital} size="sm" className="fontawesome-sidemenu" />
                  Hospitals
                </li>
              </NavLink>
              {/* <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/ObjectDetection"
                activeClassName="activeMI"
                exact>
                <li
                  className="sidemenu-li"
                  id="sidemenu-object-detection"
                  onClick={this.adminDashboardClick}>
                  <FontAwesomeIcon icon={faRobot} size="sm" className="fontawesome-sidemenu" />
                  Object Detection
                </li>
              </NavLink> */}
              {/* <Link
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to={{ pathname: "https://hit-projects.gr/VolunteerApp/" }}
                target="_blank">
                <li className="sidemenu-li-sub">
                  <FontAwesomeIcon
                    icon={faHandHoldingHeart}
                    size="sm"
                    className="fontawesome-sidemenu"
                  />
                  Volunteer App
                </li>
              </Link> */}
              {/* <Link
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to={{ pathname: "https://hit-projects.gr/SnR_elearning/" }}
                target="_blank">
                <li className="sidemenu-li-sub">
                  <FontAwesomeIcon
                    icon={faUserGraduate}
                    size="sm"
                    className="fontawesome-sidemenu"
                  />
                  E-learning Platform
                </li>
              </Link> */}
              {/* <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/PressReleaseInformation"
                activeClassName="activeMI"
                exact>
                <li
                  className="sidemenu-li"
                  id="sidemenu-dashboard"
                  onClick={this.adminDashboardClick}>
                  <FontAwesomeIcon icon={faFileAlt} size="sm" className="fontawesome-sidemenu" />
                  Press Info
                </li>
              </NavLink> */}
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Management-Strategies"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-dashboard"
                  onClick={this.adminDashboardClick}
                >
                  <FontAwesomeIcon icon={faFileAlt} size="sm" className="fontawesome-sidemenu" />
                  Management Strategies
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Docs"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-dashboard"
                  onClick={this.adminDashboardClick}
                >
                  <FontAwesomeIcon
                    icon={faFileInvoice}
                    size="sm"
                    className="fontawesome-sidemenu"
                  />
                  Documentation
                </li>
              </NavLink>
            </ul>
          </React.Fragment>
        );
        return menuLinks;
      case "EMS":
        menuLinks = (
          <ul className="sidemenu-ul">
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Agora"
              activeClassName="activeMI"
              exact
            >
              <li
                className="sidemenu-li"
                id="sidemenu-dashboard"
                onClick={this.adminDashboardClick}
              >
                <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                Agora
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-dashboard" onClick={this.psapDashboardClick}>
                <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                Dashboard
              </li>
            </NavLink>
            {this.state.userData && this.state.userData.details.EMSposition === "RUNNER" && (
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Triage"
                activeClassName="activeMI"
                exact
              >
                <li className="sidemenu-li-sub">
                  <FontAwesomeIcon icon={faBed} size="sm" className="fontawesome-sidemenu" />
                  Triage
                </li>
              </NavLink>
            )}
            {/* <Link
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to={{ pathname: "https://hit-projects.gr/VolunteerApp/" }}
              target="_blank">
              <li className="sidemenu-li-sub">
                <FontAwesomeIcon
                  icon={faHandHoldingHeart}
                  size="sm"
                  className="fontawesome-sidemenu"
                />
                Volunteer App
              </li>
            </Link> */}
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Management-Strategies"
              activeClassName="activeMI"
              exact
            >
              <li
                className="sidemenu-li"
                id="sidemenu-dashboard"
                onClick={this.adminDashboardClick}
              >
                <FontAwesomeIcon icon={faFileAlt} size="sm" className="fontawesome-sidemenu" />
                Management-Strategies
              </li>
            </NavLink>
          </ul>
        );
        return menuLinks;
      case "PSAP":
        menuLinks = (
          <ul className="sidemenu-ul">
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Agora"
              activeClassName="activeMI"
              exact
            >
              <li
                className="sidemenu-li"
                id="sidemenu-dashboard"
                onClick={this.adminDashboardClick}
              >
                <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                Agora
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-dashboard" onClick={this.psapDashboardClick}>
                <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                Dashboard
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/IncidentMGT"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li-sub">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="sm"
                  className="fontawesome-sidemenu"
                />
                Incidents
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/IncidentTeam"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li-sub">
                <FontAwesomeIcon icon={faUsers} size="sm" className="fontawesome-sidemenu" />
                Team
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/HospitalCis"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li-sub">
                <FontAwesomeIcon icon={faHospital} size="sm" className="fontawesome-sidemenu" />
                Hospitals
              </li>
            </NavLink>
            {/* <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Analytics"
              activeClassName="activeMI"
              exact>
              <li className="sidemenu-li" id="sidemenu-analytics" onClick={this.psapAnalyticsClick}>
                <FontAwesomeIcon icon={faChartBar} size="sm" className="fontawesome-sidemenu" />
                Incident Analytics
              </li>
            </NavLink> */}
          </ul>
        );
        return menuLinks;
      case "FIELD_COMMANDER":
        menuLinks = (
          <ul className="sidemenu-ul">
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Agora"
              activeClassName="activeMI"
              exact
            >
              <li
                className="sidemenu-li"
                id="sidemenu-dashboard"
                onClick={this.adminDashboardClick}
              >
                <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                Agora
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Cis"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-dashboard" onClick={this.psapDashboardClick}>
                <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                Dashboard
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/CustomDashboard"
              activeClassName="activeMI"
              exact
            >
              <li
                className="sidemenu-li"
                id="sidemenu-custom-dashboard"
                onClick={this.adminDashboardClick}
              >
                <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                Custom Dashboard
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/IncidentMGT"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li-sub">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="sm"
                  className="fontawesome-sidemenu"
                />
                Incidents
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/IncidentTeam"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li-sub">
                <FontAwesomeIcon icon={faUsers} size="sm" className="fontawesome-sidemenu" />
                Team
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Dss"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-dss" onClick={this.adminAnalyticsClick}>
                <FontAwesomeIcon icon={faBezierCurve} size="sm" className="fontawesome-sidemenu" />
                DSS
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/PatientMGT"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-patients" onClick={this.adminAnalyticsClick}>
                <FontAwesomeIcon icon={faBed} size="sm" className="fontawesome-sidemenu" />
                Patients
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/HospitalCis"
              activeClassName="activeMI"
              exact
            >
              <li
                className="sidemenu-li"
                id="sidemenu-hospitals"
                onClick={this.adminAnalyticsClick}
              >
                <FontAwesomeIcon icon={faHospital} size="sm" className="fontawesome-sidemenu" />
                Hospitals
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/EventLogs"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-eventlog" onClick={this.adminAnalyticsClick}>
                <FontAwesomeIcon icon={faFileAlt} size="sm" className="fontawesome-sidemenu" />
                Event Log
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Notifications"
              activeClassName="activeMI"
              exact
            >
              <li
                className="sidemenu-li"
                id="sidemenu-notifications"
                onClick={this.adminAnalyticsClick}
              >
                <FontAwesomeIcon icon={faBell} size="sm" className="fontawesome-sidemenu" />
                Notifications
              </li>
            </NavLink>
            {/* <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Analytics"
              activeClassName="activeMI"
              exact>
              <li className="sidemenu-li" id="sidemenu-analytics" onClick={this.psapAnalyticsClick}>
                <FontAwesomeIcon icon={faChartBar} size="sm" className="fontawesome-sidemenu" />
                Incident Analytics
              </li>
            </NavLink> */}
            {/* <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/ObjectDetection"
              activeClassName="activeMI"
              exact>
              <li
                className="sidemenu-li"
                id="sidemenu-dashboard"
                onClick={this.adminDashboardClick}>
                <FontAwesomeIcon icon={faRobot} size="sm" className="fontawesome-sidemenu" />
                Object Detection
              </li>
            </NavLink> */}
            {/* <Link
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to={{ pathname: "http://176.66.94.185:8080/" }}
              target="_blank">
              <li className="sidemenu-li-sub">
                <FontAwesomeIcon icon={faMeteor} size="sm" className="fontawesome-sidemenu" />
                Obstacle Detection
              </li>
            </Link> */}
            {/* <Link
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to={{ pathname: "https://hit-projects.gr/VolunteerApp/" }}
              target="_blank">
              <li className="sidemenu-li-sub">
                <FontAwesomeIcon
                  icon={faHandHoldingHeart}
                  size="sm"
                  className="fontawesome-sidemenu"
                />
                Volunteer App
              </li>
            </Link> */}
            {/* <Link
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to={{ pathname: "https://hit-projects.gr/SnR_elearning/" }}
              target="_blank">
              <li className="sidemenu-li-sub">
                <FontAwesomeIcon icon={faUserGraduate} size="sm" className="fontawesome-sidemenu" />
                E-learning Platform
              </li>
            </Link> */}
            {/* <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/PressReleaseInformation"
                activeClassName="activeMI"
                exact>
                <li
                  className="sidemenu-li"
                  id="sidemenu-dashboard"
                  onClick={this.adminDashboardClick}>
                  <FontAwesomeIcon icon={faFileAlt} size="sm" className="fontawesome-sidemenu" />
                  Press Info
                </li>
              </NavLink> */}
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Management-Strategies"
              activeClassName="activeMI"
              exact
            >
              <li
                className="sidemenu-li"
                id="sidemenu-dashboard"
                onClick={this.adminDashboardClick}
              >
                <FontAwesomeIcon icon={faFileAlt} size="sm" className="fontawesome-sidemenu" />
                Management-Strategies
              </li>
            </NavLink>
          </ul>
        );
        return menuLinks;
      case "HOSPITAL_COMMANDER":
        menuLinks = (
          <ul className="sidemenu-ul">
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Agora"
              activeClassName="activeMI"
              exact
            >
              <li
                className="sidemenu-li"
                id="sidemenu-dashboard"
                onClick={this.adminDashboardClick}
              >
                <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                Agora
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-dashboard" onClick={this.psapDashboardClick}>
                <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                Dashboard
              </li>
            </NavLink>

            {/* <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Analytics"
              activeClassName="activeMI"
              exact>
              <li className="sidemenu-li" id="sidemenu-analytics" onClick={this.psapAnalyticsClick}>
                <FontAwesomeIcon icon={faChartBar} size="sm" className="fontawesome-sidemenu" />
                Incident Analytics
              </li>
            </NavLink> */}
          </ul>
        );
        return menuLinks;
      default:
        menuLinks = (
          <ul className="sidemenu-ul">
            <li className="sidemenu-li" id="sidemenu-show-members" onClick={this.props.showMembers}>
              <FontAwesomeIcon icon={faUserFriends} size="sm" className="fontawesome-sidemenu" />
              Users
            </li>
            <ul
              id="sidemenu-ul-show"
              style={{
                height: this.props.sideMenu.showMembers === true ? "90px" : "0vh",
              }}
            >
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/NewUser"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li-sub"
                  id="sidemenu-reguserclick"
                  onClick={this.regUserClick}
                >
                  <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    rotation={90}
                    size="xs"
                    className="fontawesome-sidemenu-sub"
                  />
                  Register
                </li>
              </NavLink>
            </ul>
          </ul>
        );
        return menuLinks;
    }
  };

  // Add conditional jsx for cis sidemenu
  render() {
    return <div id="sidemenu">{this.menuOptions()}</div>;
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
  sideMenu: state.sideMenu,
  sideMenuClear: state.sideMenuClear,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showRegister,
      showMembers,
      showIncidents,
      closeRegisterMember,
      clearCss,
      removeClearCss,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
