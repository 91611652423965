import React, { Component } from "react";

import IncidentTable from "./IncidentTable";

class EMSIncidentMGT extends Component {
  render() {
    return (
      <div className="incidentMgtTables">
        <br />
        <div className="container">
          <div className="row">
            <div className="col-md-11 m-auto">
              <h5 className="text-left">Incidents</h5>
              <hr />
              <div>
                <IncidentTable history={this.props.history} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EMSIncidentMGT;
