import React from "react";
import Sidemenu from "../../Sidemenu";

const sideDrawer = (props) => {
  return (
    <nav className="side-drawer">
      <Sidemenu />
    </nav>
  );
};

export default sideDrawer;
