import React, { useContext, useState} from "react"
import { VolunteerInDangerContext } from "../../../context/Context"
import VolunteerService from "../../../services/VolunteerService"
import './VolunteerAvailableNotifications.css'

const VolunteerInDangerNotificationsPage = (props) => {

    const [isLoading, setIsLoading] = useState({})
    const volunteerInDangerContext = useContext(VolunteerInDangerContext)
    volunteerInDangerContext.resetUnreadCounter(0)

    const tableHeaders = [
        'Username',
        'Organization',
        'Role',
        'Date',
        'Status',
        'Position'
    ]

    const rowStyle = (index) => {
        const rowColor= index % 2 === 0 ? '#fff' : '#eff6ff'
        return {
                displayed: {
                    cursor: 'pointer',
                    backgroundColor: rowColor,
                    textAlign: 'center'
                }
              }
    }

    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    {tableHeaders.map(tableHeader => <th className="text-center"><b>{tableHeader}</b></th>)}
                </thead>
                <tbody>
                    {volunteerInDangerContext.notifications.map((notification,index) => {
                        return (<>
                                    <tr style={rowStyle(index).displayed}
                                        id={notification.id}
                                        key={notification.id}>
                                        <td className="border-0 align-middle">{notification.username}</td>
                                        <td className="border-0 align-middle">{notification.organization}</td>
                                        <td className="border-0 align-middle">{notification.role}</td>
                                        <td className="border-0 align-middle">{notification.timestamp}</td>
                                        <td className="border-0 align-middle">{notification.notif_status}</td>
                                        <td className="border-0 align-middle">
                                            <div style={{display:'grid', gridTemplateColumns:'1fr 1fr'}}>
                                                <span> <b>lat</b>: {notification.lat}</span> 
                                                <span><b>lng</b>: {notification.lon}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                                )
                    })}
                </tbody>
            </table>
        </div>
            )
}

export default VolunteerInDangerNotificationsPage