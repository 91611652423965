import React, { Component } from "react";
import { fetchOrganizations } from "../../../Redux/actions/Organizations/OrganizationActions";
// import TopLeftChart from "../Analytics/TopLeftChart";
import IncidentsMap from "../Admin/ResourceMGT/Incidents/IncidentsMap";
import Notifications from "../../Screens/Admin/ResourceMGT/Notifications/Notifications";
import {
  fetchAnalytics,
  fetchYears,
  fetchRegions,
  selectYear,
  selectRegion,
} from "../../../Redux/actions/Analytics/analyticsAction";
import {
  showMembers,
  showRegister,
  closeRegisterMember,
  showIncidents,
} from "../../../Redux/actions/UI/sideMenuActions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import ChatApp from "../../../components/Chat/ChatApp";
// import CisMapOfficial from "../IncidentModule/CisComponents/Map/CisMapOfficial";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      showChatModal: false,
    };
  }
  componentDidMount = async () => {
    this.props.fetchOrganizations();
    // this.props.fetchAnalytics();
    // this.props.fetchYears();
    // this.props.fetchRegions(this.props.year);
  };

  componentDidUpdate(prevProps) {
    if (this.props.year !== prevProps.year) {
      this.props.fetchRegions(this.props.year);
    }
  }

  countOrg(org) {
    if (this.props.organizations === undefined) {
      return null;
    } else if (this.props.organizations) {
      for (let i = 0; i < this.props.organizations.length; i++) {
        var orgCount = this.props.organizations.reduce(function (n, count) {
          return n + (count.organizationType === org);
        }, 0);
      }
      return orgCount;
    }
  }

  yearDropdownOptions = () => {
    if (this.props.years === undefined) {
      return null;
    } else if (this.props.years) {
      return this.props.years.map((year) => {
        return (
          <Dropdown.Item key={year} id={year} onClick={() => this.props.selectYear(year)}>
            {year}
          </Dropdown.Item>
        );
      });
    }
  };

  regionDropdownOptions = () => {
    if (this.props.regions === undefined) {
      return null;
    } else if (this.props.regions) {
      return this.props.regions.map((region) => {
        return (
          <Dropdown.Item
            key={Math.random()}
            id={region}
            onClick={() => this.props.selectRegion(region)}>
            {region}
          </Dropdown.Item>
        );
      });
    }
  };
  handleChatModal = (e) => {
    this.setState({ showChatModal: true });
  };
  render() {
    return (
      <div>
        <ChatApp
          show={this.state.showChatModal}
          isClosed={() => this.setState({ showChatModal: false })}
        />
        <div className="row">
          <div className="col-md-auto">
            <FontAwesomeIcon
              icon={faHome}
              size="3x"
              className="fontawesome-dash"
              style={{ float: "left" }}
            />
            <h1 className="dash-heading-refactor" style={{ float: "left" }}>
              Dashboard
            </h1>
          </div>
          <div className="col-md-auto">
            <button
              className="btn  btn-sm"
              id="request-troops-button"
              onClick={() => {
                this.handleChatModal();
              }}>
              Chat
            </button>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-5 dashboard-background-container">
            <Notifications />
          </div> */}
          <div className="col-md-12">
            <IncidentsMap />
            {/* <CisMapOfficial type="highCommander" /> */}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-4 d-flex align-items-stretch">
            <div className="row dashboard-background-container" style={{ marginRight: "0px" }}>
              <div className="col-md-12">
                <h2 className="dash-secondary-heading-refactor">INCIDENT MANAGEMENT</h2>
              </div>
              <div className="col-md-12">
                <p className="text-dashboard-refactor">
                  Register a new incident or view the list of the current running incidents around
                  the country.
                </p>
              </div>
              <div className="col-md-auto">
                <Link
                  to="/RegisterIncident"
                  onClick={() => {
                    this.props.showRegister();
                  }}>
                  <button
                    type="button"
                    data-testid="org-button"
                    className="btn dashboard-register-button-refactor">
                    New Incident
                  </button>
                </Link>
              </div>
              <div className="col-md-auto">
                <Link
                  to="/IncidentMGT"
                  onClick={() => {
                    this.props.showMembers();
                  }}>
                  <button
                    type="button"
                    data-testid="org-button"
                    className="btn dash-view-button-refactor">
                    View List
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-stretch dashboard-background-container">
            <div className="row dashboard-background-container" style={{ marginRight: "0px" }}>
              <div className="col-md-12">
                <h2 className="dash-secondary-heading-refactor">ORGANISATIONS</h2>
              </div>
              <div className="col-md-12">
                <p className="text-dashboard-refactor">
                  Psap Centers, Hospitals, EMS, Higher Command or By-standers.
                </p>
              </div>
              <div className="col-md-auto">
                <Link
                  to="/RegisterOrganization"
                  onClick={() => {
                    this.props.showRegister();
                  }}>
                  <button
                    type="button"
                    data-testid="org-button"
                    className="btn dashboard-register-button-refactor">
                    New Organisation
                  </button>
                </Link>
              </div>
              <div className="col-md-auto">
                <Link
                  to="/OrgMGT"
                  onClick={() => {
                    this.props.showMembers();
                  }}>
                  <button
                    type="button"
                    data-testid="org-button"
                    className="btn dash-view-button-refactor">
                    View List
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-stretch">
            <div className="row dashboard-background-container" style={{ marginLeft: "0px" }}>
              <div className="col-md-12">
                <h2 className="dash-secondary-heading-refactor">USERS</h2>
              </div>
              <div className="col-md-12">
                <p className="text-dashboard-refactor">
                  First responders, doctors, specialists and other individual members of
                  Organisations.
                </p>
              </div>
              <div className="col-md-auto">
                <Link
                  to="/RegisterUser"
                  onClick={() => {
                    this.props.showRegister();
                  }}>
                  <button
                    type="button"
                    data-testid="user-button"
                    className="btn dashboard-register-button-refactor">
                    New User
                  </button>
                </Link>
              </div>
              <div className="col-md-auto">
                <Link
                  to={{ pathname: "/UsersMGT", populate: false }}
                  onClick={() => {
                    this.props.showMembers();
                  }}>
                  <button
                    type="button"
                    data-testid="user-button"
                    className="btn dash-view-button-refactor">
                    View List
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organization.org,
    anal: state.analytics,
    year: state.dash.year,
    region: state.dash.region,
    years: state.years.availableYears,
    regions: state.regions.availableRegions,
  };
};

export default connect(mapStateToProps, {
  fetchOrganizations,
  fetchAnalytics,
  selectRegion,
  selectYear,
  fetchYears,
  fetchRegions,
  showMembers,
  showRegister,
  showIncidents,
  closeRegisterMember,
})(Dashboard);
