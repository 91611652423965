import React, { Component } from "react";
import { UserInstance2 } from "../../../../../config";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import {
  FormRow,
  FormInput,
  FormButtons,
  FormDropdown,
  FormWrapper,
} from "../../../../../components/UI/Form/Form";
import * as validation from "../../../../../components/UI/Form/formValidation";

import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      phoneNumber: "",
      email: "",
      position: "HIGH",
      role: "HIGH_COMMANDER",
      organizationType: "EMS_Station",
      organizationId: "9e374a7c-9a1c-43b6-9ccd-c942ce03db96",
      EMSposition: "RESCUER",
      specialExpertise: "",
      incident_details: "",
      enabled: true,
      allOrgs: {
        HOSPITAL: [],
        EMS: [{ "9e374a7c-9a1c-43b6-9ccd-c942ce03db96": "Civil Protection_Test" }],
        PSAP_CENTER: [],
        HIGHER_COMMAND: [],
        OTHER: [],
      },
      // prePopulate: false,
      loading: false,
      show: false,
      error: {},
    };
  }

  // close error modal
  handleClose = () => {
    this.setState({ show: false });
  };

  handleChangeOrgType = (OrgType) => {
    this.setState({ organizationType: OrgType });
  };

  onSubmit = async (formValues) => {
    this.state = formValues;

    const user = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      username: this.state.username,
      password: this.state.password,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      details: {
        role: this.state.role,
        organizationType: this.state.organizationType,
        organizationId: this.state.organizationId,
        specialExpertise: this.state.specialExpertise,
        position: this.state.position,
        EMSposition: this.state.EMSposition,
        incident_details: "",
        enabled: true,
      },
    };
    try {
      await UserInstance2.post("user/", user);
      console.log("here", this.state);
      this.setState({ show: true });
      this.setState({ loading: false });
      await this.props.history.push("/Login");
    } catch (error) {
      console.log("Register User API error");
      this.setState({ loading: false, error: error, show: true });
      console.log("user register", this.state);
    }
  };

  render() {
    let form = (
      <Formik
        enableReinitialize={true} // This prop is needed for populateFieldsIfEditingUser method.
        initialValues={{ ...this.state }}
        onSubmit={(formValues) => this.onSubmit(formValues)}
      >
        {({ values }) => (
          <Form className="custom-form">
            <FormRow>
              <FormInput
                name="firstName"
                validate={(value) => validation.inputIsRequired("Name", value)}
              />
              <FormInput
                name="lastName"
                validate={(value) => validation.inputIsRequired("Last name", value)}
              />
              <FormInput name="email" validate={(value) => validation.email(value, null)} />
              <FormInput name="phoneNumber" validate={(value) => validation.phoneNumber(value)} />
            </FormRow>
            <FormRow>
              <FormDropdown
                name="specialExpertise"
                size="col-md-6"
                validate={(value) => validation.selectionIsRequired("Special Expertise", value)}
                options={[
                  { NO_MEDICAL_SKILLS: "No Medical Skills" },
                  { BASIC_LIFE_SUPPORT: "Basic Life Support" },
                  { ADVANCED_LIFE_SUPPORT: "Advance Life Support" },
                  { SPECIALIST_MEDICAL: "Medical Specialist" },
                ]}
              />
            </FormRow>
            <FormRow>
              <FormDropdown
                name="organizationType"
                validate={(value) => {
                  validation.selectionIsRequired("Organization Type", value);
                }}
                options={[{ EMS_STATION: "EMS Station" }]}
              />
              <FormDropdown
                name="organizationId"
                label="Organization"
                disable={!values.organizationType}
                validate={(value) =>
                  validation.organizationIdField(value, this.state.allOrgs[values.organizationType])
                }
                options={[{ "9e374a7c-9a1c-43b6-9ccd-c942ce03db96": "Civil Protection_Test" }]}
              />
              <FormDropdown
                name="role"
                label="User Role"
                validate={(value) => validation.selectionIsRequired("User Role", value)}
                options={[{ HIGH_COMMANDER: "Admin" }]}
              />
              <FormDropdown
                name="position"
                validate={(value) => validation.selectionIsRequired("Position", value)}
                options={[{ HIGH: "High Ranking Officer" }]}
              />
            </FormRow>
            <FormRow>
              <FormInput
                name="username"
                validate={(value) => validation.username(value, 6, 100, null)}
              />
              <FormInput
                name="password"
                validate={(value) =>
                  // this.state.prePopulate ? null :
                  validation.minMax("Password", value, 6, 100)
                }
              />
              {values.organizationType === "EMS_STATION" && (
                <FormDropdown
                  name="EMSposition"
                  label="EMS Position"
                  validate={(value) => validation.selectionIsRequired("EMS Position", value)}
                  options={[{ RESCUER: "RESCUER" }]}
                />
              )}
            </FormRow>
            <FormRow>
              <div className="text-right form-group col-md-12 form-button-wrapper">
                <button type="submit" className="btn solid-button">
                  Submit
                </button>
                <Link to={{ pathname: "/Login" }}>
                  <button type="button" className="btn outline-button">
                    Go back
                  </button>
                </Link>
                <div className="required-text mb-4">* All fields are required.</div>
              </div>
            </FormRow>
            <ErrorModal
              onHide={this.handleClose}
              handleClose={this.handleClose}
              show={this.state.show}
              error={this.state.error}
            />
          </Form>
        )}
      </Formik>
    );

    if (this.state.loading === true) {
      form = <Spinner />;
    }

    return (
      <FormWrapper
        parentClass="registerUser"
        icon={faPencilAlt}
        mainHeading="Register New User"
        componentHeading="New User Form"
      >
        {form}
      </FormWrapper>
    );
  }
}

export default NewUser;
