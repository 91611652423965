import { DELETE_USER } from "../../actions/types";

export default (state = null, action) => {
  switch (action.type) {
    case DELETE_USER:
      return {
        ...state,
        deleteUser: action,
      };
    default:
      return state;
  }
};
