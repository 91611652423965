import React, { Component } from "react";
import OrgsTable from "./OrgsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";

class OrgMGT extends Component {
  render() {
    return (
      <div className="resourceMGTTables h-full bg-white rounded-2xl px-4 py-4 overflow-x-auto">
        <div className="container">
          <div className="row ">
            <FontAwesomeIcon
              icon={faUserFriends}
              size="lg"
              className="fontawesome-dash title-svg"
            />
            <h1 className="main-heading">Show Organization List</h1>
          </div>
          <div className="row mt-4">
            <div className="table-wrapper">
              <h5 className="text-left table-number mb-3">Organizations</h5>
              <OrgsTable />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrgMGT;
