import React, { useEffect, useState } from "react";
import moment from "moment";
import "./Notifications.css";
export const Notification = (props) => {
  const isAlert = () => {
    return props.notification.alerts.length !== 0 ? true : false;
  };

  return (
    <div
      className="row row-eq-height text-center mb-3 row-borders"
      style={props.rowColor ? { backgroundColor: props.rowColor } : null}
    >
      <div
        className="col-3 p-3 vertical-centered-cols"
        style={isAlert() ? { color: "#ff6060" } : {}}
      >
        {moment.unix(parseInt(props.notification.timestamp)).format("dddd MMMM Do YYYY, h:mm:ss A")}
      </div>
      <div
        className="col-3 p-3 vertical-centered-cols"
        style={isAlert() ? { color: "#ff6060" } : {}}
      >
        {props.notification.deviceId}
      </div>
      <div
        className="col-3 p-3 vertical-centered-cols"
        style={isAlert() ? { color: "#ff6060" } : {}}
      >
        {props.notification.deviceType}
      </div>
      <div
        className="col-3 p-3 vertical-centered-cols"
        style={isAlert() ? { color: "#ff6060" } : {}}
      >
        {isAlert() ? (
            props.notification.alerts.map(alert => <>
            <p style={{ color: "#ff6060" }}>{alert?.message}</p>
            <p style={{ color: "#ff6060" }}>{alert?.symptoms}</p>
            </>)
        ) : (
          props.notification.notificationMessage
        )}
      </div>
    </div>
  );
};
