import React, { useCallback, useEffect, useState, useRef } from "react";
import { authInstance } from "../../../../../config";
import PatientTable from "./PatientTable";
// import PatientBarChart from "./PatientBarChart";
// import { PatientDropdown } from "./PatientDropdown";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import PhysioModal from "./PhysioModal";
import DeleteModal from "./DeleteModal";

const PatientMGT = () => {
  const [patients, setPatients] = useState([]);
  // const [patientData, setPatientData] = useState([]);
  // const [isLoading, setIsLoading] = useState({ state: true, type: "table" });
  const [isLoading, setIsLoading] = useState(true);
  const [chartIsLoading, setChartIsLoading] = useState(false);
  const [showPhysioModal, setShowPhysioModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    patientId: null,
  });
  const [graphData, setGraphData] = useState(null);
  //The following state is needed for cancelling the API call
  const [currentCancelTokenSource, setCurrentCancelTokenSource] = useState();
  const cancelTokenSource = useRef();

  const fetchInitialData = useCallback(async () => {
    const patientsResponse = await authInstance
      .get("triage/patient/")
      .catch((error) => console.log(error));
    setPatients(patientsResponse.data.data);
    // setIsLoading({ state: false, type: null });
    setIsLoading(false);
  }, []);

  const closePhysioModal = () => {
    currentCancelTokenSource.cancel();
    setGraphData(null);
    setShowPhysioModal(false);
  };

  // const filterPatients = async (patientIds) => {
  //   const patientsResponse = await authInstance.get("triage/patient/");
  //   const newPatients = [];
  //   patientIds.forEach((patientId) => {
  //     const foundPatient = patientsResponse.data.data.find((patient) => {
  //       return patientId === patient.id;
  //     });
  //     newPatients.push(foundPatient);
  //   });
  //   setPatients(newPatients);
  // };

  // const fetchChartData = async (chartSet) => {
  //   let newChartData = [];
  //   console.log("charSet", chartSet);
  // let res1;
  // let res2;
  // let res3;
  // let res4;
  // switch (chartSet) {
  //   case "site 1":
  //     res1 = await authInstance.get("triage/physio-dss/patient/1");
  //     res2 = await authInstance.get("triage/physio-dss/patient/2");
  //     res3 = await authInstance.get("triage/physio-dss/patient/3");
  //     res4 = await authInstance.get("triage/physio-dss/patient/4");
  //     newChartData = [res1.data, res2.data, res3.data, res4.data];
  //     console.log("RES 1", res1);
  //     console.log("RES 2", res2);
  //     console.log("RES 3", res3);
  //     console.log("RES 4", res4);
  //     break;
  //   case "site 2":
  //     res1 = await authInstance.get("triage/physio-dss/patient/5");
  //     res2 = await authInstance.get("triage/physio-dss/patient/6");
  //     res3 = await authInstance.get("triage/physio-dss/patient/7");
  //     res4 = await authInstance.get("triage/physio-dss/patient/8");
  //     newChartData = [res1.data, res2.data, res3.data, res4.data];
  //     break;
  //   case "site 3":
  //     res1 = await authInstance.get("triage/physio-dss/patient/9");
  //     res2 = await authInstance.get("triage/physio-dss/patient/10");
  //     res3 = await authInstance.get("triage/physio-dss/patient/11");
  //     res4 = await authInstance.get("triage/physio-dss/patient/12");
  //     newChartData = [res1.data, res2.data, res3.data, res4.data];
  //     break;
  //   default:
  // }

  //   setPatientData(newChartData);
  //   console.log("NEW CHART DATA", newChartData);
  //   return newChartData.map((patientObject) => {
  //     return patientObject.patient_id;
  //   });
  // };

  // const onChangeFilter = async (eventType) => {
  //   switch (eventType) {
  //     case "all patients":
  //       setPatientData([]);
  //       setIsLoading({ state: true, type: "table" });
  //       await fetchInitialData().catch((error) => {
  //         console.log(error);
  //         setIsLoading({ state: false, type: null });
  //       });
  //       break;
  //     // cases site 1, site 2, site 3
  //     default:
  //       setIsLoading({ state: true, type: "both" });
  //       let patientIds = await fetchChartData(eventType).catch((error) => {
  //         console.log(error);
  //       });
  //       await filterPatients(patientIds).catch((error) => {
  //         console.log(error);
  //         setIsLoading({ state: false, type: null });
  //       });
  //   }
  //   setIsLoading({ state: false, type: null });
  // };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <>
      {showPhysioModal && (
        <PhysioModal
          closePhysioModal={closePhysioModal}
          graphData={graphData}
          chartIsLoading={chartIsLoading}
        />
      )}
      {deleteModal.show && <DeleteModal setShowModal={setDeleteModal} deleteModal={deleteModal} />}
      <div className="container">
        <div className="row">
          <div className="col-md-11 m-auto">
            <h2 className="text-left dash-heading">
              <FontAwesomeIcon icon={faBed} size="sm" style={{ marginRight: "5px" }} />
              Patients
            </h2>
            <hr />
            {/* <PatientDropdown onSelectFilter={onChangeFilter} /> */}
            {isLoading ? (
              <Spinner />
            ) : (
              <PatientTable
                patients={patients}
                setShowPhysioModal={setShowPhysioModal}
                setDeleteModal={setDeleteModal}
                setGraphData={setGraphData}
                graphData={graphData}
                setChartIsLoading={setChartIsLoading}
                setCurrentCancelTokenSource={setCurrentCancelTokenSource}
                cancelTokenSource={cancelTokenSource}
              />
            )}
            {/* {isLoading.state && isLoading.type === "both" ? (
              <Spinner />
            ) : (
              <>
                {isLoading.state && isLoading.type === "table" ? (
                  <Spinner />
                ) : (
                  <PatientTable
                    patients={patients}
                  />
                )} */}
            {/* <div className="row">
                  {patientData.length !== 0 ? (
                    <div className="d-block col-md-12 m-auto mb-2">
                      <h2 className="text-left dash-heading">Physio DSS</h2>
                      <hr />
                    </div>
                  ) : null}
                  {patientData.length !== 0
                    ? patientData.map((object) => {
                        return (
                          <div className="col-lg-6">
                            <PatientBarChart
                              labels={object.physio.midpointsETD.map((point) => {
                                return Math.round((point + Number.EPSILON) * 100) / 100;
                              })}
                              label={object.patient}
                              patientData={patientData}
                              data={object.physio["frequenciesETD "]}
                            />
                          </div>
                        );
                      })
                    : null}
                </div> */}
            {/* </>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientMGT;
