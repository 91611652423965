import React from "react";
export const Forum = (props) => { 
    return (
        <div className="container-fluid">
            <iframe src="https://forum-smf.kt-server.com/" 
                    allowfullscreen="" 
                    width="100%" 
                    height="800px">
            </iframe>
        </div>
    )
}