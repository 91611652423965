import React, { Component } from "react";
import { fetchOrganizations } from "../../../Redux/actions/Organizations/OrganizationActions";
// import TopLeftChart from "../Analytics/TopLeftChart";
import IncidentsMap from "../Admin/ResourceMGT/Incidents/IncidentsMap";
import Notifications from "../../Screens/Admin/ResourceMGT/Notifications/Notifications";
import {
  fetchAnalytics,
  fetchYears,
  fetchRegions,
  selectYear,
  selectRegion,
} from "../../../Redux/actions/Analytics/analyticsAction";
import {
  showMembers,
  showRegister,
  closeRegisterMember,
  showIncidents,
} from "../../../Redux/actions/UI/sideMenuActions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import ChatApp from "../../../components/Chat/ChatApp";

class Dos extends Component {
  constructor() {
    super();
    this.state = {
      regionChosen: 'Media Management Checklist',
    };
  }
  componentDidMount = async () => {
    //this.props.fetchOrganizations();
    // this.props.fetchAnalytics();
    // this.props.fetchYears();
    // this.props.fetchRegions(this.props.year);
  };

  componentDidUpdate(prevProps) {

  }

  handleRegion = (event) => {
    if(event.target.id === "1" ){
      this.setState({ regionChosen: "Media Management Checklist" });
    } else {
      this.setState({ regionChosen: "Families & Relatives Management Manual" });
    }

  }


  render() {
    return (
      <div className="mr-4 ml-8">
        <div className="flex flex-col sm:flex-row">
          <div className="w-full">
            <h1 className="dash-heading-refactor">
              Management Strategies
            </h1>
          </div>

          <div className='w-full text-left py-2'>
              <Dropdown>
                <Dropdown.Toggle size='md' variant='primary' id='dropdown-basic-region'>
                  {this.state.regionChosen}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item id="1" onClick={this.handleRegion}>Media Management Checklist</Dropdown.Item>
                  <Dropdown.Item id="2" onClick={this.handleRegion}>Families & Relatives Management Manual</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
          </div>

        </div>
        <br />
        {this.state.regionChosen === "Media Management Checklist" &&
        <div className="row mb-8">
        <div className="col-md-12 align-items-stretch">
          <div className="row dashboard-background-container" style={{ marginRight: "0px" }}>
            <div className="col-md-12">
              <h2 className="dash-secondary-heading-refactor">Interviewing “Dos”</h2>
            </div>
            <div className="col-md-12">
              <p className="text-dashboard-refactor mt-2">
              <ul>
                <li><b>1.</b> The behavior of the deployed SAR Team members is of vital importance to everyone, including local government, rescue organizations, and fellow first responders.</li>
                <li><b>2.</b> Ask the reporter’s name. Then use it in your response.</li>
                <li><b>3.</b> Use your full name. Nicknames are not appropriate.</li>
                <li><b>4.</b> Choose the site (if possible). Make sure you are comfortable with the location of the interview. Consider what is in the background.</li>
                <li><b>5.</b> Choose the time (if possible). If you are comfortable waiting another five minutes, ask the reporter if it is OK. However, you should bear in mind that the reporter has a deadline.</li>
                <li><b>6.</b> Be calm. Your demeanor and apparent control of the situation are very important in establishing the tempo of evolving events.</li>
                <li><b>7.</b> Tell the truth.</li>
                <li><b>8.</b> Be cooperative. You have accountability to explain to the public. There is an answer to most questions, and if you don’t know it now, let them know you will work diligently to determine the facts needed.</li>
                <li><b>9.</b> Be professional. Don’t let your personal feelings about the media, or this reporter in general, affect your response.</li>
                <li><b>10.</b> Be patient. Expect dumb questions. Do not get angry at those ill-natured or ill-tempered questions. If the same question is asked again, repeat your answer without irritation.</li>
                <li><b>11.</b> Take your time. If you make a mistake during a taped or non-broadcast interview, indicate that you would like to start over with your response. If appearing live, just start over again.</li>
                <li><b>12.</b> Use wrap-around sentences. This means repeating the question with your answer for a complete “sound bite.”</li>
              </ul>
              </p>
            </div>

          </div>
        </div>
        <div className="col-md-12 align-items-stretch mt-3 mb-3">
          <div className="row dashboard-background-container" style={{ marginRight: "0px" }}>
            <div className="col-md-12">
              <h2 className="dash-secondary-heading-refactor">Interviewing “Don’ts”</h2>
            </div>
            <div className="col-md-12">
              <p className="text-dashboard-refactor mt-2">
              <ul>
                <li><b>1.</b> Do not discriminate against any type of press or any specific press agency. You should be open to all media such as TV or radio, nationwide or local paper and foreign or national press.</li>
                <li><b>2.</b> Do not reply with “no comment.”</li>
                <li><b>3.</b> Do not give your personal opinion. Stick to the facts.</li>
                <li><b>4.</b> Do not go off the record. Anything you say can and will be used against you.</li>
                <li><b>5.</b> Do not lie. To tell a lie unintentionally is a mistake. To intentionally tell a lie is stupid.</li>
                <li><b>6.</b> Do not bluff. The truth will come out.</li>
                <li><b>7.</b> Do not be defensive. The media and their audience recognise a defensive attitude and tend to believe you are hiding something.</li>
                <li><b>8.</b> Do not be afraid. Fear is debilitating and is not a characteristic you want to portray.</li>
                <li><b>9.</b> Do not be evasive. Be upfront on what you know about the situation and what you plan to do to mitigate the disaster.</li>
                <li><b>10.</b> Do not use jargon. The public is not familiar with much of the language used in this field.</li>
                <li><b>11.</b> Do not confront. This is not the time to tell a reporter how much you dislike the media.</li>
                <li><b>12.</b> Do not try to talk and command a disaster at the same time. You will not do either well.</li>
                <li><b>13.</b> Do not wear sunglasses and do not smoke.</li>
                <li><b>14.</b> Do not promise results or speculate.</li>
                <li><b>15.</b> Do not respond to rumours.</li>
                <li><b>16.</b> Do not repeat leading questions.</li>
                <li><b>17.</b> Do not run down the efforts of the affected country or any other organisation.</li>
              </ul>
              </p>
            </div>

          </div>
        </div>
        </div>
      }

      {this.state.regionChosen === "Families & Relatives Management Manual" &&
        <div className="row mb-8">
        <div className="col-md-12 align-items-stretch">
          <div className="row dashboard-background-container" style={{ marginRight: "0px" }}>

            <div className="col-md-12">
              <p className="text-dashboard-refactor mt-2">
              During a SAR operation with multiple victims, there is an overwhelming desire of relatives to identify their loved ones. The priority for affected families is to learn what happened to their lost loved ones.
              In the immediate aftermath of a mass casualty incident, survivors and their loved ones will have four fundamental concerns or needs:
              <br/>
              • Determining whether their loved one was involved in the incident;<br/>
              • Determining their loved one's whereabouts and well-being (i.e., injury status);<br/>
              • Obtaining information about the incident and getting accessible services (e.g., fulfilling immediate needs like as food, shelter, clothing, and aid); and<br/>
              • Receiving personal possessions of their loved one.<br/><br/>
              Recent incidents have demonstrated that people will travel quickly to: a location where they believe they can find their loved one (e.g., hospitals, known reunification centers, incident sites); a location where someone can provide information about their loved one; or—if they were personally affected by the incident—a location where they can obtain recovery services.
              All SAR Team member should always remember that:<br/>
              • At all times, the deceased and the bereaved should be respected.<br/>
              • At all times and at all stages of the recovery and identification procedure, honest and accurate information should be provided.<br/>
              • Throughout the process, the families ought to be treated with empathy and compassion.<br/>
              • Identification mistakes should be avoided.<br/>
              • Families and relatives should be provided with psychosocial support.<br/>
              • Religious and cultural sensitivities should be acknowledged.<br/><br/>
              PROVIDING SUPPORT<br/>
              To assist relatives, a family liaison focal point should be developed.
              Families should be notified first before anyone else about the results and identification of their loved ones. No SAR Team member should be giving names of missing or distressed persons until every effort has been made to inform the relatives
              Families of the dead and missing must be given realistic expectations about the procedure, including the methods utilized and timescales for recovery and identification.
              Families should be able to notify a missing relative and offer additional information.
              The identification process should be completed as soon as possible.
              Local organizations such as the National Red Cross, Emergency Response organizations and non-governmental organizations (NGOs), and faith groups can frequently give emergency psychosocial assistance to individuals in need.
              Unaccompanied kids and other vulnerable groups should be provided priority attention. They should be reunited with and cared for by members of their extended family or community whenever possible.

              </p>
            </div>

          </div>
        </div>
        </div>
      }

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organization.org,
    anal: state.analytics,
    year: state.dash.year,
    region: state.dash.region,
    years: state.years.availableYears,
    regions: state.regions.availableRegions,
  };
};

export default connect(mapStateToProps, {
  fetchOrganizations,
  fetchAnalytics,
  selectRegion,
  selectYear,
  fetchYears,
  fetchRegions,
  showMembers,
  showRegister,
  showIncidents,
  closeRegisterMember,
})(Dos);
