import React from "react";
import PDF from './PDF';
import Spinner from "../../../components/UI/Spinner/Spinner";

import ErrorModal from "../../../components/UI/Modal/ErrorModal";
import { useState } from "react";
import { useFormik } from "formik";

const PressReleaseInformation = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [error, setError] = useState({})
  const [preview, setPreview] = useState(false)

  const [reason, setReason] = useState('')
  const [type, setType] = useState('')
  const [owner, setOwner] = useState('')
  const [vesselNumber, setVesselNumber] = useState('')
  const [people, setPeople] = useState('')
  const [area, setArea] = useState('')
  const [hoursFlown, setHoursFlown] = useState('')
  const [responders, setResponders] = useState('')
  const [vesselsEngaged, setVesselsEngaged] = useState('')
  const [Arrangements, setArrangements] = useState('')
  const [details, setDetails] = useState('')
  const [contactNumberFurther, setContactNumberFurther] = useState('')
  const [contactNumberMedia, setContactNumberMedia] = useState('')

  const onClickExport = () => {
  }

  // close error modal
 const handleClose = () => {
    setShow(false);
  };

  const initialValues = {
    reason,
    type,
    owner,
    vesselNumber,
    people,
    area,
    hoursFlown,
    responders,
    vesselsEngaged,
    Arrangements,
    details,
    contactNumberFurther,
    contactNumberMedia
  }

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      setPreview(true);
    }
  })
    const form = (
      <div>
        <div className="gap-2 grid grid-cols-1 xl:grid-cols-2 mt-5">
          <div className="">
            <label htmlFor="reason">General reason for the SAR operation</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="General reason for the SAR operation"
              name="reason"
              {...formik.getFieldProps('reason')}
            />
          </div>
          <div className="">
            <label htmlFor="type">Type of aircraft or vessel involved</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Type of aircraft or vessel involved"
              name="type"
              {...formik.getFieldProps('text')}
            />
          </div>
          <div className="">
            <label htmlFor="owner">Owner/Operator of the aircraft or vessel</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Owner/Operator of the aircraft or vessel"
              name="owner"
              {...formik.getFieldProps('owner')}
            />
          </div>
          <div className="">
            <label htmlFor="vesselNumber">Name of vessel/flight number</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Name of vessel/flight number"
              name="vesselNumber"
              {...formik.getFieldProps('vesselNumber')}
            />
          </div>
          <div className="">
            <label htmlFor="people">Number of people on board</label>
            <input
              type="number"
              className="form-control form-control-sm"
              placeholder="Number of people on board"
              name="people"
              {...formik.getFieldProps('people')}
            />
          </div>
          <div className="">
            <label htmlFor="area">General area being searched</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="General area being searched"
              name="area"
              {...formik.getFieldProps('area')}
            />
          </div>
          <div className="">
            <label htmlFor="hoursFlown">Number of hours flown</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Number of hours flown"
              name="hoursFlown"
              {...formik.getFieldProps('hoursFlown')}
            />
          </div>
          <div className="">
            <label htmlFor="responders">Number of first responders engaged in the SAR</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Number of first responders engaged in the SAR"
              name="responders"
              {...formik.getFieldProps('responders')}
            />
          </div>
        </div>

          <div className="w-full">
            <label htmlFor="vesselsEngaged">Number and types of aircraft and vessels engaged in the SAR</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Number and types of aircraft and vessels engaged in the SAR"
              name="vesselsEngaged"
              {...formik.getFieldProps('vesselsEngaged')}
            />
          </div>

          <div className="gap-2 grid grid-cols-1 2xl:grid-cols-2">
            <div className="">
              <label htmlFor="arrangements">Arrangements for search at sea or on land (as applicable)</label>
              <textarea
                type="text"
                className="form-control form-control-sm"
                placeholder="Arrangements for search at sea or on land (as applicable)"
                name="arrangements"
                {...formik.getFieldProps('arrangements')}
              />
            </div>
            <div className="">
              <label htmlFor="details">Details of other authorities participating in the search</label>
              <textarea
                type="text"
                className="form-control form-control-sm"
                placeholder="Details of other authorities participating in the search"
                name="details"
                {...formik.getFieldProps('details')}
              />
            </div>
          </div>
          <div className={`gap-2 grid grid-cols-1 xl:grid-cols-2 ${!preview ? 'mb-5' : ''} `}>
            <div className="">
              <label htmlFor="contactNumberFurther">Contact number for further information</label>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Contact number for further information"
                name="contactNumberFurther"
                {...formik.getFieldProps('contactNumberFurther')}
              />
            </div>
            <div className="">
              <label htmlFor="contactNumberMedia">Contact number for media enquiries</label>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Contact number for media enquiries"
                name="contactNumberMedia"
                {...formik.getFieldProps('contactNumberMedia')}
              />
            </div>
          </div>

          {preview &&
          <div className="mb-5">
            <PDF
              reason={this.state.reason}
              type={this.state.type}
              owner={this.state.owner}
              vessel_number={this.state.vessel_number}
              people={this.state.people}
              area={this.state.area}
              hours_flown={this.state.hours_flown}
              responders={this.state.responders}
              vessels_engaged={this.state.vessels_engaged}
              arrangements={this.state.arrangements}
              details={this.state.details}
              contact_number_further={this.state.contact_number_further}
              contact_number_media={this.state.contact_number_media}
            />
          </div>
          }
        
        <div className="text-center">
          {!preview && <button className="btn btn-danger btn-sm mb-5" type='submit'> Submit </button>}
          {/* <button type="button" className="btn btn-danger btn-sm" onClick={this.onClickExport}>
            Export to PDF
          </button> */}
          <ErrorModal
            onHide={handleClose}
            handleClose={handleClose}
            show={show}
            error={error}
          />
        </div>
      </div>
    );

    return (
      <div>
        <div className="RegisterPsapCenter">
          <div className="container">
            <div className="row">
              <div className="col-md-12 m-auto">
                <h6 className="display-4 text-center text-concorde-blue font-semibold mb-2">Press Release Information</h6>
                <hr />
                {isLoading ? <Spinner /> : form}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default PressReleaseInformation;
