import { YEAR_SELECTED, REGION_SELECTED } from "../../actions/types";

const INITIAL_STATE = {
  year: "2013",
  region: "ΑΙΤΩΛΟΑΚΑΡΝΑΝΙΑΣ",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case YEAR_SELECTED:
      return { ...state, year: action.payload };
    case REGION_SELECTED:
      return { ...state, region: action.payload };
    default:
      return state;
  }
};
