import React from "react";
import { connect } from "react-redux";

export const DssCasualties = (props) => {
  return (
    <div className="dss-casualties">
      <div className="row">
        <div className="col-3">
          <div className="dss-casualties-card-titles">Expected Casualties</div>
          <div className="dss-casualties-card"></div>
        </div>
        <div id="dss-casualties-vertical-line" />
        <div className="col-3">
          <div className="dss-casualties-card-titles">Demanded Resources</div>
          <div className="dss-casualties-card"></div>
        </div>
        <div className="col-5">
          <div className="dss-casualties-card-titles"></div>
          <div className="dss-casualties-card" id="dss-casualties-card-fire-brigades"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DssCasualties);
