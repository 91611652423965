import React, { useState } from "react";
import { Button } from "react-bootstrap";
import TriageDropdown from "./TriageDropdown";
import TriageForm from "./TriageForm";
import "./triage.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const Triage = () => {
  const [patient, setPatient] = useState(null);
  console.log("PATIENT", patient);
  const resetTriage = () => {
    setPatient(null);
  };
  return (
    <div id="triage-page" className="col-12">
      <h2 className="text-left dash-heading">
        <FontAwesomeIcon icon={faPencilAlt} size="sm" className="font-awesome-icon" />
        Register Patient
      </h2>
      <div className="flex-parent-element btn-container mb-3">
        <Button variant="primary" onClick={resetTriage} className="col-sm-6 new-patient-btn">
          New Patient
        </Button>
        <TriageDropdown setPatient={setPatient} />
      </div>
      <TriageForm patient={patient} />
    </div>
  );
};

export default Triage;
