export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const FETCH_ANALYTICS = "FETCH_ANALYTICS";
export const FETCH_YEARS = "FETCH_YEARS";
export const FETCH_REGIONS = "FETCH_REGIONS";
export const YEAR_SELECTED = "YEAR_SELECTED";
export const REGION_SELECTED = "REGION_SELECTED";
export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const EDIT_USER = "EDIT_USER";
export const FETCH_ORGS_START = "FETCH_ORGS_START";
export const FETCH_ORGS_SUCCESS = "FETCH_ORGS_SUCCESS";
export const FETCH_ORGS_FAILURE = "FETCH_ORGS_FAILURE";
export const EDIT_ORGS = "EDIT_ORGS";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const DELETE_ORG = "DELETE_ORG";
export const ORG_SELECTED = "ORG_SELECTED";
export const DELETE_USER = "DELETE_USER";
export const USER_SELECTED = "USER_SELECTED";
export const SHOW_REGISTER = "SHOW_REGISTER";
export const SHOW_MEMBERS = "SHOW_MEMBERS";
export const SHOW_INCIDENTS = "SHOW_INCIDENTS";
export const CLOSE_REGMEMB = "CLOSE_REGMEMB";
export const CLEAR_CSS = "CLEAR_CSS";
export const REMOVE_CLEAR_CSS = "REMOVE_CLEAR_CSS";
export const FETCH_INCIDENTS_START = "FETCH_INCIDENTS_START";
export const FETCH_INCIDENTS_SUCCESS = "FETCH_INCIDENTS_SUCCESS";
export const FETCH_INCIDENTS_FAILURE = "FETCH_INCIDENTS_FAILURE";
export const EDIT_INCIDENT = "EDIT_INCIDENTS";
export const INCIDENT_SELECTED = "INCIDENT_SELECTED";
export const DELETE_INCIDENT = "DELETE_INCIDENT";
export const SHOW_DISPATCH_MODAL = "SHOW DISPATCH MODAL";
export const HIDE_DISPATCH_MODAL = "HIDE DISPATCH MODAL";
// Dss
export const SET_DSS_TOKEN = "SET_DSS_TOKEN";
export const REFRESH_DSS_TOKEN = "REFRESH_DSS_TOKEN";
export const SET_ALLOCATION_EMS = "SET_ALLOCATION_EMS";
export const SET_EXPECTED_CASUALTIES = "SET_EXPECTED_CASUALTIES";
export const SET_HOSPITAL_PATIENTS = "SET_HOSPITAL_PATIENTS";
export const SET_ACTORS_FIELDS = "SET_ACTORS_FIELDS";
export const SET_SERVICE2_ACTORS_FIELDS = "SET_SERVICE2_ACTORS_FIELDS";
export const SET_SERVICE3_ACTORS_FIELDS = "SET_SERVICE3_ACTORS_FIELDS";
//Patients
export const FETCH_PATIENTS_REQUEST = "FETCH_PATIENTS_REQUEST";
export const FETCH_PATIENTS_SUCCESS = "FETCH_PATIENTS_SUCCESS";
export const FETCH_PATIENTS_FAILURE = "FETCH_PATIENTS_FAILURE";
export const FETCH_SITE1_PATIENTS_REQUEST = "FETCH_SITE1_PATIENTS_REQUEST";
export const FETCH_SITE1_PATIENTS_SUCCESS = "FETCH_SITE1_PATIENTS_SUCCESS";
export const FETCH_SITE1_PATIENTS_FAILURE = "FETCH_SITE1_PATIENTS_FAILURE";
export const FETCH_SITE2_PATIENTS_REQUEST = "FETCH_SITE2_PATIENTS_REQUEST";
export const FETCH_SITE2_PATIENTS_SUCCESS = "FETCH_SITE2_PATIENTS_SUCCESS";
export const FETCH_SITE2_PATIENTS_FAILURE = "FETCH_SITE2_PATIENTS_FAILURE";
export const FETCH_SITE3_PATIENTS_REQUEST = "FETCH_SITE3_PATIENTS_REQUEST";
export const FETCH_SITE3_PATIENTS_SUCCESS = "FETCH_SITE3_PATIENTS_SUCCESS";
export const FETCH_SITE3_PATIENTS_FAILURE = "FETCH_SITE3_PATIENTS_FAILURE";
