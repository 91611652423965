import React, { Component } from "react";
import { OrgInstance, GoogleAPIKey } from "../../../../../../config";
import Geocode from "react-geocode";
import {
  CustomForm,
  FormRow,
  FormInput,
  FormButtons,
  FormDropdown,
  FormWrapper,
} from "../../../../../../components/UI/Form/Form";
import * as validation from "../../../../../../components/UI/Form/formValidation";
import ErrorModal from "../../../../../../components/UI/Modal/ErrorModal";

class Other extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      prePopulate: false,
      show: false,
      error: {},
    };

    this.org = {
      name: "",
      location: "",
      lat: "",
      lng: "",
      telephoneNumber: "",
      expertise: "",
      organizationType: "OTHER",
    };
  }

  componentDidMount = () => {
    if (typeof this.props.location !== "undefined") {
      try {
        this.org = this.props.location.prePopulate;
        this.setState({ prePopulate: true });
      } catch (error) {
        console.log("Prepopulation error");
        this.setState({error: error, show: true})
      }
    }
  };

  async getLocationCoordinates(location) {
    Geocode.setApiKey(GoogleAPIKey);
    Geocode.enableDebug();
    return await Geocode.fromAddress(location).then(
      (response) => [
        response.results[0].geometry.location.lat,
        response.results[0].geometry.location.lng,
      ],
      (error) => console.error(error)
    );
  }

  onSubmit = async (formValues) => {
    try {
      [formValues.lat, formValues.lng] = await this.getLocationCoordinates(formValues.location);
    } catch (error) {
      console.log("Could not get coordinates from location.");
      this.setState({error: error, show: true})
    }

    this.setState({ loading: true });
    try {
      if (this.state.prePopulate) {
        await OrgInstance.put(
          `/other/${this.props.location.prePopulate.organizationId}/`,
          formValues
        );
      } else {
        await OrgInstance.post("/other/", formValues);
      }
      this.setState({ loading: false });
      this.props.history.push("/Dashboard");
    } catch (error) {
      this.setState({ loading: false , error: error, show:true});
      console.log("Register Other Organization API error");
    }
  };


  handleClose = () => {
    this.setState({show: false});
  };

  render() {
    return (
      <FormWrapper parentClass="Other" componentHeading="Other Organisation Details">
        <CustomForm
          initialValues={{ ...this.org }}
          onSubmit={(formValues) => this.onSubmit(formValues)}
          loading={this.state.loading}
        >
          <FormRow>
            <FormInput
              name="name"
              size="col-md-6"
              validate={(value) => validation.inputIsRequired("Name", value)}
            />
            <FormInput
              name="telephoneNumber"
              size="col-md-6"
              validate={(value) => validation.phoneNumber(value)}
            />
          </FormRow>
          <FormRow>
            <FormInput
              name="location"
              size="col-md-12"
              validate={(value) => validation.inputIsRequired("Location", value)}
            />
          </FormRow>
          <FormRow>
            <FormDropdown
              name="expertise"
              size="col-md-6"
              validate={(value) => validation.selectionIsRequired("Expertise", value)}
              options={[
                { NO_MEDICAL_SKILLS: "No Medical Skills" },
                { BASIC_LIFE_SUPPORT: "Basic Life Support" },
                { ADVANCED_LIFE_SUPPORT: "Advance Life Support" },
                { SPECIALIST_MEDICAL: "Medical Specialist" },
              ]}
            />
          </FormRow>
          <FormRow>
            <FormButtons />
          </FormRow>
          <ErrorModal
            onHide={this.handleClose}
            handleClose={this.handleClose}
            show  ={this.state.show}
            error = {this.state.error}
        />
        </CustomForm>
      </FormWrapper>
    );
  }
}

export default Other;
