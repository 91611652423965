import { FETCH_ANALYTICS } from "../../actions/types";

export default (state = 0, action) => {
  switch (action.type) {
    case FETCH_ANALYTICS:
      return { ...state, anal: action.payload };
    default:
      return state;
  }
};
