import React, { Component } from 'react'
import { InciInstance } from '../../../../../config'

class DSS extends Component {
  constructor() {
    super()
    this.state = {
      Acres: '',
      Fire_fighters: '',
      Fire_trucks: '',
      Hikers: '',
      Volunteers: '',
    }
  }
  componentDidMount = async () => {
    let results = await InciInstance.get(`incident/${this.props.incidentId}/predict-fire`)

    this.setState({
      Acres: results.data.data.response.data.Acres,
      Fire_fighters: results.data.data.response.data.Fire_fighters,
      Fire_trucks: results.data.data.response.data.Fire_trucks,
      Hikers: results.data.data.response.data.Hikers,
      Volunteers: results.data.data.response.data.Volunteers,
    })
  }
  onAddClickFireFighters = (event) => {
    event.preventDefault()
    let counter = this.state.Fire_fighters
    counter++
    this.setState({
      Fire_fighters: counter,
      Acres: this.state.Acres - (this.state.Acres * 0.28) / 100,
    })
  }

  onMinusClickFireFighters = (event) => {
    event.preventDefault()
    let minusCounter = this.state.Fire_fighters
    minusCounter--
    this.setState({
      Fire_fighters: minusCounter,
      Acres: this.state.Acres + (this.state.Acres * 3.89) / 100,
    })
  }

  onAddClickFireTrucks = (event) => {
    event.preventDefault()
    let counter = this.state.Fire_trucks
    counter++
    this.setState({
      Fire_trucks: counter,
      Acres: this.state.Acres - (this.state.Acres * 3.89) / 100,
    })
  }

  onMinusClickFireTrucks = (event) => {
    event.preventDefault()
    let minusCounter = this.state.Fire_trucks
    minusCounter--
    this.setState({
      Fire_trucks: minusCounter,
      Acres: this.state.Acres + (this.state.Acres * 3.89) / 100,
    })
  }
  onAddClickHikers = (event) => {
    event.preventDefault()
    let counter = this.state.Hikers
    counter++
    this.setState({ Hikers: counter, Acres: this.state.Acres - (this.state.Acres * 2.01) / 100 })
  }

  onMinusClickHikers = (event) => {
    event.preventDefault()
    let minusCounter = this.state.Hikers
    minusCounter--
    this.setState({
      Hikers: minusCounter,
      Acres: this.state.Acres + (this.state.Acres * 2.01) / 100,
    })
  }
  onAddClickVolunteers = (event) => {
    event.preventDefault()
    let counter = this.state.Volunteers
    counter++
    this.setState({
      Volunteers: counter,
      Acres: this.state.Acres - (this.state.Acres * 2.01) / 100,
    })
  }

  onMinusClickVolunteers = (event) => {
    event.preventDefault()
    let minusCounter = this.state.Volunteers
    minusCounter--
    this.setState({
      Volunteers: minusCounter,
      Acres: this.state.Acres + (this.state.Acres * 2.01) / 100,
    })
  }

  //fix other screens
  render() {
    return (
      <div>
        <h3 className='h3-psap-style' style={{ marginTop: '2%' }}>
          DECISION SUPPORT SYSTEM
        </h3>
        <div className='cis-DSS-headers'>
          <h6 className='dss-fire-fighters'>Fire Fighters</h6>
          <h6 className='dss-fire-trucks'>Fire Trucks</h6>
          <h6 className='dss-hikers'>Hikers</h6>
          <h6 className='dss-volunteers'>Volunteers</h6>
          <h6 className='dss-acres'>Acres</h6>
        </div>
        <div className='dss-container'>
          <div className='fire-fighters'>
            <button
              type='button'
              className='btn btn-light ml-auto'
              onClick={this.onMinusClickFireFighters}
            >
              -
            </button>
            <div className='p-3'>{this.state.Fire_fighters}</div>
            <button
              type='button'
              className='btn btn-light mr-auto'
              onClick={this.onAddClickFireFighters}
            >
              +
            </button>
          </div>
          <div className='fire-trucks'>
            <button
              type='button'
              className='btn btn-light ml-auto'
              onClick={this.onMinusClickFireTrucks}
            >
              -
            </button>
            <div className='p-3'>{this.state.Fire_trucks}</div>
            <button
              type='button'
              className='btn btn-light mr-auto'
              onClick={this.onAddClickFireTrucks}
            >
              +
            </button>
          </div>
          <div className='hikers'>
            <button
              type='button'
              className='btn btn-light ml-auto'
              onClick={this.onMinusClickHikers}
            >
              -
            </button>
            <div className='p-3'>{this.state.Hikers}</div>
            <button type='button' className='btn btn-light mr-auto' onClick={this.onAddClickHikers}>
              +
            </button>
          </div>
          <div className='volunteers'>
            <button
              type='button'
              className='btn btn-light ml-auto'
              onClick={this.onMinusClickVolunteers}
            >
              -
            </button>
            <div className='p-3'>{this.state.Volunteers}</div>
            <button
              type='button'
              className='btn btn-light mr-auto'
              onClick={this.onAddClickVolunteers}
            >
              +
            </button>
          </div>
          <div className='acres'>
            <div className='p-3'>{Math.round(this.state.Acres * 100) / 100} Acres of Land</div>
          </div>
        </div>
      </div>
    )
  }
}

export default DSS
