import {
  FETCH_INCIDENTS_START,
  FETCH_INCIDENTS_SUCCESS,
  FETCH_INCIDENTS_FAILURE,
} from "../../actions/types";

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_INCIDENTS_START:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_INCIDENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        incidents: action,
      };
    case FETCH_INCIDENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
