import {
  FETCH_INCIDENTS_START,
  FETCH_INCIDENTS_SUCCESS,
  FETCH_INCIDENTS_FAILURE,
  EDIT_INCIDENT,
  INCIDENT_SELECTED,
  DELETE_INCIDENT,
} from "../types";
import history from "../../../history";
import { InciInstance } from "../../../config";

const fetchIncidentsStart = () => {
  return {
    type: FETCH_INCIDENTS_START,
  };
};

const fetchIncidentsSuccess = (incidents) => {
  return {
    type: FETCH_INCIDENTS_SUCCESS,
    incidents,
  };
};

const fetchIncidentsFailure = (error) => {
  return {
    type: FETCH_INCIDENTS_FAILURE,
    error,
  };
};

const fetchIncidentDetails = () => {
  const response = InciInstance.get("incident/");
  return response;
};

export const fetchIncidents = () => (dispatch) => {
  dispatch(fetchIncidentsStart());
  return fetchIncidentDetails().then(
    (response) => dispatch(fetchIncidentsSuccess(response.data.data)),
    (error) => dispatch(fetchIncidentsFailure(error))
  );
};

export const selectIncident = (id) => {
  return {
    type: INCIDENT_SELECTED,
    id: id,
  };
};

export const deleteIncident = (id) => {
  return async (dispatch) => {
    const response = await InciInstance.delete(`incident/${id}/`);
    dispatch({ type: DELETE_INCIDENT, payload: response.data });
    history.push("/");
  };
};

export const editIncident = (id) => {
  return async (dispatch) => {
    const response = await InciInstance.get(`incident/${id}/`);
    dispatch({ type: EDIT_INCIDENT, payload: response.data });
    history.push({
      pathname: "/RegisterIncident",
      prePopulate: response.data.data,
      populate: true,
    });
  };
};
