import React from "react";
import moment from "moment";
import './Events.css';

export const XrayEvent = (props) => {
    return ( 
        <>
        {props.data ? 
        ( <div className="row row-eq-height mb-3 row-borders-events font-events" style={props.rowColor ? {backgroundColor: props.rowColor} : null}>
            <div className="col-3 vertical-centered-cols-events">
                <div className="row">
                    <div className="col-12">
                        <span style={{fontWeight: 'bold'}}>Local:</span> {props.data.localTimestamp ? moment.unix(props.data.localTimestamp).format("DD/MM/YYYY HH:mm:ss") : 'UNKNOWN'}
                    </div>
                </div>
            </div>
            <div className="col-3 vertical-centered-cols-events">
                <div className="row">
                    <div className="col-12">
                        <span style={{fontWeight: 'bold'}}>Sensor ID:</span> {props.data.id}
                    </div>
                    <div className="col-12">
                        <span style={{fontWeight: 'bold'}}>Sensor Type:</span> {props.data.fieldDataProducer}
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                        <span style={{fontWeight: 'bold'}}>X-ray Value:</span> &ensp;
                                {props.data?.radiationDetection?.xRayValue.toFixed(2)}
                        </div>
                    <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                        <span style={{fontWeight: 'bold'}}>X-ray Unit:</span> {props.data?.radiationDetection?.xRayUnitEnum}
                    </div>
                    <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                        <span style={{fontWeight: 'bold'}}>Other Radiation:</span> {props.data?.radiationDetection?.otherRadiation?.alert}
                    </div>
                </div>
            </div>
          </div> 
            ) : null}
        </>
    )
}