import React, { Component } from "react";
import { logout } from "../../Redux/actions/Users/securityActions";
import { connect } from "react-redux";
import { dssTokenClear } from "../../Redux/actions/dss/dssActions";

class Logout extends Component {
  componentDidMount() {
    if (this.props.security.validToken) {
      this.props.dssTokenClear();
      this.props.logout();
      this.props.history.push("/Login");
    }
  }
  render() {
    return <div />;
  }
}
const mapStateToProps = (state) => ({
  security: state.security,
});
export default connect(mapStateToProps, { logout, dssTokenClear })(Logout);
