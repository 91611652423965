import React from "react";
import moment from "moment";
import './Events.css';

export const GpsEvent = (props) => {
  
    return ( 
        <>
        {props.data ? 
        ( <div className="row row-eq-height mb-3 row-borders-events font-events" style={props.rowColor ? {backgroundColor: props.rowColor }: null}>
            <div className="col-3 vertical-centered-cols-events">
                <div className="row">
                    <div className="col-12">
                        <span style={{fontWeight: 'bold'}}>Local:</span> {moment.unix(props.data.localTimestamp).format("DD/MM/YYYY HH:mm:ss")}
                    </div>
                </div>
            </div>
            <div className="col-3 vertical-centered-cols-events">
                <div className="row">
                    <div className="col-12">
                        <span style={{fontWeight: 'bold'}}>Sensor ID:</span> {props.data.id}
                    </div>
                    <div className="col-12">
                        <span style={{fontWeight: 'bold'}}>Sensor Type:</span> {props.data.fieldDataProducer}
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                        <span style={{display: 'block', fontWeight: 'bold'}}>Location</span>
                        <div className='mt-1'>
                            <span style={{display: 'block'}}><span style={{fontWeight: 'bold'}}>Lat:</span> {props.data.location.point.wgs84Latitude}</span>
                            <span style={{display: 'block'}}><span style={{fontWeight: 'bold'}}>Lng:</span> {props.data.location.point.wgs84Longitude}</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                        <span style={{fontWeight: 'bold'}}>Altitude:</span> {props.data.otherValues.altitude}
                    </div>
                    <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                        <span style={{fontWeight: 'bold'}}>Accuracy:</span> {props.data.otherValues.accuracy}
                    </div>
                </div>
            </div>
          </div> 
            ) : null}
        </>
    )
}