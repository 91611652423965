import React, { Component } from "react";
import { authInstance, InciInstance, UserInstance, GoogleAPIKey } from "../../../../../config";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import _, { has } from "underscore";
import { connect } from "react-redux";
import "../../Cis.css";
import VolunteerService from "../../../../../services/VolunteerService";
import axios from "axios";

class CisMapOfficial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: true,
      error: {},
      userData: {},
      gps: [],
      dogs: [],
      prevGpsMarkers: [],
      prevDogMarkers: [],
      inDangerVolunteers: [],
      smartwatchGps: [],
      healthMonitoringDeviceGps: [],
    };
  }

  polygonTimer = null;
  markerTimer = null;
  intervalTimer = null;

  map = null;
  drawingManager = null;
  service = null;
  mapCenter = null;

  markerApiResponse = null;
  markers = {};
  markersString = {};
  markerArray = [];

  polygonApiResponse = null;
  polygons = {};
  polygonsString = {};
  polygonArray = [];

  polygonColorOptions = ["safe", "caution", "danger"];
  markerOptions = ["Helicopter", "Command", "Access", "Medical", "Patient", "Danger"];

  getUser = async () => {
    try {
      const userCall = await UserInstance.get(`user/${this.props.security.user.user_id}`);
      console.log("USER DATA", userCall);
      this.setState({ userData: userCall.data.data });
    } catch (error) {
      console.log("Get User API error");
    }
  };

  getAuthHeader() {
    const authorizationHeader = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };
    return authorizationHeader;
  }

  getHealthMonitoringGps = async () => {
    const healthMonitoringResponse = await authInstance.get(
      "events/event/health_monitoring_device/"
    );
    this.setState({
      healthMonitoringDeviceGps:
        healthMonitoringResponse.data.data?.health_monitoring_device?.part_1,
    });
    console.log(
      "HEALTH MONITOR RESPONSE",
      healthMonitoringResponse.data.data?.health_monitoring_device?.part_1
    );
  };

  getSmartwatchGps = async () => {
    const smartwatchGpsResponse = await authInstance.get("events/event/smartwatch/");
    this.setState({ smartwatchGps: smartwatchGpsResponse.data.data?.smartwatch?.part_1 });
  };

  getInDangerVolunteers = async () => {
    const volunteerService = new VolunteerService();
    const inDangerVolunteerResponse = await volunteerService.getInDangerVolunteer();
    this.setState({ inDangerVolunteers: inDangerVolunteerResponse.data.data });
  };

  getGpsResponse = async () => {
    const gpsIdList = [10002, 10003, 10004, 10005, 10010];
    const gpsArray = gpsIdList.map((id) => {
      return authInstance.get(`events/event/latest_gps_tracker/${id}`);
    });
    const gpsResponse = await Promise.all(gpsArray);
    const gpsLocations = gpsResponse.map((object) => {
      return object.data.data?.location;
    });
    console.log("GPS", gpsLocations);
    this.setState({
      gps: gpsLocations,
    });
  };

  getDogGpsResponse = async () => {
    const dogList = [10000, 10001];
    const dogArray = dogList.map((id) => {
      return authInstance.get(`events/event/latest_gps_tracker/${id}`);
    });
    const dogResponse = await Promise.all(dogArray);
    const dogLocations = dogResponse.map((object) => {
      return object.data.data?.location;
    });
    console.log("GPS", dogLocations);
    this.setState({
      dogs: dogLocations,
    });
  };

  async toggleLoading(promise) {
    this.setState({ loading: true });
    try {
      const result = await promise;
      this.setState({ loading: false });
      return result;
    } catch (error) {
      // this.setState({ loading: false , error: error, show: true});
    }
  }

  getBackendMarkers = async () => {
    try {
      const res = await this.toggleLoading(
        InciInstance.get(`incident/${this.props.incidentId}/`, this.getAuthHeader())
      );
      return res.data.data.markers;
    } catch (error) {
      console.log("Get Incident by ID API error (Marker)");
      // this.setState({ error: error, show: true})
    }
  };

  renderBackendMarkers = (markerInfo) => {
    window.google &&
      markerInfo.then((markerInfo) => {
        this.markersString = JSON.parse(markerInfo);
        this.markers = Object.assign(
          {},
          ...Object.keys(this.markersString).map((k) => ({
            [k]: JSON.parse(this.markersString[k]),
          }))
        );
        for (let key in this.markers) {
          let marker = new window.google.maps.Marker({
            position: this.markers[key].position,
            icon: this.markers[key].icon,
          });
          marker.addListener("rightclick", () => {
            this.deleteMarker(marker, key);
          });
          marker.addListener("click", () => {
            this.map.setZoom(13);
            this.map.setCenter(marker.getPosition());
          });
          marker.setMap(this.map);
          this.markerArray.push(marker);
        }
        this.markerApiResponse = markerInfo;
      });
  };

  // Marker options based on the button pressed
  chooseMarker = (markerOption) => {
    const anchor = new window.google.maps.Point(17, 17);
    // const imgDir = (filename) => `../../../../assets/images/${filename}`;
    this.drawingManager.setOptions({
      drawingMode: "marker",
    });
    switch (markerOption) {
      case "Helicopter":
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/helipadIcon.png"), anchor: anchor },
            type: "helicopter",
          },
        });
        break;
      case "Command":
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/commandIcon.png"), anchor: anchor },
            type: "command",
          },
        });
        break;
      case "Access":
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/accessIcon.png"), anchor: anchor },
            type: "access",
          },
        });
        break;
      case "Medical":
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/medicalIcon.png"), anchor: anchor },
            type: "medical",
          },
        });
        break;
      case "Patient":
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/patientIcon.png"), anchor: anchor },
            type: "patient",
          },
        });
        break;
      default:
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/dangerIcon.png"), anchor: anchor },
            type: "default",
          },
        });
        break;
    }
  };

  onMarkerComplete = async (marker) => {
    let markerObj = {
      position: marker.getPosition(),
      icon: marker.getIcon(),
      type: marker.type,
    };

    let key = Date.now();
    this.markers[key] = markerObj;

    let markerObjStr = JSON.stringify(markerObj);
    this.markersString[key] = markerObjStr;

    marker.addListener("rightclick", () => {
      this.deleteMarker(marker, key);
    });

    marker.addListener("click", () => {
      this.map.setZoom(13);
      this.map.setCenter(marker.getPosition());
    });

    // this.markerArray.push(marker);

    try {
      await this.toggleLoading(
        InciInstance.post(
          `incident/${this.props.incidentId}/add-markers/`,
          { markerInfo: this.markersString },
          this.getAuthHeader()
        )
      );
    } catch (error) {
      console.log("Add markers API error");
      // this.setState({ error: error, show: true})
    }
  };

  deleteMarker = async (marker, key) => {
    marker.setMap(null);
    try {
      await this.toggleLoading(
        InciInstance.delete(
          `incident/${this.props.incidentId}/delete-markers/`,
          { data: { markerInfo: { [key]: this.markersString[key] } } },
          this.getAuthHeader()
        )
      );
    } catch (error) {
      console.log("Delete markers API error");
      // this.setState({ error: error, show: true})
    }
  };

  attachDrawingManager = () => {
    //  Construct the drawing manager
    this.drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          window.google.maps.drawing.OverlayType.POLYGON,
          window.google.maps.drawing.OverlayType.MARKER,
        ],
      },
      polygonOptions: {
        fillColor: "#67D615",
        fillOpacity: 0.3,
        strokeWeight: 2,
        strokeColor: "#36B9FA",
        zIndex: 1,
      },
      markerOptions: {
        icon: {
          url: require("../../../../../assets/images/dangerIcon.png"),
          anchor: new window.google.maps.Point(17, 17),
        },
      },
    });
    this.drawingManager.addListener("markercomplete", (marker) => {
      this.onMarkerComplete(marker);
    });
    this.drawingManager.addListener("polygoncomplete", (polygon) => {
      this.onPolygonComplete(polygon);
    });

    //  Attach the drawing manager on the map
    this.drawingManager.setMap(this.map);
  };

  attachGooglePlaces = () => {
    let hospitalRequest = {
      location: this.mapCenter,
      radius: "2000",
      type: ["hospital"],
    };

    this.service = new window.google.maps.places.PlacesService(this.map);

    this.service.nearbySearch(hospitalRequest, callback);

    const createMarker = (place) => {
      let marker = new window.google.maps.Marker({
        map: this.map,
        position: place.geometry.location,
      });
      let content = `<h6>${place.name}</h6><br><b>Address</b>: ${place.vicinity}`;
      let infowindow = new window.google.maps.InfoWindow();
      window.google.maps.event.addListener(marker, "mouseover", function () {
        infowindow.setContent(content);
        infowindow.open(this.map, marker);
      });
      window.google.maps.event.addListener(marker, "mouseout", function () {
        infowindow.close();
      });

      navigator.geolocation.watchPosition((position) => {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);

        let marker1 = new window.google.maps.Marker({
          map: this.map,
          position: { lat: position.coords.latitude, lng: position.coords.longitude },
          // position: {lat: 37.9939136 , lng: 23.678646999999998 }
        });
        console.log(this.props);
        let content1 = `<h6>I am here </h6><b>${this.props.security.user.user_roles[0]}</b>`;
        window.google.maps.event.addListener(marker1, "mouseover", function () {
          infowindow.setContent(content1);
          infowindow.open(this.map, marker1);
        });
        window.google.maps.event.addListener(marker1, "mouseout", function () {
          infowindow.close();
        });
      });
    };

    function callback(results, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        for (let i = 0; i < results.length; i++) {
          createMarker(results[i]);
        }
      }
    }
  };

  loadGoogleMaps = () => {
    if (!window.google) {
      let googleMapsScript = document.createElement("script");
      googleMapsScript.type = "text/javascript";
      googleMapsScript.src = `https://maps.google.com/maps/api/js?key=${GoogleAPIKey}&v=3.exp&libraries=geometry,drawing,places`;
      let scriptNode = document.getElementsByTagName("script")[0];
      scriptNode.parentNode.insertBefore(googleMapsScript, scriptNode);

      // Below is important.
      // We cannot access google.maps until it's finished loading
      googleMapsScript.addEventListener("load", (e) => {
        this.mapConstructor();
      });
    } else {
      this.mapConstructor();
    }
  };

  // renderGpsMarkers = () => {
  //   let hasNewLocation = true;
  //   if (this.state.prevGpsMarkers.length !== 0) {
  //     hasNewLocation = this.state.gps.some((location, index) => {
  //       return (
  //         location?.point?.wgs84Latitude !== this.state.prevGpsMarkers[index]?.position?.lat() ||
  //         location?.point?.wgs84Longitude !== this.state.prevGpsMarkers[index]?.position?.lng()
  //       );
  //     });
  //   }
  //   if (!window.google?.maps) return;
  //   if (!hasNewLocation) return;
  //   window.google.maps.Map.prototype.clearMarkers = () => {
  //     for (let i = 0; i < this.state.prevGpsMarkers.length; i++) {
  //       this.state.prevGpsMarkers.at(i).setMap(null);
  //     }
  //     this.setState({ prevGpsMarkers: [] });
  //   };
  //   window.google.maps.Map.prototype.clearMarkers();
  //   this.state.gps.length !== 0 &&
  //     hasNewLocation &&
  //     this.state.gps.forEach((object, index, arr) => {
  //       const currentLocation = object?.point;
  //       // Create a Marker for EMS only
  //       const gpsAnchor = new window.google.maps.Point(17, 17);
  //       const gpsLatLng = {
  //         lat: currentLocation?.wgs84Latitude,
  //         lng: currentLocation?.wgs84Longitude,
  //       };
  //       const gpsIconUrl = `${require("../../../../../assets/images/gpsIcon.png")}`;
  //       const gpsMarker = new window.google.maps.Marker({
  //         position: gpsLatLng,
  //         icon: {
  //           url: gpsIconUrl,
  //           anchor: gpsAnchor,
  //         },
  //         title: `Lat: ${gpsLatLng.lat}, Lng: ${gpsLatLng.lng}`,
  //       });
  //       this.setState((prevState) => {
  //         return { prevGpsMarkers: [...prevState.prevGpsMarkers, gpsMarker] };
  //       });

  //       // Allow to click the marker
  //       gpsMarker.addListener("click", () => {
  //         this.map.setZoom(13);
  //         this.map.setCenter(gpsMarker.getPosition());
  //       });
  //       // Set the marker on the map
  //       gpsMarker.setMap(this.map);
  //       // Add a Popup Window
  //       gpsMarker.infoWindow = new window.google.maps.InfoWindow({
  //         content: `First Responder ${index + 1}`,
  //         shouldFocus: false,
  //         disableAutoPan: true,
  //       });
  //       // gpsMarker.infoWindow.close(this.map, gpsMarker);
  //       // WARNING !!!! DO NOT MODIFY THE FOLLOWING LINE FOR THE MAP CENTERING WILL BREAK !!!!!!
  //       // if (!window.google.maps.InfoWindow.prototype.opened) {

  //       // }
  //       gpsMarker.infoWindow.open({
  //         anchor: gpsMarker,
  //         map: this.map,
  //         shouldFocus: false,
  //       });

  //       // if (index === arr.length - 1) {
  //       //   window.google.maps.InfoWindow.prototype.opened = true;
  //       // }

  //       //Allow to reopen the info window
  //       window.google.maps.event.addListener(gpsMarker, "click", (e) => {
  //         if (gpsMarker.infoWindow) {
  //           gpsMarker.infoWindow.close({
  //             anchor: gpsMarker,
  //             map: this.map,
  //             shouldFocus: false,
  //           });
  //           gpsMarker.infoWindow.open({
  //             anchor: gpsMarker,
  //             map: this.map,
  //             shouldFocus: false,
  //           });
  //         } else {
  //           gpsMarker.infoWindow.open({
  //             anchor: gpsMarker,
  //             map: this.map,
  //             shouldFocus: false,
  //           });
  //         }
  //       });
  //     });
  // };

  renderDogMarkers = () => {
    let hasNewLocation = true;
    if (this.state.prevDogMarkers.length !== 0) {
      hasNewLocation = this.state.gps.some((location, index) => {
        return (
          location?.point?.wgs84Latitude !== this.state.prevDogMarkers[index]?.position?.lat() ||
          location?.point?.wgs84Longitude !== this.state.prevDogMarkers[index]?.position?.lng()
        );
      });
    }
    if (!window.google?.maps) return;
    if (!hasNewLocation) return;
    window.google.maps.Map.prototype.clearMarkers = () => {
      for (let i = 0; i < this.state.prevDogMarkers.length; i++) {
        this.state.prevDogMarkers.at(i).setMap(null);
      }
      this.setState({ prevDogMarkers: [] });
    };
    window.google.maps.Map.prototype.clearMarkers();
    this.state.dogs.length !== 0 &&
      hasNewLocation &&
      this.state.dogs.forEach((object, index, arr) => {
        const currentLocation = object?.point;
        // Create a Marker for EMS only
        const dogAnchor = new window.google.maps.Point(17, 17);
        const dogLatLng = {
          lat: currentLocation?.wgs84Latitude,
          lng: currentLocation?.wgs84Longitude,
        };
        const dogIconUrl = `${require("../../../../../assets/images/gpsIcon.png")}`;
        const dogMarker = new window.google.maps.Marker({
          position: dogLatLng,
          icon: {
            url: dogIconUrl,
            anchor: dogAnchor,
          },
          title: `Lat: ${dogLatLng.lat}, Lng: ${dogLatLng.lng}`,
        });
        this.setState((prevState) => {
          return { prevGpsMarkers: [...prevState.prevGpsMarkers, dogMarker] };
        });

        // Allow to click the marker
        dogMarker.addListener("click", () => {
          this.map.setZoom(13);
          this.map.setCenter(dogMarker.getPosition());
        });
        // Set the marker on the map
        dogMarker.setMap(this.map);
        // Add a Popup Window
        dogMarker.infoWindow = new window.google.maps.InfoWindow({
          content: `First Responder ${index + 1}`,
          shouldFocus: false,
          disableAutoPan: true,
        });
        // gpsMarker.infoWindow.close(this.map, gpsMarker);
        // WARNING !!!! DO NOT MODIFY THE FOLLOWING LINE FOR THE MAP CENTERING WILL BREAK !!!!!!
        // if (!window.google.maps.InfoWindow.prototype.opened) {

        // }
        dogMarker.infoWindow.open({
          anchor: dogMarker,
          map: this.map,
          shouldFocus: false,
        });

        // if (index === arr.length - 1) {
        //   window.google.maps.InfoWindow.prototype.opened = true;
        // }

        //Allow to reopen the info window
        window.google.maps.event.addListener(dogMarker, "click", (e) => {
          if (dogMarker.infoWindow) {
            dogMarker.infoWindow.close({
              anchor: dogMarker,
              map: this.map,
              shouldFocus: false,
            });
            dogMarker.infoWindow.open({
              anchor: dogMarker,
              map: this.map,
              shouldFocus: false,
            });
          } else {
            dogMarker.infoWindow.open({
              anchor: dogMarker,
              map: this.map,
              shouldFocus: false,
            });
          }
        });
      });
  };

  renderVolunteerMarkers = () => {
    this.state.inDangerVolunteers.forEach((volunteer) => {
      const vLat = volunteer?.lat;
      const vLng = volunteer?.lon;
      // Create a Marker
      const vAnchor = new window.google.maps.Point(17, 17);
      const vLatLng = { lat: vLat, lng: vLng };
      const vIconUrl = `${require("../../../../../assets/images/red-marker.png")}`;

      const vMarker = new window.google.maps.Marker({
        position: vLatLng,
        icon: {
          url: vIconUrl,
          anchor: vAnchor,
        },
        title: `Lat: ${vLatLng.lat}, Lng: ${vLatLng.lng}`,
      });
      // Allow to click the marker
      vMarker.addListener("click", () => {
        this.map.setZoom(13);
        this.map.setCenter(vMarker.getPosition());
      });
      // Set the marker on the map
      vMarker.setMap(this.map);
      // Add a Popup Window
      vMarker.infoWindow = new window.google.maps.InfoWindow({
        content: `${volunteer.username}: In Danger!`,
        disableAutoPan: true,
      });
      vMarker.infoWindow.open(this.map, vMarker);
      //Allow to reopen the info window
      window.google.maps.event.addListener(vMarker, "click", (e) => {
        if (vMarker.infoWindow) {
          vMarker.infoWindow.close(this.map, vMarker);
          vMarker.infoWindow.open(this.map, vMarker);
        } else {
          vMarker.infoWindow.open(this.map, vMarker);
        }
      });
    });
  };

  renderSmartwatchMarkers = () => {
    this.state.smartwatchGps.forEach((smartwatch, index) => {
      const sLat = smartwatch?.startingLocation?.point?.wgs84Latitude;
      const sLng = smartwatch?.startingLocation?.point?.wgs84Longitude;
      // Create a Marker
      const sAnchor = new window.google.maps.Point(17, 17);
      const sLatLng = { lat: sLat, lng: sLng };
      const sIconUrl = `${require("../../../../../assets/images/gpsIcon.png")}`;

      const sMarker = new window.google.maps.Marker({
        position: sLatLng,
        icon: {
          url: sIconUrl,
          anchor: sAnchor,
        },
        title: `Lat: ${sLatLng.lat}, Lng: ${sLatLng.lng}`,
      });
      // Allow to click the marker
      sMarker.addListener("click", () => {
        this.map.setZoom(13);
        this.map.setCenter(sMarker.getPosition());
      });
      // Set the marker on the map
      sMarker.setMap(this.map);
      // Add a Popup Window
      sMarker.infoWindow = new window.google.maps.InfoWindow({
        content: `Smartwach ${smartwatch.id}`,
        disableAutoPan: true,
      });
      sMarker.infoWindow.open(this.map, sMarker);
      //Allow to reopen the info window
      window.google.maps.event.addListener(sMarker, "click", (e) => {
        if (sMarker.infoWindow) {
          sMarker.infoWindow.close(this.map, sMarker);
          sMarker.infoWindow.open(this.map, sMarker);
        } else {
          sMarker.infoWindow.open(this.map, sMarker);
        }
      });
    });
  };

  renderHealthMonitoringDeviceMarkers = () => {
    this.state.healthMonitoringDeviceGps.forEach((device, index) => {
      console.log("INSIDE");
      const hLat = device?.location?.point?.wgs84Latitude;
      const hLng = device?.location?.point?.wgs84Longitude;
      // Create a Marker
      const hAnchor = new window.google.maps.Point(17, 17);
      const hLatLng = { lat: +hLat, lng: +hLng };
      const hIconUrl = `${require("../../../../../assets/images/gpsIcon.png")}`;

      const hMarker = new window.google.maps.Marker({
        position: hLatLng,
        icon: {
          url: hIconUrl,
          anchor: hAnchor,
        },
        title: `Lat: ${hLatLng.lat}, Lng: ${hLatLng.lng}`,
      });
      console.log("MARKER COMPLETE");

      // Allow to click the marker
      hMarker.addListener("click", () => {
        this.map.setZoom(13);
        this.map.setCenter(hMarker.getPosition());
      });
      // Set the marker on the map
      hMarker.setMap(this.map);
      console.log("MARKER ON MAP");
      // Add a Popup Window
      hMarker.infoWindow = new window.google.maps.InfoWindow({
        content: `Health Monitoring Device ${index}`,
        disableAutoPan: true,
      });
      hMarker.infoWindow.open(this.map, hMarker);
      //Allow to reopen the info window
      window.google.maps.event.addListener(hMarker, "click", (e) => {
        if (hMarker.infoWindow) {
          hMarker.infoWindow.close(this.map, hMarker);
          hMarker.infoWindow.open(this.map, hMarker);
        } else {
          hMarker.infoWindow.open(this.map, hMarker);
        }
      });
    });
    console.log("KKKKK", this.state.healthMonitoringDeviceGps);
  };

  mapConstructor = async () => {
    console.log(this.props.incidentId);
    const mapData = await InciInstance.get(`incident/${this.props.incidentId}/`);
    console.log("role", this.props.security.user.incidentRole);
    console.log("Incident Id", this.props.incidentId);
    //renders map diferently based on user
    window.google.maps.InfoWindow.prototype.opened = false;
    switch (this.props.security.user.incidentRole) {
      case "EMS":
        // Filter data from DSS for user
        const service2Array = this.props.service2Data;
        const service3Array = this.props.service3Data;
        console.log("ARRAY 1", service2Array);
        console.log("ARRAY 2", service3Array);
        // Filter the arrays
        const filteredService2Array = service2Array?.find((object) => {
          return object.actors.find((actor) => {
            return actor.id === this.props.security.user.user_id;
          });
        });

        const objectService2Array = () => {
          return Object.keys(filteredService2Array).map((key) => ({
            ...filteredService2Array,
            actors: filteredService2Array["actors"]?.find(
              (actor) => actor.id === this.props.security.user.user_id
            ),
          }))[0];
        };

        console.log("FILTERED SERVICE @ ARRAY", filteredService2Array);
        console.log(this.props.security.user.user_id);

        let service2ArrayInput;

        if (filteredService2Array === undefined) {
          service2ArrayInput = null;
        } else {
          service2ArrayInput = objectService2Array();
        }

        const filteredService3Array = service3Array.filter((object) => {
          return object.actor.actor_id === this.props.security.user.user_id;
        });
        // Merge the arrays
        const filteredEMS = [...filteredService3Array, service2ArrayInput].filter((falsyValue) =>
          Boolean(falsyValue)
        );
        // Filter data from DSS for user
        console.log("USER ID", this.props.security.user.user_id);
        console.log("ARRAY FILTERED-EMS", filteredEMS);
        // Center the map - Coordinates EMS
        const lat =
          filteredEMS.length !== 0 ? filteredEMS[0]?.gotolocation[0] : "40.35992220881701";
        const lng =
          filteredEMS.length !== 0 ? filteredEMS[0]?.gotolocation[1] : "-3.9198205429769577";

        this.mapCenter = new window.google.maps.LatLng(Number(lat), Number(lng));
        // Construct the map
        this.map = new window.google.maps.Map(document.getElementById("OfficialMap"), {
          center: {
            lat: Number(lat),
            lng: Number(lng),
          },
          zoom: 13,
        });
        // Create Marker for each location in data
        filteredEMS.forEach((object) => {
          const emsLat = object?.gotolocation[0];
          const emsLng = object?.gotolocation[1];
          // Create a Marker for EMS only
          const emsAnchor = new window.google.maps.Point(17, 17);
          const emsLatLng = { lat: emsLat, lng: emsLng };
          const iconUrl = `${
            this.state.userData.details.EMSposition === "RUNNER" || "RETRIEVER"
              ? require("../../../../../assets/images/patientIcon.png")
              : require("../../../../../assets/images/dangerIcon.png")
          }`;
          const emsMarker = new window.google.maps.Marker({
            position: emsLatLng,
            icon: {
              url: iconUrl,
              anchor: emsAnchor,
            },
            title: `Lat: ${emsLatLng.lat}, Lng: ${emsLatLng.lng}`,
          });

          // Allow to click the marker
          emsMarker.addListener("click", () => {
            this.map.setZoom(13);
            this.map.setCenter(emsMarker.getPosition());
          });
          // Set the marker on the map
          emsMarker.setMap(this.map);
          // Add a Popup Window
          emsMarker.infoWindow = new window.google.maps.InfoWindow({
            content: `Go to location: [${emsLatLng.lat}, ${emsLatLng.lng}]`,
            disableAutoPan: true,
          });
          emsMarker.infoWindow.open(this.map, emsMarker);
          //Allow to reopen the info window
          window.google.maps.event.addListener(emsMarker, "click", (e) => {
            if (emsMarker.infoWindow) {
              emsMarker.infoWindow.close(this.map, emsMarker);
              emsMarker.infoWindow.open(this.map, emsMarker);
            } else {
              emsMarker.infoWindow.open(this.map, emsMarker);
            }
          });
        });
        //Render GPS Markers for Dogs
        this.renderDogMarkers();
        // Add the drawing buttons
        this.attachDrawingManager();
        break;
      case "FIELD_COMMANDER":
        // Center the map - Coordinates Field Commander
        const fLat = +"40.35992220881701";
        const fLng = +"-3.9198205429769577";
        this.mapCenter = new window.google.maps.LatLng(fLat, fLng);
        // Construct the map
        this.map = new window.google.maps.Map(document.getElementById("OfficialMap"), {
          center: {
            lat: fLat,
            lng: fLng,
          },
          zoom: 13,
        });
        //Render GPS Markers for Dogs
        this.renderDogMarkers();
        //Render GPS Markers for Volunteers in Danger
        this.renderVolunteerMarkers();
        //Render GPS Markers for Smartwatch
        this.renderSmartwatchMarkers();
        //Render GPs Markers for Health Monitoring Device
        this.renderHealthMonitoringDeviceMarkers();
        // Create a GPS Marker for each actor in data
        // this.renderGpsMarkers();
        // Add the drawing buttons
        this.attachDrawingManager();
        break;
      default:
        this.mapCenter = new window.google.maps.LatLng(
          Number(mapData.data.data.lat),
          Number(mapData.data.data.lng)
        );
        // Construct the map
        this.map = new window.google.maps.Map(document.getElementById("OfficialMap"), {
          center: {
            lat: Number(mapData.data.data.lat),
            lng: Number(mapData.data.data.lng),
          },
          zoom: 13,
        });
        // Add the drawing buttons
        this.attachDrawingManager();
    }

    this.attachGooglePlaces();
  };

  componentDidMount = async () => {
    await this.getUser();
    this.props.security.user.incidentRole === "FIELD_COMMANDER" && (await this.getGpsResponse());
    await this.getDogGpsResponse();
    this.gpsInterval =
      this.props.security.user.incidentRole === "FIELD_COMMANDER"
        ? setInterval(this.getGpsResponse, 60000)
        : null;
    this.dogInterval = setInterval(this.getDogGpsResponse, 300000);
    await this.loadGoogleMaps();
    this.polygonTimer = setTimeout(() => {
      this.props.security.user.incidentRole !== "HIGH_COMMANDER" &&
        this.renderBackendPolygons(this.getBackendPolygons());
    }, 1000);
    this.markerTimer = setTimeout(() => {
      this.props.security.user.incidentRole !== "HIGH_COMMANDER" &&
        this.renderBackendMarkers(this.getBackendMarkers());
    }, 1000);
    this.getInDangerVolunteers();
    this.getSmartwatchGps();
    this.getHealthMonitoringGps();
    // this.intervalTimer = setInterval(() => {
    //   this.checkForChanges();
    // }, 30000);
  };

  componentDidUpdate = (prevProps, prevState) => {
    // if (this.state.gps !== prevState.gps) {
    //   this.renderGpsMarkers();
    //   console.log("THIS GPS STATE", this.state.gps);
    // }
  };

  componentWillUnmount() {
    clearTimeout(this.polygonTimer);
    clearTimeout(this.markerTimer);
    clearTimeout(this.intervalTimer);
    clearInterval(this.gpsInterval);
    clearInterval(this.dogInterval);
  }

  checkForChanges = () => {
    let latestMarkerApiRes = this.getBackendMarkers();
    latestMarkerApiRes.then((markersResolved) => {
      if (!_.isEqual(this.markerApiResponse, markersResolved)) {
        for (let marker of this.markerArray) {
          try {
            marker.setMap(null);
          } catch (error) {
            console.log("Marker does not exist!");
            this.setState({ error: error, show: true });
          }
        }
        this.markerArray = [];
        this.renderBackendMarkers(latestMarkerApiRes);
      }
    });

    let latestPolygonApiRes = this.getBackendPolygons();
    latestPolygonApiRes.then((polygonsResolved) => {
      if (!_.isEqual(this.polygonApiResponse, polygonsResolved)) {
        for (let polygon of this.polygonArray) {
          try {
            polygon.setMap(null);
          } catch (error) {
            console.log("Polygon does not exist!");
            this.setState({ error: error, show: true });
          }
        }
        this.polygonArray = [];
        this.renderBackendPolygons(latestPolygonApiRes);
      }
    });
  };

  // Polygon options based on the button pressed
  colorOptions = (color) => {
    const common = {
      fillOpacity: 0.3,
      strokeWeight: 2,
      strokeColor: "#36B9FA",
      zIndex: 1,
    };
    this.drawingManager.setOptions({
      drawingMode: "polygon",
    });
    switch (color) {
      case "safe":
        this.drawingManager.setOptions({ polygonOptions: { fillColor: "#67D615", ...common } });
        break;
      case "caution":
        this.drawingManager.setOptions({ polygonOptions: { fillColor: "#F5D316", ...common } });
        break;
      default:
        this.drawingManager.setOptions({ polygonOptions: { fillColor: "#FF0000", ...common } });
        break;
    }
  };

  onPolygonComplete = async (polygon) => {
    let polygonObj = {
      paths: polygon.getPath(),
      fillColor: polygon.fillColor,
      fillOpacity: polygon.fillOpacity,
      strokeWeight: polygon.strokeWeight,
      strokeColor: polygon.strokeColor,
      zIndex: polygon.zIndex,
    };

    let key = Date.now();
    this.polygons[key] = polygonObj;

    let polygonObjStr = JSON.stringify(polygonObj);
    this.polygonsString[key] = polygonObjStr;

    polygon.addListener("rightclick", () => {
      this.deletePolygon(polygon, key);
    });

    polygon.addListener("mouseup", () => {
      this.resizePolygon(polygon, key);
    });

    this.polygonArray.push(polygon);

    try {
      await this.toggleLoading(
        InciInstance.post(
          `incident/${this.props.incidentId}/add-polygons/`,
          { polygonInfo: this.polygonsString },
          this.getAuthHeader()
        )
      );
    } catch (error) {
      console.log("Add polygons API error");
      // this.setState({ error: error, show: true})
    }
  };

  getBackendPolygons = async () => {
    try {
      const res = await this.toggleLoading(
        InciInstance.get(`incident/${this.props.incidentId}/`, this.getAuthHeader())
      );
      return res.data.data.polygons;
    } catch (error) {
      console.log("Get Incident by ID API error (Polygon)");
      // this.setState({ error: error, show: true})
    }
  };

  renderBackendPolygons = (polygonInfo) => {
    window.google &&
      polygonInfo.then((polygonInfo) => {
        // If we get invalid JSON, for whatever reason, then avoid crashing.
        try {
          this.polygonsString = JSON.parse(polygonInfo);
        } catch {
          polygonInfo = "{}";
          this.polygonsString = JSON.parse(polygonInfo);
        }

        this.polygons = Object.assign(
          {},
          ...Object.keys(this.polygonsString).map((k) => ({
            [k]: JSON.parse(this.polygonsString[k]),
          }))
        );

        for (let key in this.polygons) {
          let polygon = new window.google.maps.Polygon({
            editable: true,
            fillColor: this.polygons[key].fillColor,
            fillOpacity: this.polygons[key].fillOpacity,
            strokeWeight: this.polygons[key].strokeWeight,
            strokeColor: this.polygons[key].strokeColor,
            zIndex: this.polygons[key].zIndex,
          });
          polygon.setPath(this.polygons[key].paths.i);
          polygon.addListener("rightclick", () => {
            this.deletePolygon(polygon, key);
          });
          polygon.addListener("mouseup", () => {
            this.resizePolygon(polygon, key);
          });
          polygon.setMap(this.map);
          this.polygonArray.push(polygon);
        }
        this.polygonApiResponse = polygonInfo;
      });
  };

  resizePolygon = async (polygon, key) => {
    // We need to extract out polygon coordinate manually
    // because otherwise there will be a
    // "Converting circular structure to JSON" error.
    let coordinates = [];
    polygon
      .getPath()
      .getArray()
      .forEach((i) => {
        coordinates.push({ lat: i.lat(), lng: i.lng() });
      });

    // The gm_accessors_ and gm_bindings_ lines are here so that
    // the custom polygon matches other polygons.
    const paths = {
      i: coordinates,
      gm_accessors_: { length: null },
      length: coordinates.length,
      gm_bindings_: { length: {} },
    };

    let updatedPolygon = {
      paths,
      fillColor: polygon.fillColor,
      fillOpacity: polygon.fillOpacity,
      strokeWeight: polygon.strokeWeight,
      strokeColor: polygon.strokeColor,
      zIndex: polygon.zIndex,
    };

    this.polygons[key] = updatedPolygon;
    let updatedPolygonStr = JSON.stringify(updatedPolygon);
    this.polygonsString[key] = updatedPolygonStr;

    try {
      await this.toggleLoading(
        InciInstance.put(
          `incident/${this.props.incidentId}/update-polygons/`,
          { polygons: JSON.stringify(this.polygonsString) },
          this.getAuthHeader()
        )
      );
    } catch (error) {
      console.log("Update polygons API error");
      // this.setState({ error: error, show: true})
    }
  };

  deletePolygon = async (polygon, key) => {
    polygon.setMap(null);
    try {
      await this.toggleLoading(
        InciInstance.delete(
          `incident/${this.props.incidentId}/delete-polygons/`,
          { data: { polygonInfo: { [key]: this.polygonsString[key] } } },
          this.getAuthHeader()
        )
      );
    } catch (error) {
      console.log("Delete polygons API error");
      // this.setState({ error: error, show: true})
    }
  };

  render() {
    console.log("GPS STATE", this.state.gps);
    console.log("ROLE", this.props.security.user.incidentRole);
    return (
      <div id="cisMapContainer" className="col-lg-12 container-box mb-0">
        <div className="row">
          <div className="col">
            <div className="button-container-row button-box">
              {this.props.type !== "highCommander" && (
                <div className="col text-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic-polygon" size="sm">
                      Area Colors
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {this.polygonColorOptions.map((color) => (
                        <Dropdown.Item
                          id={`dropdown-item-${color}`}
                          key={color}
                          onClick={() => this.colorOptions(color)}
                        >
                          {color.toUpperCase()}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              {this.props.type !== "highCommander" && (
                <div className="col text-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic-marker" size="sm">
                      Marker Options
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {this.markerOptions.map((marker) => (
                        <Dropdown.Item key={marker} onClick={() => this.chooseMarker(marker)}>
                          {marker.toUpperCase()}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {this.state.loading === true ? (
              <div id="cisMapModalContainer">
                <div id="CisMapModal">
                  <Spinner />
                </div>
              </div>
            ) : (
              <div />
            )}
            <div id="OfficialMap" />
          </div>
        </div>
      </div>
    );
  }
}

// export default CisMapOfficial;
const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps)(CisMapOfficial);
