import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import MessageList from "./MessageList";
import SendMessageForm from "./SendMessageForm";
import RoomList from "./RoomList";
import jwt_decode from "jwt-decode";
import { InciInstance, UserInstance, ChatHistoryInstance } from "../../config";

class ChatApp extends Component {
  constructor() {
    super();
    this.state = {
      messages: [],
      joinableRooms: [],
      joinedRooms: [],
      roomId: "",
      currentRoom: null,
    };
  }
  componentDidMount = () => {
    const jwt = window.localStorage.getItem("jwtToken");
    const getIncidentId = jwt_decode(jwt);

    this.getRooms(getIncidentId.incidentId, getIncidentId.user_id);
  };

  IncidentWebSocketCreator = () => {
    const jwt = window.localStorage.getItem("jwtToken");
    const getIncidentId = jwt_decode(jwt);

    let incidentWebSocket = new WebSocket(
      `ws://167.71.60.27:2020/ws/messages/incident/${getIncidentId.incidentId}`,
      ["access_token", `${jwt}`]
    );

    return incidentWebSocket;
  };

  UserWebSocketCreator = (userName) => {
    const jwt = window.localStorage.getItem("jwtToken");
    let userWebSocket = new WebSocket(`ws://167.71.60.27:2020/ws/messages/user/${userName}`, [
      "access_token",
      `${jwt}`,
    ]);

    return userWebSocket;
  };
  subscribeToRoom = (roomName, roomId) => {
    const jwt = window.localStorage.getItem("jwtToken");
    const getIncidentId = jwt_decode(jwt);
    this.setState({ messages: [] });

    if (this.state.currentRoom !== null) {
      this.state.currentRoom.close();
    }

    if (roomName === "Incident") {
      let ws = this.IncidentWebSocketCreator();
      this.setState({ currentRoom: ws });
      ws.onopen = async () => {
        this.setState({
          roomId: getIncidentId.incidentId,
        });
        let messageHistory = await ChatHistoryInstance.get(`incident/${roomId}`);
        if (messageHistory !== null) {
          this.setState({ messages: [] });

          messageHistory.data.forEach((message) => {
            this.setState({
              messages: [
                ...this.state.messages,
                { message: message.message, username: message.username },
              ],
            });
          });
        }
      };
      ws.onmessage = (event) => {
        this.setState({ messages: [...this.state.messages, JSON.parse(event.data)] });
      };
    } else if (roomName !== "Incident") {
      let ws = this.UserWebSocketCreator(roomName);

      this.setState({ currentRoom: ws });

      ws.onopen = async () => {
        this.setState({
          roomId: roomId,
        });
        let messageHistory = await ChatHistoryInstance.get(`user/${roomName}`);
        if (messageHistory !== null) {
          this.setState({ messages: [] });
          messageHistory.data.forEach((message) => {
            this.setState({
              messages: [
                ...this.state.messages,
                { message: message.message, username: message.username },
              ],
            });
          });
        }
      };

      ws.onmessage = (event) => {
        console.log(event.data);
        this.setState({ messages: [...this.state.messages, JSON.parse(event.data)] });
      };
    }
  };
  createRoom = (name) => {};
  getRooms = async (incidentId, userId) => {
    const jwt = window.localStorage.getItem("jwtToken");
    const getRole = jwt_decode(jwt);
    if (getRole.user_roles[0] === "HIGH_COMMANDER") {
      let psaps = await InciInstance.get("incident/get-psaps/");
      psaps.data.data.users.forEach((user) => {
        this.setState({
          joinableRooms: [
            ...this.state.joinableRooms,
            {
              id: user.details.id,
              name: user.username,
              lastName: user.lastName,
            },
          ],
        });
      });
    } else if (getRole.incidentRole === "PSAP") {
      let incidentInfo = await InciInstance.get(`incident/${incidentId}`);
      let userIds = { ids: incidentInfo.data.data.dispatched_users };
      let availableUsers = await UserInstance.post(`user/user-ids/`, userIds);
      availableUsers.data.data.users.forEach((user) => {
        if (user.id !== userId) {
          this.setState({
            joinableRooms: [
              ...this.state.joinableRooms,
              {
                id: user.details.id,
                name: user.username,
                lastName: user.lastName,
              },
            ],
          });
        }
      });
      this.setState({
        joinableRooms: [
          ...this.state.joinableRooms,
          { id: incidentId, name: "Incident", lastName: "Incident" },
          { id: "*", name: "concorde_admin", lastName: "High Command" },
        ],
      });
    } else {
      let incidentInfo = await InciInstance.get(`incident/${incidentId}`);
      let userIds = { ids: incidentInfo.data.data.dispatched_users };
      let availableUsers = await UserInstance.post(`user/user-ids/`, userIds);
      availableUsers.data.data.users.forEach((user) => {
        if (user.id !== userId) {
          this.setState({
            joinableRooms: [
              ...this.state.joinableRooms,
              {
                id: user.details.id,
                name: user.username,
                lastName: user.lastName,
              },
            ],
          });
        }
      });
      this.setState({
        joinableRooms: [
          ...this.state.joinableRooms,
          { id: incidentId, name: "Incident", lastName: "Incident" },
        ],
      });
    }
  };
  sendMessage = (currentRoom, text) => {
    const jwt = window.localStorage.getItem("jwtToken");
    currentRoom.send(JSON.stringify({ message: text }), ["access_token", `${jwt}`]);
  };
  render() {
    return (
      // Close connections on window close
      <Modal show={this.props.show} onHide={this.props.isClosed} size="xl" id="chat-modal">
        <Modal.Header closeButton>
          <Modal.Title>Chat App</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="chatApp">
            <RoomList
              roomId={this.state.roomId}
              subscribeToRoom={this.subscribeToRoom}
              rooms={[...this.state.joinableRooms, ...this.state.joinedRooms]}
            />
            <MessageList roomId={this.state.roomId} messages={this.state.messages} />
            <SendMessageForm
              disabled={!this.state.roomId}
              sendMessage={this.sendMessage}
              currentRoom={this.state.currentRoom}
            />
            {/* <NewRoomForm createRoom={this.createRoom} /> */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ChatApp;
