import TopLeftChart from './TopLeftChart'
import TopRightChart from './TopRightChart'
import BottomLeftChart from './BottomLeftChart'
import React, { Component } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { AnalInstance, AnalAPIKey } from '../../../config'
import ErrorModal from '../../../components/UI/Modal/ErrorModal'

class AnalyticsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      yearChosen: 'Year',
      regionChosen: 'Region',
      show: false,
      error: {},
    }
  }

  analyticsData = null
  componentDidMount = async () => {
    const authentication = {
      headers: {
        'Api-Key': AnalAPIKey,
      },
    }
    try {
      const analytics = await AnalInstance.get('', authentication)
      this.analyticsData = analytics.data
    } catch (error) {
      console.log('Get analytics API error')
      this.setState({ error: error, show: true })
      console.log('analytics', this.state.error)
    }
    this.yearDropdownOptions()
  }

  yearOptions = []
  yearDropdownOptions = () => {
    if (this.analyticsData !== null) {
      for (let year in this.analyticsData) {
        this.yearOptions.push(
          <Dropdown.Item key={year} id={year} onClick={this.handleYear}>
            {year}
          </Dropdown.Item>
        )
      }
      return this.yearOptions
    } else {
      return <div></div>
    }
  }

  handleYear = (event) => {
    this.setState({ yearChosen: event.target.id })
  }

  regionOptions = []
  regionDropdownOptions = () => {
    if (this.yearOptions !== []) {
      for (let regionData in this.analyticsData[this.state.yearChosen]) {
        this.regionOptions.push(
          <Dropdown.Item key={Math.random()} id={regionData} onClick={this.handleRegion}>
            {regionData}
          </Dropdown.Item>
        )
      }
      return this.regionOptions
    } else {
      return <div></div>
    }
  }

  handleRegion = (event) => {
    this.setState({ regionChosen: event.target.id })
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  render() {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-sm'>
            <h1 className='display-4 text-center'>Incident Analytics</h1>
            <hr />
          </div>
        </div>
        <div className='row'>
          {/* <ErrorModal
            onHide={this.handleClose}
            handleClose={this.handleClose}
            show  ={this.state.show}
            error = {this.state.error}
        /> */}
          <div className='col-sm text-right'>
            <Dropdown>
              <Dropdown.Toggle size='sm' variant='primary' id='dropdown-basic-year'>
                {this.state.yearChosen}
              </Dropdown.Toggle>
              <Dropdown.Menu>{this.yearOptions}</Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='col-sm text-left'>
            {this.state.yearChosen !== 'Year' ? (
              <Dropdown>
                <Dropdown.Toggle size='sm' variant='primary' id='dropdown-basic-region'>
                  {this.state.regionChosen}
                </Dropdown.Toggle>
                <Dropdown.Menu id='regionDropdown'>{this.regionDropdownOptions()}</Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown>
                <Dropdown.Toggle size='sm' variant='primary' id='dropdown-basic-region'>
                  {this.state.regionChosen}
                </Dropdown.Toggle>
                <Dropdown.Menu></Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <div className='row' style={{ marginTop: '10%', marginRight: '2%' }}>
          <div className='col-sm'>
            {this.state.regionChosen !== 'Region' ? (
              <TopLeftChart
                data={this.analyticsData[this.state.yearChosen][this.state.regionChosen]}
              />
            ) : (
              <div></div>
            )}
          </div>
          <div className='col-sm'>
            {this.state.regionChosen !== 'Region' ? (
              <TopRightChart
                data={this.analyticsData[this.state.yearChosen][this.state.regionChosen]}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className='row' style={{ marginTop: '25%', marginRight: '2%' }}>
          <div className='col-sm-7' style={{ marginLeft: '22%' }}>
            {this.state.regionChosen !== 'Region' ? (
              <BottomLeftChart
                data={this.analyticsData[this.state.yearChosen][this.state.regionChosen]}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default AnalyticsPage
