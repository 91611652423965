import React, { Component } from "react";

import Hospital from "./AvailableOrganizations/Hospital";
import EMS from "./AvailableOrganizations/EmergencyMedicalServices";
// import Other from "./Other";
// import HigherCommand from "./HigherCommand";

class registerOrganization extends Component {
  state = {
    options: [
      {
        name: "Select Organization",
        value: null,
      },
      {
        name: "Hospitals",
        value: "Hospitals",
      },
      {
        name: "EMS",
        value: "EMS",
      },
      // {
      //   name: "Higher Command",
      //   value: "Higher Command",
      // },
      // {
      //   name: "Other",
      //   value: "Other",
      // },
    ],
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  render() {
    const { options, value } = this.state;

    let registerOrganizationSelector = () => {
      switch (value) {
        case "Hospitals":
          return <Hospital history={this.props.history} />;
        case "EMS":
          return <EMS history={this.props.history} />;
        // case "Higher Command":
        //   return <HigherCommand history={this.props.history} />;
        // case "Other":
        //   return <Other history={this.props.history} />;
        default:
          return <div />;
      }
    };

    return (
      <div>
        <h1 className="text-center">Select the Organization you want to Register</h1>
        <br />
        <div className="text-center">
          <select
            onChange={this.handleChange}
            value={value}
            data-test="org-reg-select"
            className="custom-select select-dropdown custom-select-lg w-50"
          >
            {options.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div>{registerOrganizationSelector()}</div>
        <div />
      </div>
    );
  }
}

export default registerOrganization;
