import classnames from "classnames";
import React, { Component } from "react";
import { InciInstance, GoogleAPIKey } from "../../../../../config";
import Select from "react-select";
import { Link } from "react-router-dom";
import Spinner from "../../../../../components/UI/Spinner/Spinner";

import Geocode from "react-geocode";
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";

Geocode.setApiKey(GoogleAPIKey);
Geocode.enableDebug();

class IncidentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",

      // Basic Incident details
      name: "",
      date: "",
      time: "",
      incidentStatusCode: "",
      description: "",

      //Basic Caller Details
      caller: "",
      callerFreeText: "",
      callerContact: "",
      callerContactDetails: "",

      //Emergency Type
      typeOfEmergency: "",
      additionalInformation: "",

      //Number of Patients
      numberOfAdultPatients: "",
      numberOfChildren: "",
      numberOfFatalities: "",
      patientsAdditionalInformation: "",

      //Location
      location: "",
      lat: "",
      lng: "",
      areaType: "",

      //Hazard Type
      hazards: [],
      hazardBackground: "",

      //Activity

      isError: true,

      valName: "",
      valDate: "",
      valDescription: "",
      valCaller: "",
      valincidentStatusCode: "",
      valCallerContact: "",
      valCallerDescription: "",
      valTypeOfEmergency: "",
      valHazards: "",
      valLocation: "",
      valAreaType: "",
      valNumberOfAdultPatients: "",
      valNumberofChildren: "",
      valNumberOfFatalities: "",
      valCallerContactDetails: "",

      prePopulate: false,
      newIncident: null,
      loading: false,
      show: false,
      error: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onClickDispatch = this.onClickDispatch.bind(this);
  }

  onClickDispatch(e) {
    if (this.props.location.populate === true) {
      this.props.history.push({
        pathname: "/DispatchTable",
        newIncident: {
          name: this.state.name,
          incidentTimeOfAnnouncement: this.state.date + " " + this.state.time,
          incidentStatusCode: this.state.incidentStatusCode,
          description: this.state.description,

          //Basic Caller Details
          caller: this.state.caller,
          callerFreeText: this.state.callerFreeText,
          callerContact: this.state.callerContact,
          callerContactDetails: this.state.callerContactDetails,

          //Emergency Type
          typeOfEmergency: this.state.typeOfEmergency,
          additionalInformation: this.state.additionalInformation,

          //Number of Patients
          numberOfAdultPatients: this.state.numberOfAdultPatients,
          numberOfChildren: this.state.numberOfChildren,
          numberOfFatalities: this.state.numberOfFatalities,

          //GEOLOCATION
          location: this.state.location,
          lat: this.state.lat,
          lng: this.state.lng,
          areaType: this.state.areaType,
          //Hazard Type
          hazards: this.state.hazards.map((value) => value.value),
          hazardBackground: this.state.hazardBackground,
        },
        id: this.props.location.prePopulate.id,
        origin: "RegisterIncidentEdit",
      });
    } else if (
      this.props.location.populate !== true ||
      this.props.history.updatePsapAndEms !== null
    ) {
      this.props.history.push({
        pathname: "/DispatchTable",
        newIncident: {
          name: this.state.name,
          incidentTimeOfAnnouncement: this.state.date + " " + this.state.time,
          incidentStatusCode: this.state.incidentStatusCode,
          description: this.state.description,

          //Basic Caller Details
          caller: this.state.caller,
          callerFreeText: this.state.callerFreeText,
          callerContact: this.state.callerContact,
          callerContactDetails: this.state.callerContactDetails,

          //Emergency Type
          typeOfEmergency: this.state.typeOfEmergency,
          additionalInformation: this.state.additionalInformation,

          //Number of Patients
          numberOfAdultPatients: this.state.numberOfAdultPatients,
          numberOfChildren: this.state.numberOfChildren,
          numberOfFatalities: this.state.numberOfFatalities,

          //Location

          location: this.state.location,
          lat: this.state.lat,
          lng: this.state.lng,
          areaType: this.state.areaType,

          //Hazard Type
          hazards: this.state.hazards.map((value) => value.value),
          hazardBackground: this.state.hazardBackground,
        },
        origin: "RegisterIncident",
        updatePsapAndEms: this.props.history.location.updatePsapAndEms,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.state.location)
      Geocode.fromAddress(this.state.location)
        .then((response) => {
          const { lat, lng } = response.results[0].geometry.location;
          this.setState({
            lat: lat,
            lng: lng,
          });
        })
        .catch((error) => (error.message.includes("ZERO_RESULTS") ? null : console.log(error)));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // close error modal
  handleClose = () => {
    this.setState({ show: false });
  };

  handleChangeHazards = async (value) => {
    await this.setState({ hazards: value });
  };

  // Check input fields for errors
  validate = () => {
    let isError = false;

    if (this.state.name.length < 5 || this.state.name.length > 100) {
      isError = true;
      this.setState({ valName: "Name must be between 5 and 100 characters" });
    }

    if (!this.state.date) {
      isError = true;
      this.setState({ valDate: "Incident date is required." });
    }

    if (!this.state.time) {
      isError = true;
      this.setState({ valTime: "Incident time is required." });
    }

    if (this.state.description === "") {
      isError = true;
      this.setState({
        valDescription: "Please add an incident description",
      });
    }
    if (this.state.incidentStatusCode === "") {
      isError = true;
      this.setState({
        valincidentStatusCode: "Incident Status Code must be chosen",
      });
    }
    if (this.state.callerContact === "") {
      isError = true;
      this.setState({
        valCallerContact: "Caller Contact must be chosen",
      });
    }
    if (this.state.callerFreeText === "") {
      isError = true;
      this.setState({
        valCallerDescription: "Caller description needed",
      });
    }
    if (this.state.callerContactDetails === "") {
      isError = true;
      this.setState({
        valCallerContactDetails: "Please add caller contact details",
      });
    }
    if (this.state.caller === "") {
      isError = true;
      this.setState({
        valCaller: "Caller Relationship must be chosen",
      });
    }
    if (this.state.typeOfEmergency === "") {
      isError = true;
      this.setState({
        valTypeOfEmergency: "Type of Emergency must be chosen",
      });
    }
    if (this.state.hazards.length === 0) {
      isError = true;
      this.setState({
        valHazards: "Hazard Type must be chosen",
      });
    }
    if (this.state.location === "") {
      isError = true;
      this.setState({
        valLocation: "A Location must be chosen",
      });
    }
    if (this.state.areaType === "") {
      isError = true;
      this.setState({
        valAreaType: "An Area Type must be chosen",
      });
    }
    if (
      !this.state.numberOfAdultPatients ||
      isNaN(this.state.numberOfAdultPatients) ||
      this.state.numberOfAdultPatients.length > 1000
    ) {
      isError = true;
      this.setState({
        valNumberOfAdultPatients:
          "Please enter a valid number of adult casualties between 0 and 1000",
      });
    }
    if (
      !this.state.numberOfChildren ||
      isNaN(this.state.numberOfChildren) ||
      this.state.numberOfChildren.length > 1000
    ) {
      isError = true;

      this.setState({
        valNumberofChildren:
          "Please enter a valid number of children casualties between 0 and 1000",
      });
    }
    if (
      !this.state.numberOfFatalities ||
      isNaN(this.state.numberOfFatalities) ||
      this.state.numberOfFatalities.length > 1000
    ) {
      isError = true;
      this.setState({
        valNumberOfFatalities: "Please enter a valid number of fatalities between 0 and 1000",
      });
    }

    return isError;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      valName: "",
      valDate: "",
      valTime: "",
      valCaller: "",
      valincidentStatusCode: "",
      valCallerContact: "",
      valTypeOfEmergency: "",
      valHazards: "",
      valLocation: "",
      valAreaType: "",
      valNumberOfAdultPatients: "",
      valNumberofChildren: "",
      valNumberOfFatalities: "",
      valCallerDescription: "",
      valDescription: "",
      valCallerContactDetails: "",
    });
    try {
      const valid = this.validate();
      if (!valid) {
        this.setState({ loading: true });
        const newIncident = {
          name: this.state.name,
          incidentTimeOfAnnouncement: this.state.date + " " + this.state.time,
          incidentStatusCode: this.state.incidentStatusCode,
          description: this.state.description,

          //Basic Caller Details
          caller: this.state.caller,
          callerFreeText: this.state.callerFreeText,
          callerContact: this.state.callerContact,
          callerContactDetails: this.state.callerContactDetails,

          //Emergency Type
          typeOfEmergency: this.state.typeOfEmergency,
          additionalInformation: this.state.additionalInformation,

          //Number of Patients
          numberOfAdultPatients: this.state.numberOfAdultPatients,
          numberOfChildren: this.state.numberOfChildren,
          numberOfFatalities: this.state.numberOfFatalities,

          //GEOLOCATION
          location: this.state.location,
          lat: this.state.lat,
          lng: this.state.lng,
          areaType: this.state.areaType,
          //Hazard Type
          hazardtype_set: this.state.hazards.map((value) => value.value),
          hazardBackground: this.state.hazardBackground,

          //nullables from backend refactoring
          priorityDispatchCard: "ABDOMINAL_PAIN_PROBLEMS",
          priorityDispatchCode: "IMMEDIATE",
          eventLogs: true,
        };

        //If the form has prepopulate then patch else post
        if (this.state.prePopulate === true) {
          await InciInstance.patch(`incident/${this.state.id}/`, newIncident);
          await InciInstance.put(`incident/${this.state.id}/update-dispatched/`, {
            users: this.props.location.updatePsapAndEms,
          });
        } else {
          await InciInstance.post("incident/", newIncident);
          try {
            let incidentIdFinder = await InciInstance.get("incident/");

            for (let i = 0; i < incidentIdFinder.data.data.length; i++) {
              // Check
              if (incidentIdFinder.data.data[i].name === newIncident.name) {
                this.setState({ id: incidentIdFinder.data.data[i].id });
              }
            }
          } catch (error) {
            console.log("incident identification error");
            this.setState({ error: error, show: true });
          }
          try {
            if (this.props.location.updatePsapAndEms) {
              await InciInstance.put(`incident/${this.state.id}/update-dispatched/`, {
                users: this.props.location.updatePsapAndEms,
              });
            }
          } catch (error) {
            console.log("Update dispatch members error");
            this.setState({ error: error, show: true });
          }
        }
        this.props.history.push({ pathname: "/IncidentMGT" });
      }

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false, error: error, show: true });
      console.log("Register Incident API error");
    }
  };

  // Convert value list to label value list
  converthazardList = (hazardValuesList) => {
    const hazardsLabelsList = [
      { label: "Access", value: "ACCESS" },
      { label: "Animal Hazard to Traffic", value: "ANIMAL_HAZARD_TO_TRAFFIC" },
      {
        label: "Building Under Construction/Demolition",
        value: "BUILDING_UNDER_CONSTRUCTION_DEMOLITION",
      },
      { label: "Chemical Exposure", value: "CHEMICAL_EXPOSURE" },
      { label: "Confined Space", value: "CONFINED_SPACE" },
      { label: "Contamination", value: "CONTAMINATION" },
      { label: "Corrosives", value: "CORROSIVES" },
      { label: "Crush Injuries", value: "CRUSH_INJURIES" },
      { label: "Dangerous Animal", value: "DANGEROUS_ANIMAL" },
      { label: "Dangerous Crowd Density", value: "DANGEROUS_CROWD_DENSITY" },
      { label: "Deep Water", value: "DEEP_WATER" },
      { label: "Disorder", value: "DISORDER" },
      { label: "Disorder with Firearms", value: "DISORDER_WITH_FIREARMS" },
      { label: "Edged Weapons Known/Suspected", value: "EDGED_WEAPONS_KNOWN_SUSPECTED" },
      { label: "Explosion", value: "EXPLOSION" },
      { label: "Fallen and Loose Debris", value: "FALLEN_AND_LOOSE_DEBRIS" },
      { label: "Fallen Trees", value: "FALLEN_TREES" },
      { label: "Fast Flowing Water", value: "FAST_FLOWING_WATER" },
      { label: "Fire", value: "FIRE" },
      { label: "Firearms Involved", value: "FIREARMS_INVOLVED" },
      { label: "Firearms Known", value: "FIREAMRS_KNOWN" },
      { label: "Flammable Gas Exposure", value: "FLAMMABLE_GAS_EXPOSURE" },
      { label: "Flammable liquids", value: "FLAMMABLE_LIQUIDS" },
      { label: "Flammable Solids", value: "FLAMMABLE_SOLIDS" },
      { label: "Fuel Spill or Leak", value: "FUEL_SPILL_OR_LEAK" },
      { label: "Gas Leak", value: "GAS_LEAK" },
      { label: "Gases", value: "GASES" },
      { label: "Hazardous Cargo", value: "HAZARDOUS_CARGO" },
      { label: "High Winds", value: "HIGH_WINDS" },
      { label: "Ice", value: "ICE" },
      { label: "I.E.D", value: "IED" },
      { label: "Live Rail or Overhead Lines", value: "LIVE_RAIL_OR_OVERHEAD_LINES" },
      { label: "Loose or Unstable Structure", value: "LOOSE_OR_UNSTABLE_STRUCTURE" },
      { label: "Mud", value: "MUD" },
      { label: "Multiple Heat/Stress Casualties", value: "MULTIPLE_HEAT_STRESS_CASUALTIES" },
      { label: "Multiple Hypothermia", value: "MULTIPLE_HYPOTHERMIA" },
      { label: "Multiple Seats of Fire", value: "MULTIPLE_SEATS_OF_FIRE" },
      { label: "Multiple Vehicles", value: "MULTIPLE_VEHICLES" },
      { label: "Oxidising Substances", value: "OXIDISING_SUBSTANCES" },
      { label: "Persons Trapped", value: "PERSONS_TRAPPED" },
      { label: "Petrochemicals", value: "PETROCHEMICALS" },
      { label: "Power Lines Down", value: "POWER_LINES_DOWN" },
      { label: "Quicksand", value: "QUICKSAND" },
      { label: "Radioactive Material", value: "RADIOACTIVE_MATERIAL" },
      { label: "Rising Water Tide", value: "RISING_WATER_TIDE" },
      { label: "Secondary Devices", value: "SECONDARY_DEVICES" },
      { label: "Serious Disorder", value: "SERIOUS_DISORDER" },
      { label: "Sinking", value: "SINKING" },
      { label: "Smoke", value: "SMOKE" },
      { label: "Smoke Fumes", value: "SMOKE_FUMES" },
      { label: "Standing Water", value: "STANDING_WATER" },
      { label: "Still Water", value: "STILL_WATER" },
      { label: "Sub Surface Hazard", value: "SUB_SURFACE_HAZARD" },
      { label: "Swift Water", value: "SWIFT_WATER" },
      { label: "Toxic and Infectious Substances", value: "TOXIC_AND_INFECTIOUS_SUBSTANCES" },
      {
        label: "Trespassers on Electrical Installations",
        value: "TRESPASSERS_ON_ELECTRICAL_INSTALLATIONS",
      },
      { label: "Unstable Surface", value: "UNSTABLE_SURFACE" },
      { label: "Unstable Vehicle", value: "UNSTABLE_VEHICLE" },
      { label: "Unstable Vessel", value: "UNSTABLE_VESSEL" },
      { label: "Unstable Wreckage", value: "UNSTABLE_WRECKAGE" },
      { label: "Violence Towards Public", value: "VIOLENCE_TOWARD_PUBLIC" },
      { label: "Working at Height", value: "WORKING_AT_HEIGHT" },
      { label: "Working Sub-Surface", value: "WORKING_SUB_SURFACE" },
    ];

    let prepopulateHazardList = [];
    for (let i = 0; i < hazardValuesList.length; i++) {
      for (let k = 0; k < hazardsLabelsList.length; k++) {
        if (hazardValuesList[i] === hazardsLabelsList[k].value) {
          prepopulateHazardList.push(hazardsLabelsList[k]);
        }
      }
    }
    return prepopulateHazardList;
  };
  // Set the prepopulate values to the state
  componentDidMount = () => {
    if (this.props.location.populate) {
      var index = this.props.location.prePopulate.incidentTimeOfAnnouncement.indexOf("T");
      try {
        this.setState({
          id: this.props.location.prePopulate.id,
          name: this.props.location.prePopulate.name,
          incidentStatusCode: this.props.location.prePopulate.incidentStatusCode,
          date: this.props.location.prePopulate.incidentTimeOfAnnouncement.substr(0, index),
          time: new Date(
            this.props.location.prePopulate.incidentTimeOfAnnouncement.substr(0, index) +
              "T" +
              this.props.location.prePopulate.incidentTimeOfAnnouncement.substr(index + 1)
          ).toLocaleTimeString(
            {},
            { timeZone: "UTC", hour12: false, hour: "numeric", minute: "numeric" }
          ),
          description: this.props.location.prePopulate.description,

          caller: this.props.location.prePopulate.caller,
          callerFreeText: this.props.location.prePopulate.callerFreeText,
          callerContact: this.props.location.prePopulate.callerContact,
          callerContactDetails: this.props.location.prePopulate.callerContactDetails,

          typeOfEmergency: this.props.location.prePopulate.typeOfEmergency,
          additionalInformation: this.props.location.prePopulate.additionalInformation,

          numberOfAdultPatients: this.props.location.prePopulate.numberOfAdultPatients,
          numberOfChildren: this.props.location.prePopulate.numberOfChildren,
          numberOfFatalities: this.props.location.prePopulate.numberOfFatalities,

          location: this.props.location.prePopulate.location,
          areaType: this.props.location.prePopulate.areaType,

          hazards: this.converthazardList(this.props.location.prePopulate.hazardtype_set),
          hazardBackground: this.props.location.prePopulate.hazardBackground,

          prePopulate: true,
        });
      } catch (error) {
        console.log("Prepopulation error");
        this.setState({ error: error, show: true });
      }
    } else if (this.props.location.newIncident) {
      var index1 = this.props.location.newIncident.incidentTimeOfAnnouncement.indexOf(" ");
      this.setState({
        id: this.props.location.id,
        name: this.props.location.newIncident.name,
        incidentStatusCode: this.props.location.newIncident.incidentStatusCode,
        date: this.props.location.newIncident.incidentTimeOfAnnouncement.substr(0, index1),
        time: this.props.location.newIncident.incidentTimeOfAnnouncement.substr(index1 + 1),

        description: this.props.location.newIncident.description,

        caller: this.props.location.newIncident.caller,
        callerFreeText: this.props.location.newIncident.callerFreeText,
        callerContact: this.props.location.newIncident.callerContact,
        callerContactDetails: this.props.location.newIncident.callerContactDetails,

        typeOfEmergency: this.props.location.newIncident.typeOfEmergency,
        additionalInformation: this.props.location.newIncident.additionalInformation,

        numberOfAdultPatients: this.props.location.newIncident.numberOfAdultPatients,
        numberOfChildren: this.props.location.newIncident.numberOfChildren,
        numberOfFatalities: this.props.location.newIncident.numberOfFatalities,

        location: this.props.location.newIncident.location,
        areaType: this.props.location.newIncident.areaType,

        hazards: this.converthazardList(this.props.location.newIncident.hazards),
        hazardBackground: this.props.location.newIncident.hazardBackground,
      });
    }
    if (this.props.location.newIncident && this.props.location.populate) {
      this.setState({ prePopulate: true });
    }
  };

  render() {
    const hazards = [
      { label: "Access", value: "ACCESS" },
      { label: "Animal Hazard to Traffic", value: "ANIMAL_HAZARD_TO_TRAFFIC" },
      {
        label: "Building Under Construction/Demolition",
        value: "BUILDING_UNDER_CONSTRUCTION_DEMOLITION",
      },
      { label: "Chemical Exposure", value: "CHEMICAL_EXPOSURE" },
      { label: "Confined Space", value: "CONFINED_SPACE" },
      { label: "Contamination", value: "CONTAMINATION" },
      { label: "Corrosives", value: "CORROSIVES" },
      { label: "Crush Injuries", value: "CRUSH_INJURIES" },
      { label: "Dangerous Animal", value: "DANGEROUS_ANIMAL" },
      { label: "Dangerous Crowd Density", value: "DANGEROUS_CROWD_DENSITY" },
      { label: "Deep Water", value: "DEEP_WATER" },
      { label: "Disorder", value: "DISORDER" },
      { label: "Disorder with Firearms", value: "DISORDER_WITH_FIREARMS" },
      { label: "Edged Weapons Known/Suspected", value: "EDGED_WEAPONS_KNOWN_SUSPECTED" },
      { label: "Explosion", value: "EXPLOSION" },
      { label: "Fallen and Loose Debris", value: "FALLEN_AND_LOOSE_DEBRIS" },
      { label: "Fallen Trees", value: "FALLEN_TREES" },
      { label: "Fast Flowing Water", value: "FAST_FLOWING_WATER" },
      { label: "Fire", value: "FIRE" },
      { label: "Firearms Involved", value: "FIREARMS_INVOLVED" },
      { label: "Firearms Known", value: "FIREAMRS_KNOWN" },
      { label: "Flammable Gas Exposure", value: "FLAMMABLE_GAS_EXPOSURE" },
      { label: "Flammable liquids", value: "FLAMMABLE_LIQUIDS" },
      { label: "Flammable Solids", value: "FLAMMABLE_SOLIDS" },
      { label: "Fuel Spill or Leak", value: "FUEL_SPILL_OR_LEAK" },
      { label: "Gas Leak", value: "GAS_LEAK" },
      { label: "Gases", value: "GASES" },
      { label: "Hazardous Cargo", value: "HAZARDOUS_CARGO" },
      { label: "High Winds", value: "HIGH_WINDS" },
      { label: "Ice", value: "ICE" },
      { label: "I.E.D", value: "IED" },
      { label: "Live Rail or Overhead Lines", value: "LIVE_RAIL_OR_OVERHEAD_LINES" },
      { label: "Loose or Unstable Structure", value: "LOOSE_OR_UNSTABLE_STRUCTURE" },
      { label: "Mud", value: "MUD" },
      { label: "Multiple Heat/Stress Casualties", value: "MULTIPLE_HEAT_STRESS_CASUALTIES" },
      { label: "Multiple Hypothermia", value: "MULTIPLE_HYPOTHERMIA" },
      { label: "Multiple Seats of Fire", value: "MULTIPLE_SEATS_OF_FIRE" },
      { label: "Multiple Vehicles", value: "MULTIPLE_VEHICLES" },
      { label: "Oxidising Substances", value: "OXIDISING_SUBSTANCES" },
      { label: "Persons Trapped", value: "PERSONS_TRAPPED" },
      { label: "Petrochemicals", value: "PETROCHEMICALS" },
      { label: "Power Lines Down", value: "POWER_LINES_DOWN" },
      { label: "Quicksand", value: "QUICKSAND" },
      { label: "Radioactive Material", value: "RADIOACTIVE_MATERIAL" },
      { label: "Rising Water Tide", value: "RISING_WATER_TIDE" },
      { label: "Secondary Devices", value: "SECONDARY_DEVICES" },
      { label: "Serious Disorder", value: "SERIOUS_DISORDER" },
      { label: "Sinking", value: "SINKING" },
      { label: "Smoke", value: "SMOKE" },
      { label: "Smoke Fumes", value: "SMOKE_FUMES" },
      { label: "Standing Water", value: "STANDING_WATER" },
      { label: "Still Water", value: "STILL_WATER" },
      { label: "Sub Surface Hazard", value: "SUB_SURFACE_HAZARD" },
      { label: "Swift Water", value: "SWIFT_WATER" },
      { label: "Toxic and Infectious Substances", value: "TOXIC_AND_INFECTIOUS_SUBSTANCES" },
      {
        label: "Trespassers on Electrical Installations",
        value: "TRESPASSERS_ON_ELECTRICAL_INSTALLATIONS",
      },
      { label: "Unstable Surface", value: "UNSTABLE_SURFACE" },
      { label: "Unstable Vehicle", value: "UNSTABLE_VEHICLE" },
      { label: "Unstable Vessel", value: "UNSTABLE_VESSEL" },
      { label: "Unstable Wreckage", value: "UNSTABLE_WRECKAGE" },
      { label: "Violence Towards Public", value: "VIOLENCE_TOWARD_PUBLIC" },
      { label: "Working at Height", value: "WORKING_AT_HEIGHT" },
      { label: "Working Sub-Surface", value: "WORKING_SUB_SURFACE" },
    ];
    let form = (
      <form onSubmit={this.onSubmit}>
        <h6>
          <b>Incident Details</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valName,
              })}
              placeholder="Name"
              name="name"
              value={this.state.name}
              onChange={this.onChange}
            />
            {this.state.valName && <div className="invalid-feedback">{this.state.valName}</div>}
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valDate,
              })}
              name="date"
              value={this.state.date}
              onChange={this.onChange}
            />
            {this.state.valDate && <div className="invalid-feedback">{this.state.valDate}</div>}
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="time">Time</label>
            <input
              type="time"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valTime,
              })}
              name="time"
              value={this.state.time}
              onChange={this.onChange}
            />
            {this.state.valTime && <div className="invalid-feedback">{this.state.valTime}</div>}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="incidentStatusCode">Incident Status Code</label>
            <select
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valincidentStatusCode,
              })}
              name="incidentStatusCode"
              onChange={this.onChange}
              value={this.state.incidentStatusCode}
            >
              <option value="">Choose an Incident Status Code</option>
              <option value="MAJOR_INCIDENT_ALERT_STANDBY">MAJOR INCIDENT ALERT / STANDBY</option>
              <option value="MAJOR_INCIDENT_CONFIRMED_DECLARED">
                MAJOR INCIDENT CONFIRMED / DECLARED
              </option>
              <option value="MAJOR_INCIDENT_CANCEL">MAJOR INCIDENT CANCEL</option>
              <option value="AMBULANCE_MAJOR_INCIDENT_STOP">AMBULANCE MAJOR INCIDENT STOP</option>
              <option value="AMBULANCE_MAJOR_INCIDENT_SCENE_EVACUATION_COMPLETE">
                AMBULANCE MAJOR INCIDENT SCENE EVACUATION COMPLETE
              </option>
              <option value="AMBULANCE_MAJOR_INCIDENT_STAND_DOWN">
                AMBULANCE MAJOR INCIDENT STAND DOWN
              </option>
            </select>
            {this.state.valincidentStatusCode && (
              <div className="invalid-feedback">{this.state.valincidentStatusCode}</div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="description">Incident Description</label>
            <textarea
              type="text"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valDescription,
              })}
              placeholder="Insert An Incident Description Here"
              name="description"
              value={this.state.description}
              onChange={this.onChange}
            />
            {this.state.valDescription && (
              <div className="invalid-feedback">{this.state.valDescription}</div>
            )}
          </div>
        </div>
        <hr color="black" />
        <h6>
          <b>Caller Details</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="caller">Caller Relationship</label>
            <select
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valCaller,
              })}
              name="caller"
              onChange={this.onChange}
              value={this.state.caller}
            >
              <option value="">Choose Caller Relationship</option>
              <option value="PATIENT">Patient</option>
              <option value="RELATED_TO_PATIENT">Related to patient</option>
              <option value="UNRELATED_TO_PATIENT">Unrelated to patient</option>
              <option value="OTHER">Other</option>
            </select>
            {this.state.valCaller && <div className="invalid-feedback">{this.state.valCaller}</div>}
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="callerFreeText">Caller Description</label>
            <textarea
              type="text"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valCallerDescription,
              })}
              placeholder="Insert Caller Description Here"
              name="callerFreeText"
              value={this.state.callerFreeText}
              onChange={this.onChange}
            />
            {this.state.valCallerDescription && (
              <div className="invalid-feedback">{this.state.valCallerDescription}</div>
            )}
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="callerContact">Contact</label>
            <select
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valCallerContact,
              })}
              name="callerContact"
              onChange={this.onChange}
              value={this.state.callerContact}
            >
              <option value="">Choose Caller Contact</option>
              <option value="PHONE">Phone</option>
              <option value="EMAIL">E-mail</option>
            </select>
            {this.state.valCallerContact && (
              <div className="invalid-feedback">{this.state.valCallerContact}</div>
            )}
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="callerContactDetails">Caller Contact Details</label>
            <textarea
              type="text"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valCallerContactDetails,
              })}
              placeholder="Insert Caller Contact Details Here"
              name="callerContactDetails"
              value={this.state.callerContactDetails}
              onChange={this.onChange}
            />
            {this.state.valCallerContactDetails && (
              <div className="invalid-feedback">{this.state.valCallerContactDetails}</div>
            )}
          </div>
        </div>
        <hr color="black" />
        <h6 htmlFor="typeOfEmergency">
          <b>Type of Emergency</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="typeOfEmergency">Emergency Type</label>
            <select
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valTypeOfEmergency,
              })}
              name="typeOfEmergency"
              onChange={this.onChange}
              value={this.state.typeOfEmergency}
            >
              <option value="">Choose a Type of Emergency</option>
              <option value="LANDSLIDE">Landslide</option>
              <option value="EARTHQUAKE">Earthquake</option>
              <option value="TSUNAMI">Tsuname</option>
              <option value="FLOOD">Flood</option>
              <option value="STORM_HURRICANE_TORNADO">Storm/Hurricane/Tornado</option>
              <option value="WINTER_STORM">Winter Storm</option>
              <option value="DISORDER">Disorder</option>
              <option value="UNCONTROLLED_CROWD">Uncontrolled Crowd</option>
              <option value="FIREARMS_INCIDENT">Firearms Incident</option>
              <option value="VIOLENCE">Violence</option>
              <option value="SIEGE">Siege</option>
              <option value="ANIMAL">Animal</option>
              <option value="IN_OR_NEAR_WATER">In or Near Water</option>
              <option value="BUILDING_FIRE">Building Fire</option>
              <option value="VEHICLE_FIRE">Vehicle Fire</option>
              <option value="WILD_FIRE">Wild Fire</option>
              <option value="FIRE_AT_WASTE_SITES">Fire at Waste Sites</option>
              <option value="POLLUTION">Pollution</option>
              <option value="AIR_AIRCRAFT_ACCIDENT">Air/Aircraft Accident</option>
              <option value="MARITIME_SHIP_FERRY_ACCIDENT">Maritime/Ship/Ferry accident</option>
              <option value="RAIL_TRAIN_ACCIDENT">Rail/Trail Accident</option>
              <option value="ROAD_ACCIDENT">Road Accident</option>
              <option value="ELECTRICITY_DAMAGE">Electricity Damage</option>
              <option value="GAS_DAMAGE">Gas Damage</option>
              <option value="WATER_DAMAGE">Water Damage</option>
              <option value="CHEMICAL_ACCIDENT">Chemical Accident</option>
              <option value="BIOLOGICAL_ACCIDENT">Biological Accident</option>
              <option value="RADIOLOGICAL_ACCIDENT">Radiological Accident</option>
              <option value="NUCLEAR_ACCIDENT">Nuclear Accident</option>
              <option value="EXPLOSION">Explosion</option>
            </select>
            {this.state.valTypeOfEmergency && (
              <div className="invalid-feedback">{this.state.valTypeOfEmergency}</div>
            )}
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="additionalInformation">Emergency Type Details</label>
            <textarea
              type="text"
              className="form-control form-control-sm"
              placeholder="Additional Emergency Information Details Here"
              name="additionalInformation"
              value={this.state.additionalInformation}
              onChange={this.onChange}
            />
          </div>
        </div>
        <hr color="black" />
        <h6>
          <b>Estimation of Human Casualties</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="numberOfAdultPatients">Number of Adults</label>
            <input
              type="text"
              placeholder="Number of Adults"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valNumberOfAdultPatients,
              })}
              name="numberOfAdultPatients"
              value={this.state.numberOfAdultPatients}
              onChange={this.onChange}
            />
            {this.state.valNumberOfAdultPatients && (
              <div className="invalid-feedback">{this.state.valNumberOfAdultPatients}</div>
            )}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="numberOfChildren">Number of Children</label>
            <input
              type="text"
              placeholder="Number of Children"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valNumberofChildren,
              })}
              name="numberOfChildren"
              value={this.state.numberOfChildren}
              onChange={this.onChange}
            />
            {this.state.valNumberofChildren && (
              <div className="invalid-feedback">{this.state.valNumberofChildren}</div>
            )}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="numberOfFatalities">Number of Fatalities</label>
            <input
              type="text"
              placeholder="Number of Fatalities"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valNumberOfFatalities,
              })}
              name="numberOfFatalities"
              value={this.state.numberOfFatalities}
              onChange={this.onChange}
            />
            {this.state.valNumberOfFatalities && (
              <div className="invalid-feedback">{this.state.valNumberOfFatalities}</div>
            )}
          </div>
        </div>
        <hr color="black" />
        <h6>
          <b>Hazard Types</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="hazards">Hazard Type</label>
            <Select
              options={hazards}
              isMulti
              required
              onChange={this.handleChangeHazards}
              value={this.state.hazards}
              className={this.state.valHazards ? "red-border-line" : ""}
            />
            {this.state.valHazards && (
              <div className="invalid-feedback force-block-display">{this.state.valHazards}</div>
            )}
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="hazardBackground">Hazard Type Details</label>
            <textarea
              type="text"
              className="form-control form-control-sm"
              placeholder="Insert Additional Hazard Description Here"
              name="hazardBackground"
              value={this.state.hazardBackground}
              onChange={this.onChange}
            />
          </div>
        </div>
        <hr color="black" />
        <h6>
          <b>Location</b>
        </h6>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="location">Location</label>
            <input
              type="text"
              placeholder="Location"
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valLocation,
              })}
              name="location"
              value={this.state.location}
              onChange={this.onChange}
            />
            {this.state.valLocation && (
              <div className="invalid-feedback">{this.state.valLocation}</div>
            )}
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="areaType">Area Types</label>
            <select
              className={classnames("form-control form-control-sm", {
                "is-invalid": this.state.valAreaType,
              })}
              name="areaType"
              onChange={this.onChange}
              value={this.state.areaType}
            >
              <option value="">Choose an Area Type</option>
              <option value="URBAN">Urban</option>
              <option value="RURAL">Rural</option>
              <option value="WOODS">Woods</option>
              <option value="FIELDS">Fields</option>
            </select>
            {this.state.valAreaType && (
              <div className="invalid-feedback">{this.state.valAreaType}</div>
            )}
          </div>
        </div>
        <br />
        <div className="text-center">
          <button type="button" className="btn btn-danger btn-sm" onClick={this.onClickDispatch}>
            Dispatch
          </button>
        </div>
        <div className="text-center">
          <input type="submit" className="btn btn-danger btn-sm" />
          <Link to={{ pathname: "/IncidentMGT" }}>
            <button type="button" className="btn btn-danger btn-sm">
              Cancel
            </button>
          </Link>
          <ErrorModal
            onHide={this.handleClose}
            handleClose={this.handleClose}
            show={this.state.show}
            error={this.state.error}
          />
        </div>
      </form>
    );

    if (this.state.loading === true) {
      form = <Spinner />;
    }
    return (
      <div>
        <div className="RegisterPsapCenter">
          <div className="container">
            <div className="row">
              <div className="col-md-12 m-auto">
                <h6 className="display-4 text-center">Incident Details</h6>
                <hr />
                {form}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IncidentForm;
