import React from "react";
import { connect } from "react-redux";

const getArrayJSX = (array) => {
  return array.map((item, index) => {
    return (
      <span key={index} style={{ display: "flex" }}>
        <span>{`Incident ${item.incident_id} `}</span>
        <p className="dss-page-card-value-text">{`${item.alloc}`}</p>
      </span>
    );
  });
};
export const DssPageCardHospitalPat = (props) => {
  return (
    <div className="dss-page-card">
      {props.isHospitalPatientsLoading ? (
        <p className="loading">Waiting for data</p>
      ) : props.data ? (
        getArrayJSX(props.data)
      ) : (
        <p>There is no data</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isHospitalPatientsLoading: state.dss.isHospitalPatientsLoading,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DssPageCardHospitalPat);
