import React from "react";
import { UserInstance } from "../../config";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../Redux/actions/Users/securityActions";
import concordeImg from "../../assets/images/concorder.png";
import UserSettings from "../../assets/user-settings.svg";
import Logout from "../../assets/logout.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBell } from "@fortawesome/free-solid-svg-icons";
import DrawerToggleButton from "../UI/Navigation/SideDrawer/DrawerToggleButton";
import { ButtonToolbar, Dropdown } from "react-bootstrap";
import { NotificationsContext, VolunteerContext, VolunteerInDangerContext } from "../../context/Context";
import "./HeaderTest.css";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react"

const HeaderTest = (props) => {
  const [state, setState] = useState({})
  const notificationsContext = useContext(NotificationsContext)
  const volunteerContext = useContext(VolunteerContext)
  const volunteerInDangerContext = useContext(VolunteerInDangerContext)
  const getUser = async () => {
    try {
      // const k = UserInstance.get(`user/${ props.security.user.user_id}`);
      // console.log("USER ID", (await k).data.data);
      setState({
        ...(await (await UserInstance.get(`user/${ props.security.user.user_id}`)).data.data),
      });
      console.log("UUUUUSER",  state)
    } catch (error) {
      console.log("Get User API error");
    }
  };


  useEffect(() => {
    getUser();
  }, [props.security.user?.user_id])


  const logout = () => {
    props.logout();
    window.location.href = "/";
  };

  const profileSettings = () => {
    window.location.href = "/ProfileSettings";
  };

  const renderHeaderLogo = () => {
    return (
      <React.Fragment>
        <img id="header-logo" alt="concorde-logo" src={concordeImg} />
        <div className="toolbar__toggle-button">
          <DrawerToggleButton click={ props.drawerClickHandler} />
        </div>
      </React.Fragment>
    );
  }

  const renderBellIcon = () => {
    return (
      <>
        {/* <Dropdown className="bell">
        <Dropdown.Toggle id="dropdown-action-user-org">
          <FontAwesomeIcon icon={faBell} size="lg" className="fontawesome-header" />
        </Dropdown.Toggle>
      </Dropdown> */}
        {/* notifications-counter={} */}
        {/* <Link to="/VolunteerInDangerNotifications">
          <i className="fas fa-exclamation-circle" style={{ cursor: "pointer", color:"firebrick" }}>
            {volunteerInDangerContext.unreadCounter !== 0 ? (
              <span
                className="badge-custom"
                notifications-counter={volunteerInDangerContext.unreadCounter}
              ></span>
            ) : null}
          </i>
        </Link> */}
        {/* <Link to="/VolunteerNotifications">
          <i className="fas fa-exclamation-circle" style={{ cursor: "pointer" }}>
            {volunteerContext.unreadCounter !== 0 ? (
              <span
                className="badge-custom"
                notifications-counter={volunteerContext.unreadCounter}
              ></span>
            ) : null}
          </i>
        </Link> */}
        <Link to="/Notifications">
          <i className="fas fa-bell" style={{ cursor: "pointer" }}>
            {notificationsContext.unreadCounter !== 0 ? (
              <span
                className="badge-custom"
                notifications-counter={notificationsContext.unreadCounter}
              ></span>
            ) : null}
          </i>
        </Link>
      </>
    );
  }

  const renderUserDropdownPhoto = () => {
    if (state.avatar) {
      return (
        <img src={state.avatar} id="user-dropdown-photo" width="50" height="30" alt="avatar" />
      );
    } else {
      return <FontAwesomeIcon icon={faUser} size="lg" className="fontawesome-header" />;
    }
  }

  const renderUserDropdown = () => {
    // console.log("CURRENT STATE",  state);
    return (
      <ButtonToolbar>
        <Dropdown className="user-dropdown">
          <Dropdown.Toggle id="user-dropdown-toggle">
            { renderUserDropdownPhoto()}
            <p id="user-dropdown-username">{state.username}</p>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              to="/ProfileSettings"
              className="user-dropdown-item"
              eventKey="2"
            >
              <div className="user-dropdown-li-img">
                <img src={UserSettings} alt="user settings" />
              </div>
              Profile Settings
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/logout" className="user-dropdown-item" eventKey="1">
              <div className="user-dropdown-li-img">
                <img src={Logout} alt="logout" />
              </div>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ButtonToolbar>
    );
  }

  const renderUserRole = () => {
    let userRole
    if (props.security.user?.user_roles[0] === "HIGH_COMMANDER") {
      userRole = "High Commander" 
    } else if (props.security.user?.user_roles[0] === "FIELD_COMMANDER") {
      userRole = "Field Commander"
    } else if (props.security.user?.user_roles[0] === "EMS") {
      userRole = "EMS"
    } else if (props.security.user?.user_roles === "PSAP"){
      userRole = "PSAP"
    } else if (props.security.user?.user_roles === "HOSPITAL_COMMANDER"){
      userRole = "Hospital Commander"
    } else {
      userRole = "Unassigned Role"
    }
    return userRole
  }

  const renderEmsPosition = () => {
    let EmsPosition
    if (state.details.EMSposition === "RUNNER") {
      EmsPosition = "Runner"
    } else if (state.details.EMSposition === "RETRIEVER") {
      EmsPosition = "Retriever"
    } else if (state.details.EMSposition === "RESCUER") {
      EmsPosition = "Rescuer"
    } else {
      EmsPosition = ""
    }
    return EmsPosition;
  }
  

  const { validToken, user } =  props.security;

  return (
    <div id="header-container">
      {validToken && user ? (
        <div className="containerBox">
          <div className="row menuBox" id="header-row">
            <div className="menuSub">{ renderHeaderLogo()}</div>
            <div className="menuSub userBox">
              <div className="header-options">{ renderBellIcon()}</div>
              <div className="header-options">{ renderUserDropdown()}</div>
            </div>
          </div>
          <div className="row menuBox justify-content-end adjuster">
            <div className="ems-position">{ renderUserRole()}{state?.details?.EMSposition ? `: ${ renderEmsPosition()}` : null}</div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12"></div>
        </div>
      )}
    </div>
  );

}

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, { logout })(HeaderTest);
