import { OrgInstance } from "../../../config";
import { GET_ERRORS, FETCH_ORGANIZATIONS } from "../types";

export const registerOrganization = (newOrganization, history) => async (dispatch) => {
  try {
    await OrgInstance.post("/register-organization/", newOrganization);
    history.push("/");
    dispatch({
      type: GET_ERRORS,
      payload: null,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const fetchOrganizations = () => {
  return async (dispatch) => {
    const response = await OrgInstance.get("all/");

    dispatch({ type: FETCH_ORGANIZATIONS, payload: response.data.data });
  };
};
