import { combineReducers } from "redux";
import organizationErrorReducer from "./Organizations/organizationErrorReducer";
import loginReducer from "./Users/loginReducer";
import orgReducer from "./Organizations/orgReducer";
import analyticsReducer from "./Analytics/analyticsReducer";
import dashReducer from "./Analytics/dashReducer";
import yearsReducer from "./Analytics/yearsReducer";
import regionReducer from "./Analytics/regionReducer";
import fetchUsersReducer from "./Users/fetchUsersReducer";
import editUserReducer from "./Users/editUserReducer";
import fetchOrgsReducer from "./Organizations/fetchOrgsReducer";
import editOrgReducer from "./Organizations/editOrgsReducer";
import toggleModalReducer from "./UI/toggleModalReducer";
import toggleDispatchModalReducer from "./UI/toggleDispatchModalReducer";
import deleteOrgReducer from "./Organizations/deleteOrgReducer";
import selectOrgReducer from "./Organizations/selectOrgReducer";
import deleteUserReducer from "./Users/deleteUserReducer";
import selectUserReducer from "./Users/selectUserReducer";
import sideMenuReducer from "./UI/sideMenuReducer";
import sideMenuClearReducer from "./UI/sideMenuClearReducer";
import fetchIncidentsReducer from "./Incidents/fetchIncidentsReducer";
import editIncidentReducer from "./Incidents/editIncidentReducer";
import deleteIncidentReducer from "./Incidents/deleteIncidentReducer";
import selectIncidentReducer from "./Incidents/selectIncidentReducer";
import dssReducer from "./dss/dssReducer";
import patientReducer from "./patients/patientReducer";

// combinereducers is used when multiple reducers are present
export default combineReducers({
  errors: organizationErrorReducer,
  security: loginReducer,
  dss: dssReducer,
  organization: orgReducer,
  analytics: analyticsReducer,
  dash: dashReducer,
  years: yearsReducer,
  regions: regionReducer,
  users: fetchUsersReducer,
  incidents: fetchIncidentsReducer,
  editIncident: editIncidentReducer,
  deleteIncident: deleteIncidentReducer,
  editUser: editUserReducer,
  deleteOrg: deleteOrgReducer,
  orgs: fetchOrgsReducer,
  editOrg: editOrgReducer,
  toggleModal: toggleModalReducer,
  toggleDispatchModal: toggleDispatchModalReducer,
  selectedOrg: selectOrgReducer,
  selectUser: selectUserReducer,
  selectIncident: selectIncidentReducer,
  deleteUser: deleteUserReducer,
  sideMenu: sideMenuReducer,
  sideMenuClear: sideMenuClearReducer,
  patients: patientReducer,
});
