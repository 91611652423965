import React from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import "./PatientColumn.css";

export const patientColumns = (handlePhysio, handleDelete) => {
  return [
    {
      Header: "Triage 1",
      accessor: (p) => p.triage1.id,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value}</p>;
      },
    },
    {
      Header: "Physio",
      accessor: (p) => p.triage1.id,
      Cell: ({ row }) => {  
        console.log(row.original.dead)        
        return (
          <p>
            { !row.original.dead && 
            <Button className="p-modal-btn" onClick={() => handlePhysio(row.values["Triage 1"])}>
              Physio
            </Button>
            }
          </p>
        );
      },
    },
    {
      Header: "Delete",
      accessor: "id",
      Cell: ({ row }) => {
        return (
          <p>
            <Button className="p-modal-btn" onClick={() => handleDelete(row.values.id)}>
              Delete
            </Button>
          </p>
        );
      },
    },
    {
      Header: "Injury Description",
      accessor: (p) => p.injuryDescription,
      Cell: ({ value }) => {
        return <p>{value}</p>;
      },
    },
    {
      Header: "Time",
      accessor: (t) => moment(t.time).format("YYYY-MM-DD ddd [GMT] Z HH:mm").toString(),
      Cell: ({ value }) => {
        return <p>{value}</p>;
      },
    },
    {
      Header: "Incident",
      accessor: "incident",
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value}</p>;
      },
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ value }) => {
        return <p>{value}</p>;
      },
    },
    {
      Header: "Triage Score",
      accessor: (p) => p.triage2?.triage_score,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>
        { value === 0 ? <span className="opacity-75">⚫️</span>
        : value <= 8 ? <span>🔴</span>
        : value >= 9 && value <= 13 ? <span>🟠</span>
        : value === 14 ? <span>🟡</span>
        : value === 15 ? <span>🟢</span>
        : <span></span>
        }
        {value}
        </p>;
      },
    },
    {
      Header: "O2",
      accessor: (p) => p.triage2?.o2,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value}</p>;
      },
    },
    {
      Header: "Temperature",
      accessor: (p) => p.triage2?.temp,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value}°C</p>;
      },
    },
    {
      Header: "Breathing",
      accessor: (p) => p.triage2?.breathing,
      Cell: ({ value }) => {
        switch (value) {
          case "NORMAL":
            return <p>Normal</p>;
          case "LABORED":
            return <p>Labored</p>;
          case "SHALLOW":
            return <p>Shallow</p>;
          case "ABNORMAL_SOUNDS":
            return <p>Abnormal sounds</p>;
          default:
            return <p>Normal</p>;
        }
      },
    },
    {
      Header: "Eye Opening",
      accessor: (p) => p.triage2?.eyeOpening,
      Cell: ({ value }) => {
        switch (value) {
          case "SPONTANEOUS":
            return <p>Spontaneous</p>;
          case "TO_VOICE":
            return <p>To voice</p>;
          case "TO_PAIN":
            return <p>To pain</p>;
          case "NONE":
            return <p>None</p>;
          default:
            return <p>Spontaneous</p>;
        }
      },
    },
    {
      Header: "Motor Response",
      accessor: (p) => p.triage2?.motorResponse,
      Cell: ({ value }) => {
        switch (value) {
          case "OBEY_COMMANDS":
            return <p>Obey commands</p>;
          case "LOCALIZE":
            return <p>Localize</p>;
          case "PAIN_WITHDRAWS":
            return <p>Pain withdraws</p>;
          case "PAIN_FLEXES":
            return <p>Pain flexes</p>;
          case "PAIN_EXTENDS":
            return <p>Pain extends</p>;
          case "NONE":
            return <p>None</p>;
          default:
            return <p>Obey commands</p>;
        }
      },
    },
    {
      Header: "Verbal Response",
      accessor: (p) => p.triage2?.verbalResponse,
      Cell: ({ value }) => {
        switch (value) {
          case "ORIENTED":
            return <p>Oriented</p>;
          case "CONFUSE":
            return <p>Confuse</p>;
          case "INAPPROPRIATE":
            return <p>Inappropriate</p>;
          case "INCOMPREHENSIBLE":
            return <p>Incomprehensible</p>;
          case "NO_RESPONSE":
            return <p>No response</p>;
          default:
            return <p>Oriented</p>;
        }
      },
    },
    {
      Header: "Heart Rate",
      accessor: (p) => p.triage2?.hr,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value} bpm</p>;
      },
    },
    {
      Header: "Skin",
      accessor: (p) => p.triage2?.skin,
      Cell: ({ value }) => {
        if (value === 0) {
          return <p>Normal</p>;
        } else if (value === 1) {
          return <p>Pale</p>;
        } else if (value === 2) {
          return <p>Flushed</p>;
        } else {
          return <p>Normal</p>;
        }
      },
    },
    {
      Header: "Pain",
      accessor: (p) => p.triage2?.pain,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value}</p>;
      },
    },
    {
      Header: "Respiratory Rate",
      accessor: (p) => p.triage2?.resprate,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value}</p>;
      },
    },
    {
      Header: "Diastolic  Pressure",
      accessor: (p) => p.triage2?.diastolic_pressure,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value}</p>;
      },
    },
    {
      Header: "Blood Pressure",
      accessor: (p) => p.triage2?.systolic_pressure,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value}</p>;
      },
    },
    {
      Header: "Right Pupil",
      accessor: (p) => p.triage2?.rightPupil,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value}</p>;
      },
    },

    {
      Header: "Left Pupil",
      accessor: (p) => p.triage2?.leftPupil,
      Cell: ({ value }) => {
        return <p style={{ textAlign: "center" }}>{value}</p>;
      },
    },

    {
      Header: "Airway",
      accessor: (p) => p.triage2?.airway,
      Cell: ({ value }) => {
        return value ? <p>Open</p> : <p>Blocked</p>;
      },
    },

    {
      Header: "Capillary",
      accessor: (p) => p.triage2?.capillary,
      Cell: ({ value }) => {
        return value ? <p>Normal</p> : <p>Abnormal</p>;
      },
    },
  ];
};
