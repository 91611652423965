import React, { Component } from "react";
import { Link } from "react-router-dom";
import { OrgInstance, InciInstance } from "../../../../config";
import { connect } from "react-redux";

import { MDBDataTable, MDBBtn } from "mdbreact";
import ErrorModal from "../../../../components/UI/Modal/ErrorModal";

class HospitalCis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteData:
        // Hospitals
        // Column types must be ordered the same as the fields of the rows objects
        [
          {
            columns: [
              {
                label: "Hospital",
                field: "hospital",
                sort: "asc",
                width: 150,
              },
              {
                label: "Location",
                field: "location",
                sort: "asc",
                width: 150,
              },
              {
                label: "Notify",
                field: "notify",
                sort: "asc",
                width: 130,
              },
            ],
            rows: [],
          },
        ],
      capacityData: [
        {
          columns: [
            {
              label: "Hospital",
              field: "hospital",
              sort: "asc",
              width: 100,
            },
            {
              label: "EM",
              field: "emergencyMedicineBeds",
              sort: "asc",
              width: 30,
            },
            {
              label: "CC",
              field: "criticalCareBeds",
              sort: "asc",
              width: 30,
            },
            {
              label: "NS",
              field: "neurosurgeryBeds",
              sort: "asc",
              width: 30,
            },
            {
              label: "BPS",
              field: "burnsOrPlasticSurgeryBeds",
              sort: "asc",
              width: 30,
            },
            {
              label: "PS",
              field: "pediatricsOrPediatricSurgeryBeds",
              sort: "asc",
              width: 30,
            },
            {
              label: "V",
              field: "vascularBeds",
              sort: "asc",
              width: 30,
            },
            {
              label: "R",
              field: "resuscitationBeds",
              sort: "asc",
              width: 30,
            },
            {
              label: "T",
              field: "traumaBeds",
              sort: "asc",
              width: 30,
            },
          ],
          rows: [],
        },
      ],
      pushedHospitalButton: false,
      pushedNotifyButton: false,
      incidentId: null,
      show: true,
      error: {},
    };
  }

  notifiedHospitalsArray = [];
  notifiedHospitalCommanders = [];

  componentDidMount = async () => {
    const inci = await InciInstance.get(`/incident/${this.props.security.user.incidentId}`);
    console.log("INCI_CALL", inci);
    this.notifiedHospitalsArray = inci.data.data.notifiedHospitals;
    this.notifiedHospitalCommanders = [];
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  notifyHospital = async (hospitalId) => {
    console.log(hospitalId);
    let authentication = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };
    // Patch the notified hospitals ids
    try {
      this.notifiedHospitalsArray.push(hospitalId);
      let notifiedHospital = {
        ids: this.notifiedHospitalsArray,
      };

      await InciInstance.post(
        `incident/${this.props.security.user.incidentId}/notify-hospitals/`,
        notifiedHospital,
        authentication
      );
      // try {
      //   let HospComm = await UserInstance.get(`hospital-commander/${hospitalId}/`);
      //   try {
      //     for (let i = 0; i < HospComm.data.data.length; i++) {
      //       if (HospComm.data.data[i].details.role_set[0] === "HOSPITAL_COMMANDER") {
      //         await InciInstance.post(`incident/${this.props.security.user.incidentId}/notify/`, {
      //           ids: [HospComm.data.data[i].id, "c5dc0aa8-ab54-4dcf-81c6-7023c1461419"],
      //         });
      //       }
      //     }
      //   } catch (error) {
      //     console.log("Error during Notification");
      //   }
      // } catch (error) {
      //   console.log("Error Retrieving Hospital Commander");
      // }
    } catch (error) {
      console.log("Notify Hospital Error");
      this.setState({ error: error, show: true });
    }

    // Get all the hospitals
    let allHospitals = [];
    try {
      allHospitals = await OrgInstance.get("/hospital/");
      console.log("Hospital", allHospitals);
    } catch (error) {
      console.log("Get hospitals Error");
      this.setState({ error: error, show: true });
    }

    this.setState({ pushedHospitalButton: false });
    this.handleClickHospitals();
  };

  requestUpdate = async () => {
    // let emptynotifiedlist = {
    //   notifiedHospitals: []
    // };
    // await InciInstance.patch(`/incidents/${this.props.location.prePopulate.id}`, emptynotifiedlist);
    // this.notifiedHospitalsArray = [];
    // this.setState({ pushedHospitalButton: false });
    // this.handleClickHospitals();
  };

  handleClickHospitals = async () => {
    try {
      let authentication = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      };

      // Get all register hospitals
      const hospitals = await OrgInstance.get("/hospital/", authentication);
      console.log("HOSPITALS", hospitals);
      // Populate the available hospitals table
      this.setState((prevState) => (prevState.inviteData[0].rows = []));
      for (let i = 0; i < hospitals.data.data.length; i++) {
        if (!this.notifiedHospitalsArray.includes(hospitals.data.data[i].organizationId)) {
          console.log("notifiedHospitalsArray", this.notifiedHospitalsArray);
          console.log("Incoming Id", hospitals.data.data[i].organizationId);
          this.setState(
            (prevState) =>
              (prevState.inviteData[0].rows = [
                ...prevState.inviteData[0].rows,
                {
                  hospital: hospitals.data.data[i].name,
                  location: hospitals.data.data[i].location,
                  notify: (
                    <MDBBtn
                      color="primary"
                      size="sm"
                      onClick={() => this.notifyHospital(hospitals.data.data[i].organizationId)}
                    >
                      Notify
                    </MDBBtn>
                  ),
                },
              ])
          );
        }
      }
      // Populate the Notified hospitals table
      this.setState((prevState) => (prevState.capacityData[0].rows = []));
      for (let i = 0; i < hospitals.data.data.length; i++) {
        if (this.notifiedHospitalsArray.includes(hospitals.data.data[i].organizationId)) {
          console.log(
            "IS IT ?",
            this.notifiedHospitalsArray.includes(hospitals.data.data[i].organizationId)
          );
          this.setState(
            (prevState) =>
              (prevState.capacityData[0].rows = [
                ...prevState.capacityData[0].rows,
                {
                  hospital: hospitals.data.data[i].name,
                  emergencyMedicineBeds: hospitals.data.data[i].emergencyMedicineBeds,
                  criticalCareBeds: hospitals.data.data[i].criticalCareBeds,
                  neurosurgeryBeds: hospitals.data.data[i].neurosurgeryBeds,
                  burnsOrPlasticSurgeryBeds: hospitals.data.data[i].burnsOrPlasticSurgeryBeds,
                  pediatricsOrPediatricSurgeryBeds:
                    hospitals.data.data[i].pediatricsOrPediatricSurgeryBeds,
                  vascularBeds: hospitals.data.data[i].vascularBeds,
                  resuscitationBeds: hospitals.data.data[i].resuscitationBeds,
                  traumaBeds: hospitals.data.data[i].traumaBeds,
                  request: (
                    <MDBBtn color="danger" onClick={() => this.requestUpdate()} size="sm">
                      Request Update
                    </MDBBtn>
                  ),
                },
              ])
          );
        }
      }
      this.setState({ pushedHospitalButton: true });
    } catch (error) {
      console.log("Get available hospitals API error");
      this.setState({ error: error, show: true });
    }
  };

  render() {
    return (
      <div className="container">
        <h1 className="display-4 text-center">Incident Hospitals</h1>
        <div className="row mt-5">
          <div className="col-sm">
            <h5>Available Hospitals</h5>
            <hr />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm">
            {this.state.pushedHospitalButton ? (
              <div>
                {/* <ErrorModal
                  onHide={this.handleClose}
                  handleClose={this.handleClose}
                  show={this.state.show}
                  error={this.state.error}
                /> */}
                <MDBDataTable
                  maxHeight="500px"
                  scrollY
                  striped
                  bordered
                  fixed
                  hover
                  small
                  data={this.state.inviteData[0]}
                />
              </div>
            ) : (
              <div className="button_container text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleClickHospitals}
                >
                  Show Available Hospitals
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm">
            <h5>Notified Hospitals Capacities</h5>
            <hr />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm">
            {this.state.pushedHospitalButton ? (
              <div>
                <MDBDataTable
                  maxHeight="500px"
                  scrollY
                  striped
                  bordered
                  fixed
                  hover
                  small
                  data={this.state.capacityData[0]}
                />
              </div>
            ) : (
              <div></div>
            )}
            <div className="row mt-5">
              <div className="col-sm">
                <div className="text-center">
                  <Link
                    to={{
                      pathname: "/",
                      prePopulate: this.props.location.prePopulate,
                      populate: true,
                      hasArrived: this.props.location.hasArrived,
                    }}
                  >
                    <button type="button" className="btn btn-danger btn-sm">
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  security: state.security,
  userId: state.security.user.user_id,
  selectedIncident: state.selectIncident,
});

export default connect(mapStateToProps, null)(HospitalCis);
