import { SHOW_DISPATCH_MODAL, HIDE_DISPATCH_MODAL } from "../types";

export const showDispatchModal = () => {
  return {
    type: SHOW_DISPATCH_MODAL,
  };
};

export const hideDispatchModal = () => {
  return {
    type: HIDE_DISPATCH_MODAL,
  };
};
