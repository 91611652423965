import React from "react";
import moment from "moment";
import "./Events.css";

export const SmartwatchEvent = (props) => {
  
  return (
    <>
      {props.data ? (
        <div
          className="row row-eq-height mb-3 row-borders-events font-events"
          style={props.rowColor ? { backgroundColor: props.rowColor } : null}>
          <div className="col-3 vertical-centered-cols-events">
            <div className="row">
              <div className="col-12">
                <span style={{ fontWeight: "bold" }}>Local:</span>{" "}
                {moment.unix(props.data.localTimestamp).format("DD/MM/YYYY HH:mm:ss")}
              </div>
              <div className="col-12">
                <span style={{ fontWeight: "bold" }}>UTC:</span>{" "}
                {moment.unix(props.data.utcTimestamp).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            </div>
          </div>
          <div className="col-3 vertical-centered-cols-events">
            <div className="row">
              <div className="col-12">
                <span style={{ fontWeight: "bold" }}>Sensor ID:</span> {props.data.id}
              </div>
              <div className="col-12">
                <span style={{ fontWeight: "bold" }}>Sensor Type:</span>{" "}
                {props.data.fieldDataProducer}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                <span style={{ display: "block", fontWeight: "bold" }}>Heart Rate</span>
                <div className="mt-1">
                  <span style={{ display: "block" }}>
                    <span style={{ fontWeight: "bold" }}>Average:</span>{" "}
                    {props.data.vitalParametersDetection.averageHeartRateValue}
                  </span>
                  <span style={{ display: "block" }}>
                    <span style={{ fontWeight: "bold" }}>Maximum:</span>{" "}
                    {props.data.vitalParametersDetection.maximumHeartRateValue}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                <span style={{ display: "block", fontWeight: "bold" }}>TrackingInfo</span>
                <div className="mt-1">
                  <span style={{ display: "block" }}>
                    <span style={{ fontWeight: "bold" }}>Avg Speed:</span>{" "}
                    {props.data.trackingInformation.avgSpeed}
                  </span>
                  <span style={{ display: "block" }}>
                    <span style={{ fontWeight: "bold" }}>Distance:</span>{" "}
                    {props.data.trackingInformation.distance}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                <span style={{ display: "block", fontWeight: "bold" }}>Info</span>
                <div className="mt-1">
                  <span style={{ display: "block" }}>
                    <span style={{ fontWeight: "bold" }}>Weight:</span>{" "}
                    {props.data.personInformation.personWeight}
                  </span>
                  <span style={{ display: "block" }}>
                    <span style={{ fontWeight: "bold" }}>Sex:</span>{" "}
                    {props.data.personInformation.sexUnitEnum}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                <span style={{ display: "block", fontWeight: "bold" }}>Start</span>
                <div className="mt-1">
                  <span style={{ display: "block" }}>
                    <span style={{ fontWeight: "bold" }}>Lat:</span>{" "}
                    {props.data.startingLocation.point.wgs84Latitude}
                  </span>
                  <span style={{ display: "block" }}>
                    <span style={{ fontWeight: "bold" }}>Lng:</span>{" "}
                    {props.data.startingLocation.point.wgs84Longitude}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 vertical-centered-cols-events pt-1">
                <span style={{ display: "block", fontWeight: "bold" }}>End</span>
                <div className="mt-1">
                  <span style={{ display: "block" }}>
                    <span style={{ fontWeight: "bold" }}>Lat:</span>{" "}
                    {props.data.endingLocation.point.wgs84Latitude}
                  </span>
                  <span style={{ display: "block" }}>
                    <span style={{ fontWeight: "bold" }}>Lng:</span>{" "}
                    {props.data.endingLocation.point.wgs84Latitude}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
