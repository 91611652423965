import React from "react";
import { faBell, faExclamationTriangle, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import organizations from "../../../assets/organizations.svg";
import users from "../../../assets/users.svg";
import dss from "../../../assets/dss.svg";
import { useDrag } from "react-dnd";
import ItemTypes from "../../../utils/dndItems";

const DashboardDragItem = ({ panel }) => {
  //drags the id of the component to the target
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.COMPONENT,
    item: {
      id: panel.name,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  //console.log('1111', panel)

  //renders the draggable panel icon
  const renderIcon = () => {
    switch (panel.name) {
      case "Organizations":
        return (
          <div>
            <img
              src={organizations}
              alt="organizations"
              width={30}
              height={32}
              className="min-w-min"
            />
          </div>
        );
      case "Users":
        return (
          <div>
            <img src={users} alt="users" width={30} height={32} className="min-w-min" />
          </div>
        );
      case "DSS":
        return (
          <div>
            <img src={dss} alt="dss" width={30} height={32} className="min-w-min" />
          </div>
        );
      case "Incidents":
        return (
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-concorde-blue" size="2x" />
        );
      case "Notifications":
        return <FontAwesomeIcon icon={faBell} className="text-concorde-blue" size="2x" />;
      case "Map":
        return <FontAwesomeIcon icon={faMap} className="text-concorde-blue" size="2x" />;
      default:
        return <FontAwesomeIcon icon={faBell} className="text-concorde-blue" size="2x" />;
    }
  };

  return (
    <div
      ref={drag}
      opacity={isDragging ? "0.5" : "1"}
      className="bg-white border-2 border-concorde-light rounded-xl flex flex-col items-center px-20 py-6 gap-y-1"
      style={{display: panel.isVisible ? "flex" : "none" }}  
    >
      {renderIcon()}
      <span className="text-concorde-light text-xl">{panel.name}</span>
    </div>
  );
};

export default DashboardDragItem;
