import {
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  EDIT_USER,
  USER_SELECTED,
  DELETE_USER,
} from "../types";
import history from "../../../history";
import { UserInstance } from "../../../config";

const fetchUsersStart = () => {
  return {
    type: FETCH_USERS_START,
  };
};

const fetchUsersSuccess = (users) => {
  return {
    type: FETCH_USERS_SUCCESS,
    users,
  };
};

const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USERS_FAILURE,
    error,
  };
};

const fetchUserDetails = () => {
  const response = UserInstance.get("user/");
  return response;
};

export const fetchUsers = () => (dispatch) => {
  dispatch(fetchUsersStart());
  return fetchUserDetails().then(
    (response) => dispatch(fetchUsersSuccess(response.data.data)),
    (error) => dispatch(fetchUsersFailure(error))
  );
};

export const selectUser = (id) => {
  return {
    type: USER_SELECTED,
    id: id,
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    const response = await UserInstance.delete(`user/${id}/`);
    dispatch({ type: DELETE_USER, payload: response.data });
    history.push("/");
  };
};

export const editUser = (id) => {
  return async (dispatch) => {
    const response = await UserInstance.get(`user/${id}/`);
    dispatch({ type: EDIT_USER, payload: response.data });
    history.push({
      pathname: "/RegisterUser",
      prePopulate: response.data.data,
      populate: true,
    });
  };
};
