// import React from "react";

// import Logo from "../../Logo/Logo";
// import NavigationItems from "../NavigationItems/NavigationItems";
// import classes from "./SideDrawer.css";
// import Backdrop from "../../UI/Backdrop/Backdrop";
// import Aux from "../../../hoc/Aux/Aux";

// const sideDrawer = props => {
//   let attachedClasses = [classes.SideDrawer, classes.Close];
//   if (props.open) {
//     attachedClasses = [classes.SideDrawer, classes.Open];
//   }
//   return (
//     <Aux>
//       <Backdrop show={props.open} clicked={props.closed} />
//       <div className={attachedClasses.join(" ")} onClick={props.closed}>
//         <div className={classes.Logo}>
//           <Logo />
//         </div>
//         <nav>
//           <NavigationItems isAuthenticated={props.isAuth} />
//         </nav>
//       </div>
//     </Aux>
//   );
// };

// export default sideDrawer;

import  React from 'react';

const drawerToggleButton = props => (
  <button className="toggle-button" onClick={props.click}>
    <div className="toggle-button__line" />
    <div className="toggle-button__line" />
    <div className="toggle-button__line" />
  </button>
)

export default drawerToggleButton;