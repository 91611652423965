import { dssMiddleware } from "../../../config";
import {
  SET_DSS_TOKEN,
  SET_ALLOCATION_EMS,
  SET_EXPECTED_CASUALTIES,
  SET_HOSPITAL_PATIENTS,
  SET_ACTORS_FIELDS,
  SET_SERVICE2_ACTORS_FIELDS,
  SET_SERVICE3_ACTORS_FIELDS,
} from "../types";
import history from "../../../history";

export const loginDssMiddleware = () => async (dispatch) => {
  try {
    // Post => Login Request
    // const res = await dssMiddleware.post("token/", {
    //   username: "angelica",
    //   password: "dummy",
    // });
    const token =
      "SNR_CONCORDE:51ad6d7a9387623590c1aa1e6a18b82958fb5e0d020b279d4d6989af0d7118b9:501b4419-2687-11ec-8466-107b444dbb42";
    // const refreshToken = res.data.refresh;
    // Store the token in the localStorage
    localStorage.setItem("dssToken", token);
    // localStorage.setItem("dssRefresh", refreshToken);
    // const decodedToken = jwt_decode(token);
    // dispatch({
    //   type: SET_DSS_TOKEN,
    //   token: token,
    //   refreshToken: refreshToken,
    //   tokenExp: decodedToken.exp,
    //   userId: decodedToken.user_id,
    //   jti: decodedToken.jti,
    // });
    dispatch({
      type: SET_DSS_TOKEN,
      token: token,
      refreshToken: token,
    });
    history.push("/Dashboard");
  } catch (err) {
    console.log("Get dss access token error");
  }
};

//   export const updateUser = (token) => async (dispatch) => {
//     try {
//       let newToken = await authInstance.post("http://167.71.60.27:2020/api/login/refresh/", {
//         refresh: token,
//       });
//       const newDecodedToken = jwt_decode(newToken.data.data[0].access);
//       dispatch({
//         type: UPDATE_CURRENT_USER,
//         payload: newDecodedToken,
//         tokenExp: newDecodedToken.exp,
//       });
//     } catch (err) {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err,
//       });
//     }
//   };

export const dssTokenClear = () => (dispatch) => {
  localStorage.removeItem("dssToken");
  localStorage.removeItem("dssRefresh");
  dispatch({
    type: SET_DSS_TOKEN,
    token: null,
    refreshToken: null,
    tokenExp: null,
    userId: null,
    jti: null,
  });
};

export const getAllocationEms = () => async (dispatch) => {
  try {
    const res = await dssMiddleware.get("service1/start/");
    dispatch({
      type: SET_ALLOCATION_EMS,
      payload: res.data.DSS_output,
    });
  } catch (err) {
    console.log("Get allocation EMS error");
  }
};

export const getHospitalPatients = () => async (dispatch) => {
  try {
    const res = await dssMiddleware.get("service2/start/");
    dispatch({
      type: SET_HOSPITAL_PATIENTS,
      payload: res.data.DSS_output.patient_allocation,
    });
  } catch (err) {
    console.log("Get allocation of hospital patients error");
  }
};

export const getActorsFields = () => async (dispatch) => {
  try {
    const resService2 = await dssMiddleware.get("service2/start/");
    const resService3 = await dssMiddleware.get("service3/start/");
    const allocationTasksService2 = resService2.data.DSS_output.task_allocation;
    const allocationTasksService3 = resService3.data.DSS_output.task_allocation;
    // const totalAllocationTasks = allocationTasksService2.concat(allocationTasksService3);
    // console.log("EMS DATA", totalAllocationTasks);
    console.log("SERVICE 2", allocationTasksService2);
    console.log("SERVICE 3", allocationTasksService3);
    dispatch({
      type: SET_ACTORS_FIELDS,
      payload: [...allocationTasksService2, ...allocationTasksService3],
    });
  } catch (err) {
    console.log(err);
    console.log("Get allocation of available tasks error");
  }
};

export const getExpectedCasualties = () => async (dispatch) => {
  try {
    const res = await dssMiddleware.get("service4/start/");
    dispatch({
      type: SET_EXPECTED_CASUALTIES,
      payload: res.data.DSS_output,
    });
  } catch (err) {
    console.log("Get expected casualties error");
  }
};

export const getService2ActorsFields = () => async (dispatch) => {
  try {
    const resServ2 = await dssMiddleware.get("service2/start/");
    console.log("REDUCER ARRAY 1", resServ2);
    const allocTasksService2 = resServ2.data.DSS_output.task_allocation;
    console.log("ALLOC ARRAY 1", allocTasksService2);
    dispatch({
      type: SET_SERVICE2_ACTORS_FIELDS,
      payload: allocTasksService2,
    });
  } catch (err) {
    console.log(err);
    console.log("Get allocation of available tasks error");
  }
};

export const getService3ActorsFields = () => async (dispatch) => {
  try {
    const resServ3 = await dssMiddleware.get("service3/start/");
    const allocTasksService3 = resServ3.data.DSS_output.task_allocation;
    dispatch({
      type: SET_SERVICE3_ACTORS_FIELDS,
      payload: allocTasksService3,
    });
  } catch (err) {
    console.log(err);
    console.log("Get allocation of available tasks error");
  }
};
