import React, { useEffect, useState, useContext } from "react";
import { Notification } from "./Notification";
import { authInstance } from "../../../config";
import moment from "moment";
// import { NotificationsGenericContext } from '../../../context/NotificationsContextGeneric';
import { NotificationsContext } from "../../../context/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import "./Notifications.css";

export const NotificationsPage = (props) => {
  const notificationsContext = useContext(NotificationsContext);
  notificationsContext.resetUnreadCounter(0);
  console.log("NOT", notificationsContext.notifications)
  return (
    <div className="container-fluid overflow-x-auto">
      <h1 className="dash-heading">
        <FontAwesomeIcon icon={faBell} size="sm" style={{ marginRight: "5px" }} />
        Notification Page
      </h1>
      <div className="row row-eq-height text-center table-header">
        <div className="col-3 p-3">Time</div>
        <div className="col-3 p-3">Device Id</div>
        <div className="col-3 p-3">Device Type</div>
        <div className="col-3 p-3">Message</div>
      </div>
      <div className="row row-eq-height">
        {notificationsContext.notifications.map((notification, index) => {
          return (
            <div className="col-12" key={index}>
              <Notification
                rowColor={index % 2 === 0 ? "#fff" : "#eff6ff"}
                notification={notification}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NotificationsPage