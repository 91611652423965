import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import SecuredRoute from "../src/securityUtils/SecureRoute";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

import Logout from "./containers/Authentication/Logout";
import DispatchTable from "./containers/Screens/Admin/ResourceMGT/Incidents/Dispatch/DispatchTable";
import AnalyticsPage from "./containers/Screens/Analytics/AnalyticsPage";
import DssPage from "./containers/Screens/DSS/DssPage";
import EventLogPage from "./containers/Screens/EventLog/EventLogPage";
import { NotificationsPage } from "./containers/Screens/Notifications/NotificationsPage";
import VolunteerNotificationsPage from "./containers/Screens/Volunteer/VolunteerNotificationsPage";
import VolunteerInDangerNotificationsPage from "./containers/Screens/Volunteer/VolunteerInDangerNotificationsPage";
import { VideosPage } from "./containers/Screens/ObjectDetection/VideosPage";
import Triage from "./containers/Screens/IncidentModule/CisComponents/Triage/Triage";
// Common
import Login from "./containers/Authentication/Login";
import NewUser from "./containers/Screens/Admin/ResourceMGT/RegisterUser/NewUser";
import ProfileSettings from "./containers/Screens/Common/ProfileSettings";

// Admin Routes
import AdminDashboard from "./containers/Screens/Admin/Dashboard";
import IncidentsMap from "./containers/Screens/Admin/ResourceMGT/Incidents/IncidentsMap";
import RegisterUser from "./containers/Screens/Admin/ResourceMGT/RegisterUser/User";
import UsersMGT from "./containers/Screens/Admin/ResourceMGT/RegisterUser/UsersMGT";
import OrgMGT from "./containers/Screens/Admin/ResourceMGT/Organizations/OrgMGT";
import registerOrganization from "./containers/Screens/Admin/ResourceMGT/Organizations/RegisterOrganization";
import registerHospital from "./containers/Screens/Admin/ResourceMGT/Organizations/AvailableOrganizations/Hospital";
import registerEms from "./containers/Screens/Admin/ResourceMGT/Organizations/AvailableOrganizations/EmergencyMedicalServices";
import registerOther from "./containers/Screens/Admin/ResourceMGT/Organizations/AvailableOrganizations/Other";
import registerHigherCommand from "./containers/Screens/Admin/ResourceMGT/Organizations/AvailableOrganizations/HigherCommand";
import CustomDashboard from "./containers/Screens/Admin/CustomDashboard";
import Dos from "./containers/Screens/Admin/Dos";
import PressReleaseInformation from "./containers/Screens/Admin/PressReleaseInformation";

// Guest Routes
// eslint-disable-next-line

//PSAP Routes
import EMSIncidentMGT from "./containers/Screens/IncidentModule/CisComponents/Incident/IncidentMGT";
import registerIncident from "./containers/Screens/Admin/ResourceMGT/Incidents/IncidentForm";
import HospitalCis from "./containers/Screens/IncidentModule/CisComponents/HospitalCis";
import PatientMGT from "./containers/Screens/IncidentModule/CisComponents/Patients/PatientMGT";
import ChatApp from "./components/Chat/ChatApp";

// Common Incident Space routes
import Cis from "./containers/Screens/IncidentModule/Cis";
import SitrepForm from "./containers/Screens/IncidentModule/CisComponents/Sitrep";
import IncidentTeam from "./containers/Screens/IncidentModule/CisComponents/IncidentTeam";
import FireDSS from "./containers/Screens/Analytics/FireDSS";

//New Incident Routes
import IncidentMGT from "./containers/Screens/Admin/ResourceMGT/Incidents/IncidentMGT";
import IncidentTable from "./containers/Screens/Admin/ResourceMGT/Incidents/IncidentTable";
import { Documentation } from "./containers/Screens/Documentation/Documentation";
import { Forum } from "./containers/Screens/Forum/Forum";

class Routes extends Component {
  render() {
    // Expose the paths that correspond to each user role
    let exposedUrls;
    if (this.props.security.validToken) {
      const token = localStorage.getItem("jwtToken");
      const decoded = jwt_decode(token);
      let role = decoded.user_roles[0];

      console.log(decoded);

      switch (role) {
        // Admin private routes
        case "HIGH_COMMANDER":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={AdminDashboard} />
              <SecuredRoute exact path="/CustomDashboard" component={CustomDashboard} />
              <SecuredRoute exact path="/Dashboard" component={AdminDashboard} />
              <SecuredRoute exact path="/IncidentsMap" component={IncidentsMap} />
              <SecuredRoute exact path="/ProfileSettings" component={ProfileSettings} />
              <SecuredRoute exact path="/Logout" component={Logout} />
              <SecuredRoute exact path="/RegisterUser" component={RegisterUser} />
              <SecuredRoute exact path="/RegisterOrganization" component={registerOrganization} />
              <SecuredRoute exact path="/OrgMGT" component={OrgMGT} />
              <SecuredRoute exact path="/UsersMGT" component={UsersMGT} />
              <SecuredRoute exact path="/RegisterHospital" component={registerHospital} />
              <SecuredRoute exact path="/RegisterEms" component={registerEms} />
              <SecuredRoute exact path="/RegisterOther" component={registerOther} />
              <SecuredRoute exact path="/RegisterHigherCommand" component={registerHigherCommand} />
              <SecuredRoute exact path="/Analytics" component={AnalyticsPage} />
              <SecuredRoute exact path="/RegisterIncident" component={registerIncident} />
              <SecuredRoute exact path="/IncidentInfo" component={IncidentTable} />
              <SecuredRoute exact path="/IncidentMGT" component={IncidentMGT} />
              <SecuredRoute exact path="/DispatchTable" component={DispatchTable} />
              <SecuredRoute exact path="/Dss" component={DssPage} />
              <SecuredRoute exact path="/EventLogs" component={EventLogPage} />
              <SecuredRoute exact path="/Notifications" component={NotificationsPage} />
              <SecuredRoute
                exact
                path="/VolunteerNotifications"
                component={VolunteerNotificationsPage}
              />
              <SecuredRoute
                exact
                path="/VolunteerInDangerNotifications"
                component={VolunteerInDangerNotificationsPage}
              />
              <SecuredRoute exact path="/ObjectDetection" component={VideosPage} />
              <SecuredRoute exact path="/HospitalCis" component={HospitalCis} />
              <SecuredRoute
                exact
                path="/PressReleaseInformation"
                component={PressReleaseInformation}
              />
              <SecuredRoute exact path="/Management-Strategies" component={Dos} />
              <SecuredRoute exact path="/Docs" component={Documentation} />
              <SecuredRoute exact path="/Agora" component={Forum} />
            </Switch>
          );
          break;
        // Guest Private routes
        case "EMS":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={Cis} />
              <SecuredRoute exact path="/Dashboard" component={Cis} />
              <SecuredRoute exact path="/CustomDashboard" component={CustomDashboard} />
              <SecuredRoute exact path="/Cis" component={Cis} />
              <SecuredRoute exact path="/IncidentMGT" component={EMSIncidentMGT} />
              <SecuredRoute exact path="/DispatchTable" component={DispatchTable} />
              <SecuredRoute exact path="/ProfileSettings" component={ProfileSettings} />
              <SecuredRoute exact path="/Sitrep" component={SitrepForm} />
              <SecuredRoute exact path="/IncidentTeam" component={IncidentTeam} />
              <SecuredRoute exact path="/FireDSS" component={FireDSS} />
              <SecuredRoute exact path="/Dss" component={DssPage} />
              <SecuredRoute exact path="/PatientMGT" component={PatientMGT} />
              <SecuredRoute exact path="/HospitalCis" component={HospitalCis} />
              <SecuredRoute exact path="/EventLogs" component={EventLogPage} />
              <SecuredRoute exact path="/Notifications" component={NotificationsPage} />
              <SecuredRoute exact path="/Logout" component={Logout} />
              <SecuredRoute
                exact
                path="/VolunteerNotifications"
                component={VolunteerNotificationsPage}
              />
              <SecuredRoute
                exact
                path="/VolunteerInDangerNotifications"
                component={VolunteerInDangerNotificationsPage}
              />
              <SecuredRoute exact path="/Logout" component={Logout} />
              <SecuredRoute exact path="/ObjectDetection" component={VideosPage} />
              <SecuredRoute exact path="/Triage" component={Triage} />
              <SecuredRoute
                exact
                path="/PressReleaseInformation"
                component={PressReleaseInformation}
              />
              <SecuredRoute exact path="/Management-Strategies" component={Dos} />
              <SecuredRoute exact path="/Docs" component={Documentation} />
              <SecuredRoute exact path="/Agora" component={Forum} />
            </Switch>
          );
          break;
        // Psap Private routes
        case "PSAP":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={Cis} />
              <SecuredRoute exact path="/RegisterIncident" component={registerIncident} />
              <SecuredRoute exact path="/IncidentMGT" component={IncidentMGT} />
              <SecuredRoute exact path="/DispatchTable" component={DispatchTable} />
              <SecuredRoute exact path="/ProfileSettings" component={ProfileSettings} />
              <SecuredRoute exact path="/Sitrep" component={SitrepForm} />
              <SecuredRoute exact path="/IncidentTeam" component={IncidentTeam} />
              <SecuredRoute exact path="/HospitalCis" component={HospitalCis} />
              <SecuredRoute exact path="/FireDSS" component={FireDSS} />
              <SecuredRoute exact path="/Analytics" component={AnalyticsPage} />
              <SecuredRoute exact path="/ChatApp" component={ChatApp} />
              <SecuredRoute exact path="/Docs" component={Documentation} />
              <SecuredRoute exact path="/Agora" component={Forum} />
              <SecuredRoute exact path="/Logout" component={Logout} />
            </Switch>
          );
          break;
        case "HOSPITAL_COMMANDER":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={registerHospital} />
              <SecuredRoute exact path="/Analytics" component={AnalyticsPage} />
              <SecuredRoute exact path="/Agora" component={Forum} />
              <SecuredRoute exact path="/Logout" component={Logout} />
            </Switch>
          );
          break;
        default:
          break;
      }
    } else {
      exposedUrls = (
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/NewUser" component={NewUser} />
        </Switch>
      );
    }
    return (
      <div>
        <Switch>
          {
            // Public Routes
          }
          {/* <Route exact path="/Login" component={Login} /> */}
          {exposedUrls}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, null)(Routes);
