import React, { Component } from "react";
import axios from "axios";
import KeyPad from "./Calculator/KeyPad";
import Result from "./Calculator/Result";
import { Link } from "react-router-dom";
import { FireSvg } from "./fire";

class FireDSS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: "",
      lng: "",
      submission: false,
      result: "0",
      FfCount: 0,
      VolCount: 0,
      AirCount: 0,
      FtCount: 0,
      HikersCount: 0
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.degToCompass = this.degToCompass.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  res = null;
  onSubmit = async e => {
    const authentication = {
      headers: {
        "Api-Key": "6f249b9e850b8a513643f33ad75ba37b"
      }
    };
    let coord = {
      latitude: this.state.lat,
      longitude: this.state.lng
    };
    const Analytics = axios.post("http://165.22.77.92:1992/predict-fire", coord, authentication);
    this.res = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${this.state.lat}&lon=${this.state.lng}&appid=a20ba6b1bda4d9df403a665a74ddf166`
    );
    this.setState({ submission: true });

    Analytics.then(x => {
      this.setState({
        result: x.data.data.Acres,
        FfCount: x.data.data.Fire_fighters,
        VolCount: x.data.data.Volunteers,
        HikersCount: x.data.data.Hikers,
        FtCount: x.data.data.Fire_trucks
      });
    });
  };

  componentDidMount() {
    this.setState({
      lat: this.props.location.prePopulate.geoLocation.lat,
      lng: this.props.location.prePopulate.geoLocation.lng
    });
  }

  degToCompass(num) {
    var val = Math.floor(num / 22.5 + 0.5);
    var arr = [
      "↑ N",
      "NNE",
      "↗ NE",
      "ENE",
      "→ E",
      "ESE",
      "↘ SE",
      "SSE",
      "↓ S",
      "SSW",
      "↙ SW",
      "WSW",
      "← W",
      "WNW",
      "↖ NW",
      "NNW"
    ];
    return arr[val % 16];
  }

  onClick = button => {
    if (button === "+1 Fire-Fighter") {
      this.setState({ result: this.state.result - (this.state.result * 0.28) / 100 });
      this.setState({ FfCount: this.state.FfCount + 1 });
    } else if (button === "-1 Fire-Fighter") {
      this.setState({ result: this.state.result + (this.state.result * 3.89) / 100 });
      this.setState({ FfCount: this.state.FfCount - 1 });
    } else if (button === "+1 Volunteer") {
      this.setState({ result: this.state.result - (this.state.result * 2.01) / 100 });
      this.setState({ VolCount: this.state.VolCount + 1 });
    } else if (button === "-1 Volunteer") {
      this.setState({ result: this.state.result + (this.state.result * 2.01) / 100 });
      this.setState({ VolCount: this.state.VolCount - 1 });
    } else if (button === "+1 Airplane") {
      this.setState({ result: this.state.result - (this.state.result * 18.72) / 100 });
      this.setState({ AirCount: this.state.AirCount + 1 });
    } else if (button === "-1 Airplane") {
      this.setState({ result: this.state.result + (this.state.result * 18.72) / 100 });
      this.setState({ AirCount: this.state.AirCount - 1 });
    } else if (button === "+1 Fire-Truck") {
      this.setState({ result: this.state.result - (this.state.result * 3.89) / 100 });
      this.setState({ FtCount: this.state.FtCount + 1 });
    } else if (button === "-1 Fire-Truck") {
      this.setState({ result: this.state.result + (this.state.result * 3.89) / 100 });
      this.setState({ FtCount: this.state.FtCount - 1 });
    } else if (button === "Reset") {
      this.reset();
    } else {
      this.setState({
        result: this.state.result + button
      });
    }
  };

  reset = () => {
    this.setState({
      result: "0",
      FfCount: 1,
      FtCount: 1,
      VolCount: 1,
      AirCount: 1
    });
  };

  render() {
    return (
      <div className="container">
        <h6 className="display-4 text-center">Fire Damage Prediction</h6>
        <hr />
        <div id="weather-wrapper">
          <div className="row">
            <div className="col-sm-3">
              <div className="row">
                <div className="col-sm-8" style={{ marginLeft: "20px" }}>
                  <label htmlFor="lat">Latitude</label>
                  <input
                    type="lat"
                    className="form-control form-control-sm"
                    name="lat"
                    value={this.state.lat}
                    onChange={this.onChange}
                  />

                  <label htmlFor="lng">Longitude</label>
                  <input
                    type="lng"
                    className="form-control form-control-sm"
                    name="lng"
                    value={this.state.lng}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    style={{ marginTop: "20px", marginLeft: "20px" }}
                    onClick={this.onSubmit}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              {this.state.submission === true ? (
                <table className="table" id="weatherTable">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>Wind</td>
                      <td>
                        {this.degToCompass(this.res.data.wind.deg)} , at a speed of{" "}
                        {this.res.data.wind.speed} m/s
                      </td>
                    </tr>
                    <tr>
                      <td>Cloudiness</td>
                      <td>{this.res.data.weather[0].description.toUpperCase()}</td>
                    </tr>
                    <tr>
                      <td>Pressure</td>
                      <td>{this.res.data.main.pressure} hpa</td>
                    </tr>
                    <tr>
                      <td>Humidity</td>
                      <td>{this.res.data.main.humidity} %</td>
                    </tr>
                    <tr>
                      <td>Temprature</td>
                      <td>{Math.round(this.res.data.main.temp - 273.15)} °C</td>
                    </tr>
                    <tr>
                      <td>Sunrise</td>
                      <td>{new Date(this.res.data.sys.sunrise * 1000).toLocaleTimeString()}</td>
                    </tr>
                    <tr>
                      <td>Sunset</td>
                      <td>{new Date(this.res.data.sys.sunset * 1000).toLocaleTimeString()}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <h1 className="display-4" style={{ fontSize: "150%", marginTop: "30px" }}>
                Calculation Results
              </h1>
              <KeyPad
                onClick={this.onClick}
                FfCount={this.state.FfCount}
                FtCount={this.state.FtCount}
                AirCount={this.state.AirCount}
                VolCount={this.state.VolCount}
                HikersCount={this.state.HikersCount}
              />
            </div>
            <div className="col-sm-3">
              <div className="row" style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                <div className="col" style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                  <FireSvg />
                  <FireSvg />
                </div>
              </div>
              <div className="row" style={{ marginTop: "0px", paddingTop: "0px" }}>
                <div className="col" style={{ marginTop: "0px", paddingTop: "0px" }}>
                  <Result result={Number(Math.round(this.state.result + "e2") + "e-2")} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sm" style={{ marginLeft: "40%" }}>
              <Link
                to={{
                  pathname: "/Cis",
                  prePopulate: this.props.location.prePopulate,
                  populate: true,
                  hasArrived: this.props.location.hasArrived
                }}
              >
                <button type="button" className="btn btn-danger btn-sm">
                  Cancel
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FireDSS;
