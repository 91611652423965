import React, { Component, createContext } from "react";
import { Router } from "react-router-dom";
import Routes from "../src/Routes";
import { connect } from "react-redux";
import "tailwindcss/tailwind.css";

import Sidemenu from "./components/UI/Sidemenu";
import HeaderTest from "./components/UI/HeaderTest";
import history from "./history";
import SideDrawer from "./components/UI/Navigation/SideDrawer/SideDrawer";
import Backdrop from "./components/UI/Navigation/Backdrop/Backdrop";
import NotificationsService from "./services/NotificationsService";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { NotificationsGenericContextProvider } from './context/NotificationsContextGeneric'
import { NotificationsContext, VolunteerContext, VolunteerInDangerContext } from "./context/Context";
import VolunteerService from "./services/VolunteerService";

class App extends Component {
  state = {
    sideDrawerOpen: false,
  };
  notificationService = NotificationsService.getInstance()
  volunteerService = VolunteerService.getInstance()

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
      sideDrawer = <SideDrawer />;
    }
    const { validToken, user } = this.props.security;
    return (
      <DndProvider backend={HTML5Backend}>
      <NotificationsGenericContextProvider context={NotificationsContext} notificationIdentifier={'timestamp'} getNotifications={ async () => await this.notificationService.getLatestNotifications(99) }>
        <NotificationsGenericContextProvider context={VolunteerContext} notificationIdentifier={'timestamp'} getNotifications={this.volunteerService.getAvailableVolunteer}>
         <NotificationsGenericContextProvider context={VolunteerInDangerContext} notificationIdentifier={'timestamp'} getNotifications={this.volunteerService.getInDangerVolunteer}>
        <Router history={history}>
          <div className="container-fluid" id="app-container">
            <div className="row" id="app-row-header" style={{backgroundColor: '#fff'}}>
              <div className="col-12" id="header-col">
                <HeaderTest drawerClickHandler={this.drawerToggleClickHandler} />
                {sideDrawer}
                {backdrop}
              </div>
            </div>
              {validToken && user ? (
                <div className="row" id="app-row-content">
                  <div className="col-2" id="sidemenu-col">
                    <Sidemenu />
                  </div>
                  <div className="col-10 body-content" id="app-body">
                    <div id="body-container body-content" className="body-content-inner">
                      <Routes />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row" id="app-row-content">
                  <div className="col" id="sidemenu-hidden"></div>
                  <div className="col-12 body-content pl-0">
                    <div id="body-container body-content">
                      <Routes />
                    </div>
                  </div>
                </div>
            )}
          </div>
          </Router>
          </NotificationsGenericContextProvider>
        </NotificationsGenericContextProvider>
      </NotificationsGenericContextProvider>
      </DndProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, null)(App);
