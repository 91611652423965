import React, { Component } from "react";
import { OrgInstance, GoogleAPIKey } from "../../../../../../config";
import Geocode from "react-geocode";
import {
  CustomForm,
  FormRow,
  FormInput,
  FormButtons,
  FormDropdown,
  FormWrapper,
} from "../../../../../../components/UI/Form/Form";
import * as validation from "../../../../../../components/UI/Form/formValidation";

import ErrorModal from "../../../../../../components/UI/Modal/ErrorModal";

class EmergencyMedicalServices extends Component {
  constructor() {
    super();

    this.state = {
      prePopulate: false,
      loading: false,
      show: false,
      error: {},
    };

    this.org = {
      name: "",
      location: "",
      lat: "",
      lng: "",
      telephoneNumber: "",
      expertise: "",
      fleetSize: "",
      fleetTypeAVehicles: "",
      fleetTypeBVehicles: "",
      totalNumberOfParamedics: "",
    };
  }

  componentDidMount = () => {
    if (typeof this.props.location !== "undefined") {
      try {
        this.org = this.props.location.prePopulate;
        this.setState({ prePopulate: true });
      } catch (error) {
        console.log("Prepopulation error");
        this.setState({error: error, show: true});
      }
    }
  };

  async getLocationCoordinates(location) {
    Geocode.setApiKey(GoogleAPIKey);
    Geocode.enableDebug();
    return await Geocode.fromAddress(location).then(
      (response) => [
        response.results[0].geometry.location.lat,
        response.results[0].geometry.location.lng,
      ],
      (error) => console.error(error)
    );
  }

  onSubmit = async (formValues) => {
    try {
      [formValues.lat, formValues.lng] = await this.getLocationCoordinates(formValues.location);
    } catch (error) {
      console.log("Could not get coordinates from location.");
      this.setState({error: error, show: true});
    }

    this.setState({ loading: true });
    try {
      if (this.state.prePopulate === true) {
        await OrgInstance.put(
          `/ems/${this.props.location.prePopulate.organizationId}/`,
          formValues
        );
      } else {
        await OrgInstance.post("/ems/", formValues);
      }
      this.setState({ loading: false });
      this.props.history.push("/Dashboard");
    } catch (error) {
      console.log("Register EMS Organization API error");
      this.setState({ loading: false, error: error, show: true });
    }
  };

  handleClose = () => {
    this.setState({show: false});
  };

  render() {
    const oneHundredThousand = 100000;

    return (
      <FormWrapper
        parentClass="EmergencyMedicalServices"
        componentHeading="Emergency Medical Services Details"
      >
        <CustomForm
          initialValues={{ ...this.org }}
          onSubmit={(formValues) => this.onSubmit(formValues)}
          // loading={this.state.loading}
        >
         <ErrorModal
            onHide={this.handleClose}
            handleClose={this.handleClose}
            show  ={this.state.show}
            error = {this.state.error}
        />
          <FormRow>
            <FormInput
              name="name"
              size="col-md-6"
              validate={(value) => validation.inputIsRequired("Name", value)}
            />
            <FormInput
              name="telephoneNumber"
              size="col-md-6"
              validate={(value) => validation.phoneNumber(value)}
            />
          </FormRow>
          <FormRow>
            <FormInput
              name="location"
              size="col-md-12"
              validate={(value) => validation.inputIsRequired("Location", value)}
            />
          </FormRow>
          <FormRow>
            <FormInput
              name="fleetSize"
              type="number"
              size="col-md-4"
              validate={(value) => validation.maxNum("Fleet size", value, oneHundredThousand)}
            />
            <FormInput
              name="fleetTypeAVehicles"
              type="number"
              size="col-md-4"
              validate={(value) => validation.maxNum("Fleet type A", value, oneHundredThousand)}
            />
            <FormInput
              name="fleetTypeBVehicles"
              type="number"
              size="col-md-4"
              validate={(value) => validation.maxNum("Fleet type B", value, oneHundredThousand)}
            />
          </FormRow>
          <FormRow>
            <FormInput
              name="totalNumberOfParamedics"
              type="number"
              size="col-md-12"
              validate={(value) =>
                validation.maxNum("Number of paramedics", value, oneHundredThousand)
              }
            />
          </FormRow>
          <FormRow>
            <FormDropdown
              name="expertise"
              size="col-md-6"
              validate={(value) => validation.selectionIsRequired("Expertise", value)}
              options={[
                { NO_MEDICAL_SKILLS: "No Medical Skills" },
                { BASIC_LIFE_SUPPORT: "Basic Life Support" },
                { ADVANCED_LIFE_SUPPORT: "Advance Life Support" },
                { SPECIALIST_MEDICAL: "Medical Specialist" },
              ]}
            />
          </FormRow>
          <FormRow>
            <FormButtons />
          </FormRow>
        </CustomForm>
      </FormWrapper>
    );
  }
}

export default EmergencyMedicalServices;
