import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchUsers,
  editUser,
  selectUser,
  deleteUser,
} from "../../../../../Redux/actions/Users/fetchUsersAction";
import { showModal, hideModal } from "../../../../../Redux/actions/UI/toggleModalAction";
import DataTable from "react-data-table-component";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import More from "../../../../../assets/more.svg";
import Edit from "../../../../../assets/edit.svg";
import Delete from "../../../../../assets/delete.svg";
import orderBy from "lodash/orderBy";
import Dropdown from "react-bootstrap/Dropdown";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

class UsersTable extends Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  modalHandler() {
    if (this.props.modal) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal isOpen={this.props.modal} toggle={this.props.modal} centered size="sm">
            <MDBModalHeader>Delete</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.props.hideModal} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  if (this.selectedUser !== null) {
                    this.props.deleteUser(this.props.selectedUser.id);
                    this.props.hideModal();
                  }
                }}
                size="sm"
              >
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field]) {
        return row[field].toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  actionOptions = (row) => {
    return (
      <>
        <Dropdown.Item onClick={() => this.props.editUser(row.id)}>
          <div className="table-edit">
            <img src={Edit} alt="" /> Edit
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.props.selectUser(row.id);
            this.props.showModal();
          }}
        >
          <div className="table-delete">
            <img src={Delete} alt="" /> Delete
          </div>
        </Dropdown.Item>
      </>
    );
  };

  renderUsers() {
    if (this.props.users !== null && this.props.users.isLoading === false) {
      console.log("USERS", this.props.users);
      return this.props.users.users.users.map((user) => {
        return {
          id: user.id,
          username: user.username,
          name: user.firstName,
          lastName: user.lastName,
          role: user.details.role,
          position: user.details.position,
          telephone: user.phoneNumber,
          email: user.email,
          edit: (
            <MDBBtn color="primary" size="sm" onClick={() => this.props.editUser(user.id)}>
              Edit
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              color="danger"
              size="sm"
              onClick={() => {
                this.props.selectUser(user.id);
                this.props.showModal();
              }}
            >
              Delete
            </MDBBtn>
          ),
        };
      });
    }
  }

  render() {
    let filteredData;
    const { filterText } = this.state;
    if (this.props.users !== null && this.props.users.isLoading === false) {
      filteredData = this.renderUsers().filter(
        (item) =>
          item.username.toLowerCase().includes(filterText.toLowerCase()) ||
          item.name.toLowerCase().includes(filterText.toLowerCase()) ||
          item.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
          item.role.toLowerCase().includes(filterText.toLowerCase()) ||
          item.position.toLowerCase().includes(filterText.toLowerCase()) ||
          item.email.toLowerCase().includes(filterText.toLowerCase()) ||
          item.telephone.includes(filterText)
      );
    }

    const columns = [
      {
        name: "Username",
        selector: "username",
        grow: 2,
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        grow: 2,
        sortable: true,
      },
      {
        name: "Last Name",
        selector: "lastName",
        grow: 1,
        sortable: true,
      },
      {
        name: "Role",
        selector: "role",
        grow: 1,
        sortable: true,
      },
      {
        name: "Position",
        selector: "position",
        grow: 1,
        sortable: true,
      },
      {
        name: "Telephone",
        selector: "telephone",
        grow: 2,
        sortable: true,
      },
      {
        name: "Email",
        selector: "email",
        grow: 2,
        sortable: true,
      },
      {
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-action-user-org">
              <img src={More} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="edit-table-wrapper">{this.actionOptions(row)}</Dropdown.Menu>
          </Dropdown>
        ),
        selector: "action",
        allowOverflow: true,
        button: true,
        right: true,
      },
    ];

    if (this.props.users !== null && this.props.users.isLoading === true) {
      return <Spinner />;
    } else {
      return (
        <>
          <div className="table-search-wrapper">
            <input
              className="table-search"
              placeholder="Search Users"
              value={this.filter}
              onChange={this.handleChange}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination={true}
            paginationPerPage={4}
            paginationRowsPerPageOptions={[4, 10, 15]}
            sortFunction={this.customSort}
          />
          <div>{this.modalHandler()}</div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    modal: state.toggleModal.show,
    selectedUser: state.selectUser,
    deletedUser: state.deleteUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchUsers, editUser, selectUser, deleteUser, showModal, hideModal },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
