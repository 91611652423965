import React, { Component } from "react";

class KeyPad extends Component {
  render() {
    return (
      <div id="keypad-container">
        <div className="row">
          <div className="col-sm-4">
            <p style={{ marginBottom: "1%" }}>Fire Fighters ({this.props.FfCount})</p>
            <button
              id="keypad"
              className="btn btn-primary font-weight-bold "
              name="+1 Fire-Fighter"
              onClick={e => this.props.onClick(e.target.name)}
            >
              +1
            </button>
            {this.props.FfCount > 0 ? (
              <button
                id="keypad"
                className="btn btn-danger font-weight-bold"
                name="-1 Fire-Fighter"
                onClick={e => this.props.onClick(e.target.name)}
              >
                -1
              </button>
            ) : (
              <button
                id="keypad"
                className="btn btn-danger font-weight-bold"
                disabled
                name="-1 Fire-Fighter"
                onClick={e => this.props.onClick(e.target.name)}
              >
                -1
              </button>
            )}

            <p style={{ marginBottom: "1%", marginTop: "3%" }}>
              Volunteers ({this.props.VolCount}) / Hikers ({this.props.HikersCount})
            </p>

            <button
              id="keypad"
              className="btn btn-primary font-weight-bold"
              name="+1 Volunteer"
              onClick={e => this.props.onClick(e.target.name)}
            >
              +1
            </button>
            {this.props.VolCount > 0 ? (
              <button
                id="keypad"
                className="btn btn-danger font-weight-bold"
                name="-1 Volunteer"
                onClick={e => this.props.onClick(e.target.name)}
              >
                -1
              </button>
            ) : (
              <button
                id="keypad"
                className="btn btn-danger font-weight-bold"
                name="-1 Volunteer"
                disabled
                onClick={e => this.props.onClick(e.target.name)}
              >
                -1
              </button>
            )}
          </div>

          <div className="col-sm-4">
            <p style={{ marginBottom: "1%" }}>Airplanes ({this.props.AirCount})</p>
            <button
              id="keypad"
              className="btn btn-primary font-weight-bold"
              name="+1 Airplane"
              onClick={e => this.props.onClick(e.target.name)}
            >
              +1
            </button>
            {this.props.AirCount > 0 ? (
              <button
                id="keypad"
                className="btn btn-danger font-weight-bold"
                name="-1 Airplane"
                onClick={e => this.props.onClick(e.target.name)}
              >
                -1
              </button>
            ) : (
              <button
                id="keypad"
                className="btn btn-danger font-weight-bold"
                name="-1 Airplane"
                disabled
                onClick={e => this.props.onClick(e.target.name)}
              >
                -1
              </button>
            )}

            <p style={{ marginBottom: "1%", marginTop: "3%" }}>
              Fire Trucks ({this.props.FtCount})
            </p>
            <button
              id="keypad"
              className="btn btn-primary font-weight-bold"
              name="+1 Fire-Truck"
              onClick={e => this.props.onClick(e.target.name)}
            >
              +1
            </button>
            {this.props.FtCount > 0 ? (
              <button
                id="keypad"
                className="btn btn-danger font-weight-bold"
                name="-1 Fire-Truck"
                onClick={e => this.props.onClick(e.target.name)}
              >
                -1
              </button>
            ) : (
              <button
                id="keypad"
                className="btn btn-danger font-weight-bold"
                name="-1 Fire-Truck"
                disabled
                onClick={e => this.props.onClick(e.target.name)}
              >
                -1
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <button
              id="keypadReset"
              className="btn btn-primary "
              name="Reset"
              onClick={e => this.props.onClick(e.target.name)}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default KeyPad;
