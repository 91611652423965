import React, { useEffect, useState, useRef } from "react";
/*
props :
    getNotifications: returns GenericType[]
    notificationIdentifier: string
    context: Context
*/
const NotificationsGenericContextProvider = (props) => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCounter, setUnreadCounter] = useState(0);
    let interval;
    
    const currentNotifications = useRef([]);

    useEffect(() => {
      currentNotifications.current = notifications;
    }, [notifications]);

    useEffect(() => {
      interval = setInterval(async () => {
        let newNotifications = await props.getNotifications();

        newNotifications = Array.isArray(newNotifications) ? newNotifications : newNotifications.data.data

        const unreadNotificationsAndCounter = checkForUnreadNotifications(unreadCounter, currentNotifications.current, newNotifications)
        
        if(unreadNotificationsAndCounter) {
          console.log('new notifications')
          setNotifications(unreadNotificationsAndCounter.newNotifications)
          setUnreadCounter(unreadNotificationsAndCounter.unreadCounter)
        }
        // setNotifications((oldNotifications) => {
        //   setUnreadCounter((oldUnreadCounter) => {
        //     const unreadNotifications = checkForUnreadNotifications(
        //       oldUnreadCounter,
        //       oldNotifications,
        //       newNotifications
        //     );
        //     return unreadNotifications.unreadCounter;
        //   });
        //   return newNotifications;
        // });
      }, 5000);
  
      // on unmount
      return () => clearInterval(interval);
    }, []);
  
    const checkForUnreadNotifications = (oldUnreadCounter, oldNotifications, newNotifications) => {
      
      const oldIdentifiers = oldNotifications.map((oldNotification) => oldNotification[props.notificationIdentifier]);
      const newIdentifiers = newNotifications.map((newNotification) => newNotification[props.notificationIdentifier]);
      const intersection = oldIdentifiers.filter((oldIdentifier) =>
        newIdentifiers.includes(oldIdentifier)
      );
      // console.log("-------------------------");
      // console.log("old timestamps");
      // console.log(oldIdentifiers);
  
      // console.log("new timestamps");
      // console.log(newIdentifiers);
  
      // console.log("intersection");
      // console.log(intersection);
      // console.log("-------------------------");
      if(newNotificationsExists(oldNotifications, newNotifications)) {
        let newUnreadCounter =
          oldUnreadCounter < 10
            ? oldUnreadCounter + (oldNotifications.length - intersection.length)
            : oldUnreadCounter;
         return { unreadCounter: newUnreadCounter, newNotifications };
      } else return null
    };

    const newNotificationsExists = (oldNotifications, newNotifications) => {
      // console.log(oldNotifications)
      // console.log(newNotifications)
      // console.log(JSON.stringify(oldNotifications))
      // console.log(JSON.stringify(newNotifications))
      return JSON.stringify(oldNotifications) !== JSON.stringify(newNotifications);
    }

    const resetUnreadCounter = () => {
      setUnreadCounter(0);
    };
  
    return (
      <props.context.Provider value={{ notifications, unreadCounter, resetUnreadCounter }}>
        {props.children}
      </props.context.Provider>
    );
  };

  export { NotificationsGenericContextProvider };