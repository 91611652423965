import React, { useEffect } from "react";
import { useFormik } from "formik";
import { authInstance } from "../../../../../config";
import "./triageForm.css";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const initialValues = {
  injuryDescription: "",
  o2: "",
  temp: "",
  breathing: "NORMAL",
  eyeOpening: "SPONTANEOUS",
  motorResponse: "OBEY_COMMANDS",
  verbalResponse: "ORIENTED",
  hr: "",
  skin: "0",
  pain: "",
  rr: "0",
  diastolic_pressure: "",
  systolic_pressure: "",
  rightPupil: "",
  leftPupil: "",
  airway: "true",
  capillary: "true",
  dead: false
};

const forceOnlyNumber = (e) => {
  const char = String.fromCharCode(e.which);
  if (!/[0-9]|\.{1}/.test(char)) {
    e.preventDefault();
  }
};

const handlePaste = (e) => {
  e.preventDefault();
};

const validate = (values, props /* only available when using withFormik */) => {
  const errors = {};
  if (values.temp > 45) {
    errors.temp = "Temperature cannot be greater than 45°C";
  }

  return errors;
};

const requiredMessage = "This field is required and must be a positive number or 0.";
const positiveNumberMessage = "The number typed must be 0 or greater.";
const pupilMinMessage = "pupil cannot accept values lower than 2.";
const pupilMaxMessage = "pupil cannot accept values greater than 9.";
const integerOnlyMessage = "must be an integer.";

const validationSchema = Yup.object({
  injuryDescription: Yup.string().required(requiredMessage),
  o2: Yup.number(requiredMessage).required(requiredMessage).min(0, positiveNumberMessage),
  temp: Yup.string().required(requiredMessage),
  hr: Yup.number().required(requiredMessage).min(0, positiveNumberMessage),
  pain: Yup.number()
    .integer(`Pain ${integerOnlyMessage}`)
    .required(requiredMessage)
    .min(0, positiveNumberMessage)
    .max(10, "Pain cannot accept values greater than 10"),
  resprate: Yup.number()
    .integer(`Respiratory Rate ${integerOnlyMessage}`)
    .required(requiredMessage)
    .min(0, positiveNumberMessage)
    .max(30, "Respiratory Rate cannot accept values greater than 30"),
  diastolic_pressure: Yup.string().required(requiredMessage),
  systolic_pressure: Yup.string().required(requiredMessage),
  rightPupil: Yup.number()
    .integer(`Right Pupil ${integerOnlyMessage}`)
    .required(requiredMessage)
    .min(2, `Right ${pupilMinMessage}`)
    .max(9, `Right ${pupilMaxMessage}`),
  leftPupil: Yup.number()
    .integer(`Left Pupil ${integerOnlyMessage}`)
    .required(requiredMessage)
    .min(2, `Left ${pupilMinMessage}`)
    .max(9, `Left ${pupilMaxMessage}`),
});

const TriageForm = (props) => {
  console.log("SPHINX", props.patient?.id);
  const divClasses = "form-group col-lg-3 col-md-4 col-sm-6";
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const dead = values.dead
      const triage1 = {
        o2: values.o2,
        temp: values.temp,
        breathing: values.breathing,
        eyeOpening: values.eyeOpening,
        motorResponse: values.motorResponse,
        verbalResponse: values.verbalResponse,
      };
      const triage2 = {
        ...triage1,
        hr: values.hr,
        skin: values.skin,
        pain: values.pain,
        resprate: values.resprate,
        diastolic_pressure: values.diastolic_pressure,
        systolic_pressure: values.systolic_pressure,
        rightPupil: values.rightPupil,
        leftPupil: values.leftPupil,
        airway: values.airway === "true" ? true : false,
        capillary: values.capillary === "true" ? true : false,
      };
      console.log(values)
      try {
        console.log("formik.values", values.rightPupil);
        const data = {
          injuryDescription: values.injuryDescription,
          dead,
          triage1,
          triage2,
        };
        const response = props.patient
          ? await authInstance.put(`triage/patient/${props.patient.id}/`, data)
          : await authInstance.post("triage/patient/", data);
        console.log("PATIENT", response);
        formik.resetForm();
        window.location.reload();
      } catch (error) {}
    },
    //validationSchema,
    validate,
  });

  useEffect(() => {
    if (props.patient) {
      const fetchedValues = {
        dead: props.patient.dead,
        injuryDescription: props.patient.injuryDescription,
        o2: props.patient.triage2.o2,
        temp: props.patient.triage2.temp,
        breathing: props.patient.triage2.breathing,
        eyeOpening: props.patient.triage2.eyeOpening,
        motorResponse: props.patient.triage2.motorResponse,
        verbalResponse: props.patient.triage2.verbalResponse,
        hr: props.patient.triage2.hr,
        skin: props.patient.triage2.skin,
        pain: props.patient.triage2.pain,
        resprate: props.patient.triage2.resprate,
        diastolic_pressure: props.patient.triage2.diastolic_pressure,
        systolic_pressure: props.patient.triage2.systolic_pressure,
        rightPupil: props.patient.triage2.rightPupil,
        leftPupil: props.patient.triage2.leftPupil,
        airway: props.patient.triage2.airway,
        capillary: props.patient.triage2.capillary,
      };
      formik.setValues(fetchedValues);
    } else if (props.patient === null) {
      formik.resetForm();
    }
  }, [props.patient]);
  
  function isDead(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      const zeroedValues = {
        injuryDescription: "",
        o2: 0,
        temp: 0,
        breathing: "NONE",
        eyeOpening: "NONE",
        motorResponse: "NONE",
        verbalResponse: "NONE",
        hr: 0,
        skin: "NONE",
        pain: 0,
        resprate: 0,
        diastolic_pressure: 0,
        systolic_pressure: 0,
        rightPupil: 0,
        leftPupil: 0,
        airway: "NONE",
        capillary: "NONE",
      };
      formik.setValues(zeroedValues);
    } else {
      if (props.patient) {
        const fetchedValues = {
          dead: props.patient.dead,
          injuryDescription: props.patient.injuryDescription,
          o2: props.patient.triage2.o2,
          temp: props.patient.triage2.temp,
          breathing: props.patient.triage2.breathing,
          eyeOpening: props.patient.triage2.eyeOpening,
          motorResponse: props.patient.triage2.motorResponse,
          verbalResponse: props.patient.triage2.verbalResponse,
          hr: props.patient.triage2.hr,
          skin: props.patient.triage2.skin,
          pain: props.patient.triage2.pain,
          resprate: props.patient.triage2.resprate,
          diastolic_pressure: props.patient.triage2.diastolic_pressure,
          systolic_pressure: props.patient.triage2.systolic_pressure,
          rightPupil: props.patient.triage2.rightPupil,
          leftPupil: props.patient.triage2.leftPupil,
          airway: props.patient.triage2.airway,
          capillary: props.patient.triage2.capillary,
        };
        formik.setValues(fetchedValues);
      } else if (props.patient === null) {
        formik.resetForm();
      }
    }    
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row triage-container col-md-12 mb-4">
        <div className="form-group col-sm-12">
          <label htmlFor="injuryDescription">Injury Description</label>
          <input
            className="form-control"
            id="injuryDescription"
            type="text"
            placeholder="Injury description"
            {...formik.getFieldProps("injuryDescription")}
          />
          {formik.touched.injuryDescription && formik.errors.injuryDescription ? (
            <div className="errors">{formik.errors.injuryDescription}</div>
          ) : null}
        </div>
        <div className={divClasses}>
          <label htmlFor="o2">O2</label>
          <input
            className="form-control"
            id="o2"
            type="number"
            min="0"
            placeholder="0%"
            {...formik.getFieldProps("o2")}
          />
          {formik.touched.o2 && formik.errors.o2 ? (
            <div className="errors">{formik.errors.o2}</div>
          ) : null}
        </div>
        <div className={divClasses}>
          <label htmlFor="temp">Temperature</label>
          <input
            className="form-control"
            id="temp"
            type="number"
            min="0"
            max="45"
            step="0.1"
            placeholder="0°C"
            onKeyPress={forceOnlyNumber}
            onPaste={handlePaste}
            {...formik.getFieldProps("temp")}
          />
          {formik.touched.temp && formik.errors.temp ? (
            <div className="errors">{formik.errors.temp}</div>
          ) : null}
        </div>
        <div className={divClasses}>
          <label htmlFor="breathing">Breathing</label>
          <select className="form-control" id="breathing" {...formik.getFieldProps("breathing")}>
            <option value="NORMAL">Normal</option>
            <option value="LABORED">Labored</option>
            <option value="SHALLOW">Shallow</option>
            <option value="ABNORMAL_SOUNDS">Abnormal sounds</option>
            <option value="NONE">None</option>
          </select>
        </div>
        <div className={divClasses}>
          <label htmlFor="eyeOpening">Eye Opening</label>
          <select className="form-control" id="eyeOpening" {...formik.getFieldProps("eyeOpening")}>
            <option value="SPONTANEOUS">Spontaneous</option>
            <option value="TO_VOICE">To voice</option>
            <option value="TO_PAIN">To pain</option>
            <option value="NONE">None</option>
          </select>
        </div>
        <div className={divClasses}>
          <label htmlFor="motorResponse">Motor Response</label>
          <select
            className="form-control"
            id="motorResponse"
            {...formik.getFieldProps("motorResponse")}
          >
            <option value="OBEY_COMMANDS">Obey commands</option>
            <option value="LOCALIZE">Localize</option>
            <option value="PAIN_WITHDRAWS">Pain withdraws</option>
            <option value="PAIN_FLEXES">Pain flexes</option>
            <option value="PAIN_EXTENDS">Pain extends</option>
            <option value="NO_RESPONSE">No response</option>
            <option value="NONE">None</option>
          </select>
        </div>
        <div className={divClasses}>
          <label htmlFor="verbalResponse">Verbal Response</label>
          <select
            className="form-control"
            id="verbalResponse"
            {...formik.getFieldProps("verbalResponse")}
          >
            <option value="ORIENTED">Oriented</option>
            <option value="CONFUSE">Confuse</option>
            <option value="INAPPROPRIATE">Inappropriate</option>
            <option value="INCOMPREHENSIBLE">Incomprehensible</option>
            <option value="NO_RESPONSE">No response</option>
            <option value="NONE">None</option>
          </select>
        </div>
        <div className={divClasses}>
          <label htmlFor="hr">Heart Rate</label>
          <input
            className="form-control"
            id="hr"
            type="number"
            min="0"
            placeholder="0 bpm"
            {...formik.getFieldProps("hr")}
          />
          {formik.touched.hr && <formik className="errors hr"></formik> ? (
            <div className="errors">{formik.errors.hr}</div>
          ) : null}
        </div>
        <div className={divClasses}>
          <label htmlFor="skin">Skin</label>
          <select className="form-control" id="skin" {...formik.getFieldProps("skin")}>
            <option value="0">Normal</option>
            <option value="1">Pale</option>
            <option value="2">Flushed</option>
            <option value="NONE">None</option>
          </select>
        </div>
        <div className={divClasses}>
          <label htmlFor="pain">Pain</label>
          <input
            className="form-control"
            id="pain"
            type="number"
            min="0"
            max="10"
            step="1"
            placeholder="0"
            {...formik.getFieldProps("pain")}
          />
          {formik.touched.pain && formik.errors.pain ? (
            <div className="errors">{formik.errors.pain}</div>
          ) : null}
        </div>
        <div className={divClasses}>
          <label htmlFor="resprate">Respiratory Rate</label>
          <input
            className="form-control"
            id="resprate"
            type="number"
            min="0"
            max="30"
            step="1"
            placeholder="0"
            {...formik.getFieldProps("resprate")}
          />
          {formik.touched.resprate && formik.errors.resprate ? (
            <div className="errors">{formik.errors.resprate}</div>
          ) : null}
        </div>
        <div className={divClasses}>
          <label htmlFor="diastolic_pressure">Diastolic Pressure</label>
          <input
            className="form-control"
            id="diastolic_pressure"
            type="number"
            min="0"
            step="1"
            placeholder="0 mmHg"
            onKeyPress={forceOnlyNumber}
            onPaste={handlePaste}
            {...formik.getFieldProps("diastolic_pressure")}
          />
          {formik.touched.diastolic_pressure && formik.errors.diastolic_pressure ? (
            <div className="errors">{formik.errors.diastolic_pressure}</div>
          ) : null}
        </div>
        <div className={divClasses}>
          <label htmlFor="systolic_pressure">Systolic Pressure</label>
          <input
            className="form-control"
            id="systolic_pressure"
            type="number"
            min="0"
            step="0.1"
            placeholder="0 mmHg"
            onKeyPress={forceOnlyNumber}
            onPaste={handlePaste}
            {...formik.getFieldProps("systolic_pressure")}
          />
          {formik.touched.systolic_pressure && formik.errors.systolic_pressure ? (
            <div className="errors">{formik.errors.systolic_pressure}</div>
          ) : null}
        </div>
        <div className={divClasses}>
          <label htmlFor="rightPupil">Right Pupil</label>
          <input
            className="form-control"
            id="rightPupil"
            type="number"
            min="2"
            max="9"
            step="1"
            placeholder="2 mm"
            {...formik.getFieldProps("rightPupil")}
          />
          {formik.touched.rightPupil && formik.errors.rightPupil ? (
            <div className="errors">{formik.errors.rightPupil}</div>
          ) : null}
        </div>
        <div className={divClasses}>
          <label htmlFor="leftPupil">Left Pupil</label>
          <input
            className="form-control"
            id="leftPupil"
            type="number"
            min="2"
            max="9"
            step="1"
            placeholder="2 mm"
            {...formik.getFieldProps("leftPupil")}
          />
          {formik.touched.leftPupil && formik.errors.leftPupil ? (
            <div className="errors">{formik.errors.leftPupil}</div>
          ) : null}
        </div>
        <div className={divClasses}>
          <label htmlFor="airway">Airway</label>
          <select className="form-control" id="airway" {...formik.getFieldProps("airway")}>
            <option value="true">Open</option>
            <option value="false">Blocked</option>
            <option value="NONE">None</option>
          </select>
        </div>
        <div className={divClasses}>
          <label htmlFor="capillary">Capillary</label>
          <select
            className="capillary form-control"
            id="capillary"
            {...formik.getFieldProps("capillary")}
          >
            <option value="true">Normal</option>
            <option value="false">Abnormal</option>
            <option value="NONE">None</option>
          </select>
        </div>
        <div className={divClasses}>
          <div className="d-flex">
            <input 
              type="checkbox" 
              id="dead"                            
              {...formik.getFieldProps("dead")}   
              onChange={e => isDead(e)}   
            />
            <label className="ml-2"> Patient is Dead</label>
          </div>
        </div>
      </div>
      <div className="text-right form-group col-md-12 form-button-wrapper">
        <button type="submit" className="btn solid-button">
          Submit
        </button>
        <Link to={{ pathname: "/Dashboard" }}>
          <button type="button" className="btn outline-button">
            Cancel
          </button>
        </Link>
        {/* <div className="required-fields mb-4 mt-2">*All fields are required.</div> */}
      </div>
    </form>
  );
};

export default TriageForm;
