import React, { Component } from "react";

class SendMessageForm extends Component {
  constructor() {
    super();
    this.state = {
      message: "",
    };
  }
  handleChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.sendMessage(this.props.currentRoom, this.state.message);
    this.setState({ message: "" });
  };
  render() {
    return (
      <form onSubmit={this.handleSubmit} className="send-message-form">
        <input
          type="text"
          disabled={this.props.disabled}
          onChange={this.handleChange}
          value={this.state.message}
          placeholder="Type your message and hit ENTER"
        />
      </form>
    );
  }
}

export default SendMessageForm;
